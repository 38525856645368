import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Card, Modal, TextField, Typography, CircularProgress, Checkbox, FormControlLabel, FormGroup, FormLabel, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CancelButton from 'src/components/shared/CancelButton';
import CompleteButton from 'src/components/shared/CompleteButton';
import MultipleImageUpload from 'src/components/shared/MultipleImageUpload';
import Services from 'src/services/Services';
import moment from 'moment';
import { toast } from 'react-toastify';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './AddEditBolParaCompany.css'

export default function AddEditCampaignModal({ open, onClose, getCampaigns, campaign, setSelectedCampaign }) {
    const parsedUser = JSON.parse(localStorage.getItem('user'));

    const [campaignTitle, setCampaignTitle] = useState("");
    const [campaignImages, setCampaignImages] = useState([]);
    const [campaignStart, setCampaignStart] = useState();
    const [campaignEnd, setCampaignEnd] = useState();
    const [campaignLink, setCampaignLink] = useState("");
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [selectedCategories, setSelectedCategories] = useState({
        esnaf: false,
        easypoint: false,
        personalCustomers: false,
    });
    const [isActive, setIsActive] = useState(false);

    const handleCategoryChange = (event) => {
        setSelectedCategories({
            ...selectedCategories,
            [event.target.name]: event.target.checked
        });
    };

    const [isLoading, setIsLoading] = useState(false);

    const allFieldsAreValid = () => {
        let isEmpty = true
        const isAtLeastOneCategorySelected = Object.values(selectedCategories).some(value => value === true);
        if (editorState) {
            isEmpty = convertToRaw(editorState.getCurrentContent()).blocks.every(b => b.text.trim() === "");
        }
        if (campaignTitle && !isEmpty && campaignStart && campaignEnd && isAtLeastOneCategorySelected) {
            return true
        }
        else {
            return false
        }
    }

    useEffect(() => {
        if (campaign) {
            setCampaignTitle(campaign.title)
            setCampaignImages(campaign.images)
            setCampaignStart(moment(campaign.start * 1000))
            setCampaignEnd(moment(campaign.end * 1000))
            setCampaignLink(campaign.link)
            const contentBlock = htmlToDraft(campaign.description);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
            setIsActive(campaign.status === "1" ? true : false)
            if (campaign.targetAudience) {
                const updatedCategories = {
                    esnaf: campaign.targetAudience.includes('esnaf'),
                    easypoint: campaign.targetAudience.includes('easypoint'),
                    personalCustomers: campaign.targetAudience.includes('personal-customers'),
                };
                setSelectedCategories(updatedCategories);
            }

        }
    }, [campaign]);

    const categoryMapping = {
        esnaf: 'esnaf',
        easypoint: 'easypoint',
        personalCustomers: 'personal-customers',
    };

    // True olan kategorileri alıp bir array oluşturma
    const getSelectedCategoriesArray = () => {
        return Object.keys(selectedCategories)
            .filter(category => selectedCategories[category]) // True olan kategorileri filtreler
            .map(category => categoryMapping[category]); // Filtrelenenleri diziye dönüştürür
    };

    const formattedDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const extractBase64Images = (data) => {
        return data.map(item => item.base64.base64);
    };

    const handleOnClose = () => {
        onClose()
        setCampaignTitle("")
        setCampaignImages([])
        setCampaignStart()
        setCampaignEnd()
        setCampaignLink("")
        setEditorState(EditorState.createEmpty());
        setSelectedCampaign()
        setSelectedCategories({
            esnaf: false,
            mallLogistics: false,
            easypoint: false,
            personalCustomers: false,
            corporationalCustomers: false,
            porters: false
        })
        setIsActive(false)
    }

    const handleSave = () => {
        if (campaign) {
            updateCampaign()
        }
        else {
            createCampaign()
        }
    }

    const createCampaign = () => {
        setIsLoading(true)
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlContent = draftToHtml(rawContentState);

        let cleanedString = htmlContent.replaceAll("&nbsp;", "");

        Services.CreateCampaign(parsedUser.token, campaignTitle, cleanedString, formattedDate(new Date(campaignStart)), formattedDate(new Date(campaignEnd)), extractBase64Images(campaignImages), campaignLink, getSelectedCategoriesArray())
            .then((res) => {
                if (res.data.status) {
                    toast.success("Kampanya başarıyla eklendi.")
                    getCampaigns(parsedUser.token)
                    setIsLoading(false)
                }
                else {
                    toast.error("Kampanya eklenirken bir sorunla karşılaşıldı.")
                    setIsLoading(false)
                }
                handleOnClose()
            })
            .catch((err) => console.log(err));
    }

    const updateCampaign = () => {
        setIsLoading(true)
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlContent = draftToHtml(rawContentState);

        let cleanedString = htmlContent.replaceAll("&nbsp;", "");

        const campaignImageData = (campaignImages[0]?.file ? extractBase64Images(campaignImages) : null);

        Services.UpdateCampaign(parsedUser.token, campaign.campaignID, campaignTitle, cleanedString, formattedDate(new Date(campaignStart)), formattedDate(new Date(campaignEnd)), campaignImageData, campaignLink, getSelectedCategoriesArray(), isActive)
            .then((res) => {
                if (res.data.status) {
                    toast.success("Kampanya başarıyla güncellendi.")
                    getCampaigns(parsedUser.token)
                    setIsLoading(false)
                }
                else {
                    toast.error("Kampanya güncellenirken bir sorunla karşılaşıldı.")
                    setIsLoading(false)
                }
                handleOnClose()
            })
            .catch((err) => console.log(err));
    }

    const handleSwitchChange = () => {
        setIsActive(!isActive)
    };

    return (
        <Modal sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }} open={open}>
            <Card sx={{ padding: '25px', overflowY: 'auto', maxHeight: '95%' }}>
                <div style={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', display: 'flex' }}>
                    {!campaign ? <Typography variant='h6'>Kampanya Ekle</Typography> : <Typography variant='h6'>Kampanya Düzenle</Typography>}
                    {campaign && !isLoading &&
                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                            <Typography>
                                Aktiflik Durumu:
                            </Typography>
                            <Switch
                                checked={isActive}
                                onChange={handleSwitchChange}
                                name="isActive"
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </div>
                    }
                </div>
                <>
                    {isLoading ?
                        <div style={{ width: '250px', height: '250px', marginTop: '15px', marginBottom: '15px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            <CircularProgress size={22} color="error" />
                        </div>
                        :
                        <>

                            <>
                                <TextField
                                    fullWidth
                                    label="Kampanya Adı *"
                                    name="campaignTitle"
                                    value={campaignTitle}
                                    onChange={(e) => setCampaignTitle(e.target.value)}
                                    margin="normal"
                                />

                                <Typography sx={{ marginTop: '10px', marginBottom: '15px', fontWeight: '600' }}>Hedef Kitle</Typography>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={selectedCategories.esnaf} onChange={handleCategoryChange} name="esnaf" />}
                                        label="Esnaf"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={selectedCategories.easypoint} onChange={handleCategoryChange} name="easypoint" />}
                                        label="EasyPoint"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={selectedCategories.personalCustomers} onChange={handleCategoryChange} name="personalCustomers" />}
                                        label="Bireysel Müşteriler"
                                    />
                                </FormGroup>

                                <div style={{maxWidth:'1000px', border: '1px solid rgba(145, 158, 171, 0.24)', padding: '15px', borderRadius: '8px' }}>
                                    <Editor
                                        editorClassName='editor'
                                        editorState={editorState}
                                        onEditorStateChange={setEditorState}
                                        toolbar={{
                                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                                        }}
                                    />
                                </div>

                                <TextField
                                    fullWidth
                                    label="Kampanya Linki"
                                    name="campaignTitle"
                                    value={campaignLink}
                                    onChange={(e) => setCampaignLink(e.target.value)}
                                    margin="normal"
                                />
                                <Typography sx={{ marginTop: '10px', marginBottom: '15px', fontWeight: '600' }}>Kampanya Resimleri</Typography>
                                <div style={{ width: '100%' }}>
                                    <MultipleImageUpload setFiles={setCampaignImages} files={campaignImages} />
                                </div>

                                <div style={{ width: '100%', marginTop: '15px', justifyContent: 'space-between', display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            sx={{ marginRight: '8px', width: '50%' }}
                                            format="DD/MM/YYYY"
                                            label="Başlangıç Tarihi"
                                            value={campaignStart}
                                            onChange={(newValue) => {
                                                setCampaignStart(newValue);
                                                setCampaignEnd(null);
                                            }}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            sx={{ width: '50%' }}
                                            format="DD/MM/YYYY"
                                            label="Bitiş Tarihi"
                                            value={campaignEnd}
                                            minDate={campaignStart}
                                            disabled={!campaignStart}
                                            onChange={(newValue) => setCampaignEnd(newValue)}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div style={{ width: '100%', alignItems: 'center', justifyContent: 'flex-end', gap: '15px', display: 'flex', marginTop: '15px' }}>
                                    <CancelButton onClick={handleOnClose} label={"Vazgeç"} />
                                    <CompleteButton disabled={!allFieldsAreValid()} onClick={handleSave} label={"Kaydet"} />
                                </div>
                            </>

                        </>
                    }
                </>

            </Card>
        </Modal >
    )
}
