import React from 'react';
import { Button } from '@mui/material';

const CancelButton = ({ onClick, label, sx }) => {
    return (
        <>
            <Button
                sx={{
                    width:162,
                    fontSize: 14,
                    fontFamily: 'Inter',
                    fontWeight: 600,
                    color:'#D92D20',
                    letterSpacing: 0.4,
                    height: 46,
                    borderRadius: '5px',
                    borderColor:'#D92D20',
                    padding: '8px 16px 8px 16px',
                    gap: '6px',
                    '&:hover':{
                        backgroundColor: '#fff',
                        borderColor:'#D92D20'
                    },
                    ...sx
                }}
                variant="outlined"
                onClick={onClick}
            >
                {label.toLocaleUpperCase('tr-TR')}
            </Button >
        </>
    );
};

export default CancelButton;
