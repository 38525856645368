import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// @mui
import { Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import api from 'src/services/BaseService';
import Services from 'src/services/Services';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [token, setToken] = useState('');

  const sendRequest = (username) => {
    console.log(username, token);
    setLoading(true);
    Services.ForgotPassword(token, username)
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          toast.success(
            'Şifre yenileme talebinizi aldık. Yeni şifrenizi e-postanızdan/telefonunuzdan bulabilirsiniz.'
          );
          setTimeout(() => {
            navigate('/login');
          }, 2500);
        } else {
          toast.error('Girilen bilgiyle eşleşen bir kullanıcı bulunamadı.');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Şifre yenileme sırasında bir hata meydana geldi.');
      })
      .finally(() => setLoading(false));
  };

  const handleClick = (event) => {
    event.preventDefault();
    sendRequest(event.target.elements.username.value);
  };

  useEffect(() => {
    Services.GetToken().then((res) => {
      if (res.data.status) {
        setToken(res.data.result.token);
      }
    });
  }, []);

  return (
    <>
      <form id="forgot-form" onSubmit={handleClick}>
        <Stack spacing={3}>
          <TextField
            required
            id="username"
            name="username"
            label="E-posta/Telefon"
            autoComplete="off"
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ my: 2 }}
        >
          <Typography
            onClick={() => navigate('/login')}
            sx={{ color: '#DC3545', cursor: 'pointer' }}
          >
            Girişe Dön
          </Typography>
        </Stack>

        <LoadingButton
          disabled={loading}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
        >
          Talebi Gönder
        </LoadingButton>
      </form>
    </>
  );
}
