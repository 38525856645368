import React from 'react';
import { Typography, Button, Stack } from '@mui/material';

const UserStatusFilters = ({ activeOnClick, passiveOnClick, pendingOnClick }) => {

    return (
        <Stack sx={{my:1, marginLeft: 1 }} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', display: 'flex' }}>
            <Typography
                sx={{
                    fontWeight: 500,
                    fontFamily: 'Inter',
                    fontSize: 14,
                    color: '#6B7280',
                    letterSpacing: 0.15
                }}
            >
                Kullanıcı Durumu:
            </Typography>
            {activeOnClick &&
                <>
                    <Button onClick={activeOnClick}
                        variant='outlined'
                        sx={{
                            background: '#ECFDF3',
                            padding: " 4px 12px 4px 10px",
                            borderRadius: '5px',
                            borderColor: '#ABEFC6',
                            borderWidth: 1,
                            color: '#067647',
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 500,
                            letterSpacing: 0.15,
                            marginLeft: '10px',
                            '&:hover': {
                                backgroundColor: '#ceded5',
                                borderColor: '#93cfaa'
                            }
                        }}>

                        • Aktif Kullanıcılar

                    </Button>
                </>
            }

            {passiveOnClick &&
                <>
                    <div
                        style={{
                            width: '1%',
                            height: '1px',
                            borderTop: '1px solid #ccc',
                            transform: 'rotate(90deg)',
                        }}
                    ></div>
                    <Button onClick={passiveOnClick}
                        variant='outlined'
                        sx={{
                            background: '#F9FAFB',
                            padding: " 4px 12px 4px 10px",
                            borderRadius: '5px',
                            borderColor: '#EAECF0',
                            borderWidth: 1,
                            color: '#344054',
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 500,
                            letterSpacing: 0.15,
                            '&:hover': {
                                backgroundColor: '#cacbcc',
                                borderColor: '#b1b3b5'
                            }
                        }}>
                        • Pasif Kullanıcılar
                    </Button>
                </>
            }

            {pendingOnClick &&
                <>
                    <div
                        style={{
                            width: '1%',
                            height: '1px',
                            borderTop: '1px solid #ccc',
                            transform: 'rotate(90deg)',
                        }}
                    ></div>
                    <Button onClick={pendingOnClick}
                        variant='outlined'
                        sx={{
                            background: '#FFFAEB',
                            padding: " 4px 12px 4px 10px",
                            borderRadius: '5px',
                            borderColor: '#FEDF89',
                            borderWidth: 1,
                            color: '#B54708',
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 500,
                            letterSpacing: 0.15,
                            '&:hover': {
                                backgroundColor: '#e3ded1',
                                borderColor: '#d1b871'
                            }
                        }}>
                        • Bekleyen Kullanıcılar
                    </Button>
                </>
            }

        </Stack>
    );
};

export default UserStatusFilters;