import FilterListIcon from '@mui/icons-material/FilterList';
import React from 'react';
import { Button } from '@mui/material';

const ClearButton = ({ onClick,sx }) => {

    return (
        <>
            <Button
                sx={{
                    color: '#6B7280',
                    borderColor: '#00000036',
                    fontWeight: 500,
                    fontSize:14,
                    fontFamily:'Inter',
                    letterSpacing:0.4,
                    '&:hover': {
                        backgroundColor:'#ccc',
                        borderColor: '#9CA3AF',
                    },
                    width:94,
                    height:40,
                    borderRadius: '5px',
                    padding: '8px 16px 8px 16px',
                    gap:'6px',
                    ...sx
                }}
                variant="outlined"
                onClick={onClick}
            ><FilterListIcon/>
                Filtreler
            </Button>
        </>
    );
};

export default ClearButton;