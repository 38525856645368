const getUser = () => {
  if (typeof window !== 'undefined') {
    const localToken = JSON.parse(localStorage.getItem('user'));
    return localToken;
  }
  return null;
};

const getCustomer = () => {
  if (typeof window !== 'undefined') {
    const localToken = JSON.parse(localStorage.getItem('customer'));
    return localToken;
  }
  return null;
};

const getInfos = () => {
  try {
    return getUser();
  } catch (e) {
    return null;
  }
};

export default {
  getInfos,
  getUser,
  getCustomer,
};
