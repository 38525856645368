import { useEffect, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import packageJson from '../../../../package.json';
import {
  Input,
  Slide,
  Button,
  IconButton,
  InputAdornment,
  ClickAwayListener,
  Stack,
  FormControl,
  Select,
  OutlinedInput,
  Dialog,
  MenuItem,
  Typography,
} from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// component
import Iconify from '../../../components/iconify';
import { tokenService } from 'src/services';
import { useDispatch } from 'react-redux';
import { updateCustomerValue } from 'src/store/customer';

// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledSearchbar = styled('div')(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// ----------------------------------------------------------------------

export default function Searchbar() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedCustomerStatus, setSelectedCustomerStatus] = useState([]);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   localStorage.setItem('mallID', value.id);
  //   dispatch(updateCustomerValue(value));
  //   setSelectedCustomerStatus(value);
  //   window.location.reload();
  // };

  // useEffect(() => {
  //   let customer;
  //   const mallID = localStorage.getItem('mallID');
  //   if (tokenService.getUser()?.authLocations?.branch) {
  //     if (tokenService.getUser()?.authLocations?.branch?.lenght > 0) {
  //       customer = tokenService.getUser()?.authLocations?.branch[0];
  //     } else {
  //       customer = tokenService.getUser()?.authLocations?.branch['0'];
  //     }
  //   } else {
  //     if (tokenService.getUser()?.authLocations?.mall?.length > 0) {
  //       customer = tokenService
  //         .getUser()
  //         ?.authLocations?.mall.find((item) => item.id === mallID);
  //     } else {
  //       customer = tokenService.getUser()?.authLocations?.mall['0'];
  //     }
  //   }
  //   setSelectedCustomerStatus(customer);
  //   dispatch(updateCustomerValue(customer));
  // }, []);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <Stack
          mr={1}
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <FormControl sx={{ minWidth: 250 }}>
            {/* <Select
              sx={{ marginRight: '8px' }}
              displayEmpty
              value={selectedCustomerStatus}
              input={<OutlinedInput />}
              onChange={handleChange}
              renderValue={(selected) => {
                return selected?.title;
              }}
              MenuProps={MenuProps}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {tokenService.getUser()?.authLocations?.branch ? (
                tokenService.getUser()?.authLocations?.branch.length > 0 ? (
                  tokenService
                    .getUser()
                    ?.authLocations?.branch.map((x, index) => (
                      <MenuItem key={index} value={x}>
                        {x?.title}
                      </MenuItem>
                    ))
                ) : (
                  <MenuItem
                    key={tokenService.getUser()?.authLocations?.branch.id}
                    value={tokenService.getUser()?.authLocations?.branch}
                  >
                    {tokenService.getUser()?.authLocations?.branch?.title}
                  </MenuItem>
                )
              ) : tokenService.getUser()?.authLocations?.mall?.length > 0 ? (
                tokenService.getUser()?.authLocations?.mall.map((x, index) => (
                  <MenuItem key={index} value={x}>
                    {x?.title}
                  </MenuItem>
                ))
              ) : (
                <MenuItem
                  key={tokenService.getUser()?.authLocations?.branch?.id}
                  value={tokenService.getUser()?.authLocations?.branch}
                >
                  {tokenService.getUser()?.authLocations?.branch?.title}
                </MenuItem>
              )}
            </Select> */}
            <Typography sx={{ color: '#b5b5b5', marginLeft: '20px' }}>
              Versiyon: {packageJson.version}
            </Typography>
          </FormControl>
        </Stack>
      </div>
    </ClickAwayListener>
  );
}

