import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  FormControl,
  Select,
  OutlinedInput,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Box,
  Divider,
  FormControlLabel,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Iconify from '../iconify/Iconify';
import api from 'src/services/BaseService';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

CreateWorkOrderModal.propTypes = {
  openCreateModal: PropTypes.bool,
  onCreateModal: PropTypes.func,
  customer: PropTypes.object,
  locations: PropTypes.array,
};

export default function CreateWorkOrderModal({
  openCreateModal,
  onCreateModal,
  customer,
  locations,
}) {
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedCar, setSelectedCar] = useState();
  const [status, setStatus] = useState([1]);
  const [boxNumber, setBoxNumber] = useState();
  const [getCargoNumber, setGetCargoNumber] = useState();
  const [cargoList, setCargoList] = useState([]);
  const [direction, setDirection] = useState();
  const [openCargoSelect, setOpenCargoSelect] = useState(false);
  const [multipleTransport, setMultipleTransport] = useState();

  function createNewJob() {
    const data = [];
    cargoList.forEach((x) => {
      const request = {
        customer: customer?.customer?.branchID,
        type: 'post-office',
        transportationTool: selectedCar,
        orderInventory: +x.cargoNumber,
        direction: direction.handle,
        shipmentFirm: x.cargoName,
        isMultipleTransportation: multipleTransport,
        isOnlyCreateJob: true,
        status: direction.handle === 'StoreToMallLogistics' ? 12 : 5,
        from: direction.from,
        to: direction.to,
      };
      data.push(request);
    });

    api
      .post('mall-logistics/create', data)
      .then((response) => {
        if (response.data.status) {
          setStatus([1]);
          setSelectedCar(null);
          setBoxNumber(null);
          setGetCargoNumber(null);
          setCargoList([]);
          setDirection(null);
          onCreateModal();
          toast.success('Başarıyla oluşturuldu.');
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => toast.error(error.message));
  }

  const handleClose = (event, reason) => {
    if (reason && reason == 'backdropClick') {
      setStatus([1]);
      setSelectedCar(null);
      setBoxNumber(null);
      setGetCargoNumber(null);
      setCargoList([]);
      setDirection(null);
      onCreateModal();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let cargos = [];
    cargos = cargos.concat(cargoList);
    cargos.push({
      cargoName: event.target.cargotitle.value,
      cargoNumber: event.target.cargonumber.value,
    });

    setCargoList(cargos);
    setOpenCargoSelect(false);
  };

  useEffect(() => {
    console.log(customer.customer.title);
    if (customer.customer.branchID) {
      setSelectedCustomer(customer.customer.title);
    }
  }, []);

  return (
    <>
      <Dialog
        open={openCreateModal}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Stack alignItems="center" spacing={2} sx={{ mt: 1, padding: 2.5 }}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {status.includes(1) && (
                <Link
                  sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  color="inherit"
                  onClick={() => setStatus([...status, 1])}
                >
                  <Typography
                    underline="hover"
                    key="1"
                    color="inherit"
                    variant={status[status.length - 1] == 1 ? 'h6' : 'p'}
                  >
                    Müşteri Seçiniz
                  </Typography>
                </Link>
              )}
              {status.includes(2) && (
                <Link
                  sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  color="inherit"
                  onClick={() => setStatus([...status, 2])}
                >
                  <Typography
                    underline="hover"
                    key="2"
                    color="inherit"
                    href="/material-ui/getting-started/installation/"
                    variant={status[status.length - 1] == 2 ? 'h6' : 'p'}
                  >
                    Araç Seçiniz
                  </Typography>
                </Link>
              )}

              {status.includes(3) && (
                <Link
                  sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  color="inherit"
                  onClick={() => setStatus([...status, 3])}
                >
                  <Typography
                    key="3"
                    color="inherit"
                    variant={status[status.length - 1] == 3 ? 'h6' : 'p'}
                  >
                    Koli Adedi Giriniz
                  </Typography>
                </Link>
              )}

              {status.includes(4) && (
                <Link
                  sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  color="inherit"
                  onClick={() => setStatus([...status, 4])}
                >
                  <Typography
                    key="3"
                    color="inherit"
                    variant={status[status.length - 1] == 4 ? 'h6' : 'p'}
                  >
                    Kargo Firması ve Adet Giriniz
                  </Typography>
                </Link>
              )}

              {status.includes(5) && (
                <Link
                  sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  color="inherit"
                  onClick={() => setStatus([...status, 5])}
                >
                  <Typography
                    key="3"
                    color="inherit"
                    variant={status[status.length - 1] == 5 ? 'h6' : 'p'}
                  >
                    Yön Seçiniz
                  </Typography>
                </Link>
              )}

              {status.includes(6) && (
                <Typography
                  key="3"
                  color="inherit"
                  variant={status[status.length - 1] == 6 ? 'h6' : 'p'}
                >
                  Özet
                </Typography>
              )}
            </Breadcrumbs>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack alignItems="center" spacing={2} sx={{ mt: 1, padding: 2.5 }}>
            {status[status.length - 1] == 1 && (
              <FormControl sx={{ width: 300, padding: 2.5 }}>
                <Select
                  displayEmpty
                  value={selectedCustomer || customer.customer.title}
                  input={<OutlinedInput />}
                  onChange={(event) => setSelectedCustomer(event.target.value)}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Müşteriler</em>;
                    }

                    return selected;
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="Adidas Kanyon">Adidas Kanyon</MenuItem>
                  <MenuItem value="Loopcode A.Ş.">Loop Code AŞ</MenuItem>
                </Select>
              </FormControl>
            )}

            {status[status.length - 1] == 2 && (
              <>
                <FormControl sx={{ width: 300, padding: 2.5 }}>
                  <ToggleButtonGroup
                    color="primary"
                    size="large"
                    orientation="vertical"
                    value={selectedCar}
                    exclusive
                    onChange={(event, nextView) => {
                      setSelectedCar(nextView);
                    }}
                  >
                    <ToggleButton value="Büyük Araç" aria-label="list">
                      Büyük Araç
                    </ToggleButton>
                    <ToggleButton value=" Küçük Araç" aria-label="small">
                      Küçük Araç
                    </ToggleButton>
                    <ToggleButton value=" Askılı" aria-label="quilt">
                      Askılı
                    </ToggleButton>
                    <ToggleButton value="Palet" aria-label="palet">
                      Palet
                    </ToggleButton>
                    <ToggleButton value="Ring" aria-label="ring">
                      Ring
                    </ToggleButton>
                    <ToggleButton value="Paket" aria-label="package">
                      Paket
                    </ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>

                <FormControlLabel
                  sx={{ marginTop: 2, justifyContent: 'center' }}
                  control={
                    <Checkbox
                      onChange={(e) => setMultipleTransport(e.target.checked)}
                    />
                  }
                  label="Çoklu Gönderim"
                />
              </>
            )}

            {status[status.length - 1] == 3 && (
              <FormControl sx={{ width: 300, padding: 2.5 }}>
                <TextField
                  id="selectcargonum"
                  label="Koli Adedi"
                  type="number"
                  value={boxNumber}
                  onChange={(event) => setBoxNumber(event.target.value)}
                />
              </FormControl>
            )}

            {status[status.length - 1] == 4 && (
              <>
                <FormControl sx={{ width: 500, padding: 2.5 }}>
                  {cargoList &&
                    cargoList.length > 0 &&
                    cargoList.map((x, index) => (
                      <Stack
                        key={index}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography color="inherit" variant="h6">
                          {x.cargoName}
                        </Typography>

                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <FormControl sx={{ width: 200, padding: 2.5 }}>
                            <TextField
                              id="outlined-number"
                              label="Kargo Adedi"
                              type="number"
                              value={x.cargoNumber}
                              onChange={(event) => {}}
                            />
                          </FormControl>

                          <Button
                            color="error"
                            onClick={() => {
                              let cargos = [];
                              cargos = cargos.concat(cargoList);
                              cargos.splice(index, 1);
                              setCargoList(cargos);
                            }}
                          >
                            Kaldır
                          </Button>
                        </Box>
                      </Stack>
                    ))}
                </FormControl>

                <IconButton
                  color="inherit"
                  size="large"
                  onClick={() => setOpenCargoSelect(true)}
                >
                  <Iconify icon={'simple-line-icons:plus'} width="50" />
                </IconButton>
              </>
            )}

            {status[status.length - 1] == 5 && (
              <FormControl sx={{ width: 400, padding: 2.5 }}>
                <ToggleButtonGroup
                  color="primary"
                  size="large"
                  orientation="vertical"
                  value={direction}
                  exclusive
                  onChange={(event, nextView) => {
                    setDirection(nextView);
                  }}
                >
                  {locations.map((x, index) => (
                    <ToggleButton key={index} value={x} aria-label="list">
                      {x.title}
                    </ToggleButton>
                  ))}

                  {/* <ToggleButton
                    value="Mall Logistics'ten Mağazaya"
                    aria-label="small"
                  >
                    Mall Logistics'ten Mağazaya
                  </ToggleButton>
                  <ToggleButton
                    value="Loopcode Depo --> Loopcode Merkez Ofis"
                    aria-label="quilt"
                  >
                    Loopcode Depo --{'>'} Loopcode Merkez Ofis
                  </ToggleButton>
                  <ToggleButton
                    value=" Loopcode Depo --> Loopcode Store"
                    aria-label="palet"
                  >
                    Loopcode Depo --{'>'} Loopcode Store
                  </ToggleButton> */}
                </ToggleButtonGroup>
              </FormControl>
            )}

            {status[status.length - 1] == 6 && (
              <FormControl sx={{ width: 600, padding: 2.5 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1}
                >
                  <Typography color="inherit" variant="h6">
                    Müşteri:
                  </Typography>

                  <Typography color="inherit" variant="p">
                    {selectedCustomer}
                  </Typography>
                </Stack>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                  mb={1}
                >
                  <Typography color="inherit" variant="h6">
                    Yön:
                  </Typography>

                  <Typography color="inherit" variant="p">
                    {direction.title}
                  </Typography>
                </Stack>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                  mb={1}
                >
                  <Typography color="inherit" variant="h6">
                    Taşıma Aracı:
                  </Typography>

                  <Typography color="inherit" variant="p">
                    {selectedCar}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                  mb={1}
                >
                  <Typography color="inherit" variant="h6">
                    Toplam Adet:
                  </Typography>

                  {cargoList && cargoList.length > 1 ? (
                    <Typography color="inherit" variant="p">
                      {cargoList.reduce(
                        (a, b) => +a.cargoNumber + +b.cargoNumber
                      )}
                    </Typography>
                  ) : (
                    cargoList[0].cargoNumber
                  )}
                </Stack>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                  mb={1}
                >
                  <Typography color="inherit" variant="h6">
                    Koli Adedi:
                  </Typography>

                  <Typography color="inherit" variant="p">
                    {boxNumber}
                  </Typography>
                </Stack>
                <Divider />
                {cargoList.map((x, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                    mb={1}
                  >
                    <div>
                      <span>{x.cargoName}</span>
                      <Typography color="inherit" variant="h6"></Typography>
                    </div>

                    <div>
                      <span>{x.cargoNumber}</span>
                      <Typography color="inherit" variant="p"></Typography>
                    </div>
                  </Stack>
                ))}
                <Divider />

                <FormControlLabel
                  sx={{ marginTop: 2, justifyContent: 'center' }}
                  control={<Checkbox defaultChecked />}
                  label="Üzerime zimmetlensin"
                />
              </FormControl>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
          <Button
            variant="contained"
            size="large"
            color="ochre"
            onClick={() =>
              setStatus([...status, status[status.length - 1] - 1])
            }
          >
            Geri Dön
          </Button>
          {status[status.length - 1] == 6 ? (
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                createNewJob();
              }}
            >
              Oluştur
            </Button>
          ) : (
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                console.log(cargoList);
                setStatus([...status, status[status.length - 1] + 1]);
              }}
            >
              Devam Et
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={openCargoSelect} onClose={() => setOpenCargoSelect(false)}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Kargo Firması Seçin</DialogTitle>
          <DialogContent>
            <FormControl sx={{ width: 300, padding: 2.5 }}>
              <Select
                displayEmpty
                id="cargotitle"
                name="cargotitle"
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Kargo Firması</em>;
                  }

                  return selected;
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="MNG Kargo">MNG Kargo</MenuItem>
                <MenuItem value="Sürat Kargo">Sürat Kargo</MenuItem>
                <MenuItem value="Aras Kargo">Aras Kargo</MenuItem>
                <MenuItem value="Diğer">Diğer</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ width: 200, padding: 2.5 }}>
              <TextField
                id="cargonumber"
                name="cargonumber"
                label="Kargo Adedi"
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button type="submit">Kaydet</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
