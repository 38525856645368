import React from 'react';
import { Button } from '@mui/material';

const FilterButton = ({ onClick, sx, label }) => {

    return (
        <>
            <Button
                sx={{
                    boxShadow: 'none',
                    backgroundColor: '#DC423C',
                    fontWeight: 500,
                    fontSize: 14,
                    fontFamily: 'Inter',
                    width: 97,
                    height: 40,
                    borderRadius: '5px',
                    '&:hover': {
                        backgroundColor: '#B53228',
                    },
                    padding: '8px 16px 8px 16px',
                    gap: '6px',
                    ...sx
                }}
                variant="contained"
                onClick={onClick}
            >
               {label ? label : "Filtrele"}
            </Button>
        </>
    );
};

export default FilterButton;