import * as React from 'react';
import Box from '@mui/material/Box';
import Scrollbar from '../components/scrollbar';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { WarningOutlined } from '@mui/icons-material';
import {
  Card,
  Stack,
  Typography,
  Button,
  Divider,
  Modal,
  Tabs,
  Tab,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import api from 'src/services/BaseService';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.min.css';
import { registerAllModules } from 'handsontable/registry';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

registerAllModules();
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function FastReport() {
  const hotRef = React.useRef(null);
  const initialRows = Array.from({ length: 50 }, (_, index) => ({
    id: index + 1,
    barcode: '',
    name: '',
    corporation: '',
    no: '',
    shipmentFirm: '',
    direction: '',
    packageCount: '',
    corporationID: '',
  }));

  const [selectedTab, setSelectedTab] = React.useState('DHL');

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getColumnHeaders = () => {
    switch (selectedTab) {
      case 'DHL':
        return [
          'NO',
          'İŞLEM TARİHİ',
          'KONŞİMENTO NUMARASI (WAYBİLL)',
          'GÖNDEREN ADI',
          'ALICI ADI',
          'İŞLEM YAPAN EP PERSONELİ',
          'KURYEYE TESLİM EDEN PERSONEL ADI',
          'TESLİM TARİHİ',
          'TUTAR (TL)',
          'LOKASYON (FORMATI DEĞİŞTİRME)',
          'AÇIKLAMA',
        ];
      case 'TAX FREE':
        return [
          'NO',
          'MARKA',
          'İŞLEM TARİHİ',
          'MÜŞTERİ FATURA NO',
          'MÜŞTERİ İSMİ',
          'ÜLKE',
          'FATURA TUTARI',
          'ÖDENEN İADE TUTAR',
          'ÖDEME TÜRÜ',
          'MAĞAZA',
          'LOKASYON (FORMATI DEĞİŞTİRME)',
          'EP ÇALIŞANI',
          'MAĞAZA LOKASYONU',
        ];
      case 'ARAS':
        return [
          'NO',
          'KARGO GELİŞ TARİHİ',
          'GÖNDERİ NUMARASI',
          'MÜŞTERİ AD SOYAD',
          'EP PERSONEL AD SOYAD',
          'AÇIKLAMA',
          'İŞLEM TÜRÜ',
          'TUTAR',
          'ÖDEME TÜRÜ',
          'LOKASYON (FORMATI DEĞİŞTİRME)',
        ];
      default:
        return [];
    }
  };

  const getColumnSettings = () => {
    const headers = getColumnHeaders();
    return headers.map((header) => {
      if (header.includes('TARİH')) {
        return {
          type: 'date',
          strict: false,
          className: 'htCenter htMiddle',
          placeholder: 'GG/AA/YYYY',
          datePickerConfig: {
            firstDay: 1,
            showWeekNumber: true,
            i18n: {
              previousMonth: 'Previous Month',
              nextMonth: 'Next Month',
              months: [
                'Ocak',
                'Şubat',
                'Mart',
                'Nisan',
                'Mayıs',
                'Haziran',
                'Temmuz',
                'Ağustos',
                'Eylül',
                'Ekim',
                'Kasım',
                'Aralık',
              ],
              weekdays: [
                'Pazar',
                'Pazartesi',
                'Salı',
                'Çarşamba',
                'Perşembe',
                'Cuma',
                'Cumartesi',
              ],
              weekdaysShort: ['Pa', 'Pzt', 'Salı', 'Ça', 'Pe', 'Cu', 'Ct'],
            },
          },
          width: 100,
        };
      }
      return {
        strict: false,
        className: 'htCenter htMiddle',
      };
    });
  };

  const formatDate = (value) => {
    if (!value) return '';
    if (value.length === 4) {
      return moment(value, 'DDMM').format('DD/MM/YYYY');
    } else if (value.length === 8) {
      return moment(value, 'DDMMYYYY').format('DD/MM/YYYY');
    }
    return value;
  };

  const [tableData, setTableData] = React.useState(initialRows);
  const [customerList, setCustomerList] = React.useState([]);
  const [isSuccessModalOpen, setSuccessModalOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [failedRowsCount, setFailedRowsCount] = React.useState();
  const [muhaberatID, setMuhaberatID] = React.useState('');

  const handleCreate = () => {
    const table = hotRef.current.hotInstance.getData();
    let nonEmptyRows = table
      .filter((row) => {
        const slicedRow = row.slice(1, -1);
        return slicedRow.every((cell, index) => {
          return cell !== '' && cell !== null;
        });
      })
      .map((row) => {
        const customerName = row[2];
        const corporational = row[3];
        const cargoFirmName = row[6];
        let direction = '';
        if (row[7] === 'Giden Kargo') {
          direction = 'CustomerToMallLogistics';
        } else if (row[7] === 'Gelen Kargo') {
          direction = 'MallLogisticsToCustomer';
        } else {
          direction = '';
        }
        const matchingCustomer = customerList.find(
          (item) => item.value === corporational
        );
        const matchingCargo = cargoList.find((item) => item === cargoFirmName);
        if (matchingCustomer && matchingCargo) {
          return {
            barcode: row[1],
            shipmentFirm: cargoFirmName,
            isMultipleTransportation: false,
            orderInventory: 1,
            isOnlyCreateJob: true,
            type: 'post-office',
            isPhysicalBarcode: true,
            status: 5,
            direction: direction,
            transportationTool: 'Ring',
            customer: matchingCustomer.id,
            from:
              row[7] === 'Giden Kargo'
                ? matchingCustomer.locationID
                : muhaberatID,
            to:
              row[7] === 'Gelen Kargo'
                ? muhaberatID
                : matchingCustomer.locationID,
            sentToEasyPoint: muhaberatID,
            customerName: customerName,
            index: row[0],
            companyName: row[3],
            packageCount: row[8],
            requestedInventoryCount: row[8],
            orderInventory: row[8],
          };
        }
      })
      .filter(Boolean);

    if (nonEmptyRows?.length > 0) {
      api
        .post('mall-logistics/create', nonEmptyRows)
        .then((response) => {
          const numJobsCreated = nonEmptyRows.length;
          if (response.data.status === true) {
            const data = hotRef.current.hotInstance.getData();
            const newData = data.map((row) => {
              const matchingRow = nonEmptyRows.find(
                (nonEmptyRow) => nonEmptyRow?.index === row[0]
              );
              if (matchingRow) {
                return [row[0], '', '', '', '', '', '', '', '', ''];
              }
              return row;
            });
            const filledRowsCount = newData.filter((row) =>
              row.slice(1).some((cell) => cell !== '')
            ).length;
            openSuccessModal(
              `Toplam ${numJobsCreated} adet iş oluştu.`,
              filledRowsCount
            );
            setTableData(newData);
          } else {
            openSuccessModal('Lütfen boş alan bırakmayınız', true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      openSuccessModal('Lütfen boş alan bırakmayınız', true);
    }
  };

  const openSuccessModal = (message, count) => {
    setSuccessMessage(message);
    setFailedRowsCount(count);
    setSuccessModalOpen(true);
  };

  React.useEffect(() => {
    const mall = localStorage.getItem('mallID');
    api
      .post('flow/get-malls-branches', {
        mallID: mall,
      })
      .then((response) => {
        const result = response.data.result;
        const processedDataList = result?.map((item) => ({
          value: item.title,
          id: item.branchID,
          corporationID: item.corporationID ? item.corporationID : '-',
          locationID: item.locations[0].locationID,
        }));
        setCustomerList(processedDataList);
      });

    api
      .post('/malls/get-malls', {
        withOffices: true,
      })
      .then((res) => {
        if (res.data.status) {
          const foundMall = res.data.result.find((item) => item.id === mall);

          if (foundMall) {
            const foundOffice = foundMall.offices.find(
              (item) => item.isMuhaberat === '1'
            );
            setMuhaberatID(foundOffice?.id);
          }
        }
      });
  }, []);

  React.useEffect(() => {
    if (hotRef.current) {
      const hotInstance = hotRef.current.hotInstance;
      hotInstance.addHook('afterChange', onAfterChange);
      hotInstance.updateSettings({
        data: initialRows.map((row) => [
          row.id,
          row.barcode,
          row.name,
          row.corporation,
          row.no,
          row.shipmentFirm,
          '',
          row.packageCount,
          row.corporationID,
        ]),
        colHeaders: getColumnHeaders(),
        columns: getColumnSettings(),
      });
    }
  }, [hotRef, customerList, selectedTab]);

  const onAfterChange = (changes, source) => {
    if (!hotRef.current) return;
    const hotInstance = hotRef.current.hotInstance;

    if (source === 'Autofill.fill') {
      setCopyLoading(false);
    }
    const dataToSet = [];
    changes?.forEach(([row, col, prevValue, newValue]) => {
      const currentValue = hotInstance.getDataAtCell(row, col);

      const columnHeaders = getColumnHeaders();
      if (columnHeaders[col].includes('TARİH')) {
        let formattedValue = newValue?.replace(/\./g, '/');
        if (newValue?.includes('.') || newValue?.includes('-')) {
          formattedValue = newValue?.replace(/\./g, '/');
          formattedValue = formattedValue?.replace(/-/g, '/');
        }
        if (
          !newValue?.includes('.') &&
          !newValue?.includes('-') &&
          newValue?.length === 8
        ) {
          formattedValue = newValue?.replace(
            /(\d{2})(\d{2})(\d{4})/,
            '$1/$2/$3'
          );
        }
        if (formattedValue !== currentValue) {
          dataToSet.push([row, col, formattedValue]);
        }
      }
    });

    if (dataToSet.length > 0) {
      hotInstance.setDataAtCell(dataToSet);
    }
  };

  return (
    <>
      <Helmet>
        <title> Hızlı Rapor | Easy Point Panel </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Hızlı Rapor
          </Typography>

          <div>
            <Button
              onClick={handleCreate}
              variant="contained"
              endIcon={<SendIcon />}
              sx={{ marginRight: '12px' }}
            >
              Kaydet
            </Button>
          </div>
        </Stack>

        <Card>
          <Scrollbar>
            <Box sx={{ width: '100%' }}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="kargo seçimi tabları"
                sx={{ borderBottom: 1, borderColor: 'divider' }}
              >
                <Tab label="DHL" value="DHL" />
                <Tab label="TAX FREE" value="TAX FREE" />
                <Tab label="ARAS" value="ARAS" />
              </Tabs>
              <HotTable
                ref={hotRef}
                rowHeights={35}
                licenseKey="non-commercial-and-evaluation"
                data={tableData}
                persistentState={true}
                colHeaders={getColumnHeaders()}
                columns={getColumnSettings()}
                stretchH="all"
                afterChange={onAfterChange}
              />
            </Box>
          </Scrollbar>
        </Card>
        <Modal
          open={isSuccessModalOpen}
          onClose={() => {
            setSuccessModalOpen(false);
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            {successMessage.includes('Lütfen boş alan bırakmayınız') ? (
              <WarningOutlined sx={{ color: 'orange', fontSize: 64 }} />
            ) : (
              <CheckCircleIcon sx={{ color: 'green', fontSize: 64 }} />
            )}
            <Typography variant="h6">{successMessage}</Typography>
            {failedRowsCount > 0 && (
              <>
                <Divider sx={{ my: 2, backgroundColor: 'gray' }} />{' '}
                <Typography variant="h6">
                  Uyarı: {failedRowsCount} satırınızın verileri doldurulmadığı
                  için bu satırlarda iş oluşturulamadı.
                </Typography>
              </>
            )}
            <Button
              onClick={() => {
                setSuccessModalOpen(false);
                if (!successMessage.includes('Lütfen boş alan bırakmayınız')) {
                  // Success modal logic if needed
                }
              }}
            >
              Tamam
            </Button>
          </Box>
        </Modal>
      </Stack>
    </>
  );
}
