import { TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CompleteButton from 'src/components/shared/CompleteButton';
import Services from 'src/services/Services';

export default function SendPanic() {
    const [message, setMessage] = useState("");
    const [token, setToken] = useState("");

    useEffect(() => {
        Services.GetToken()
            .then((res) => {
                setToken(res.data.result.token);
            })
            .catch((err) => console.log(err));
    }, []);

    const sendPanic = () => {
        Services.SendPanic(token, message)
            .then((res) => {
                console.log(res.data, "Send Panic");
            })
            .catch((err) => console.log(err));
    }

    return (
        <div>
            <TextField
                label="Mesaj"
                variant="outlined"
                multiline
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                sx={{ width: '250px', height: '250px' }}
            />
            <CompleteButton label={"Gönder"} onClick={sendPanic} />
        </div>
    )
}
