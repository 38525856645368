import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  const logo = (
    // <div
    //   style={{
    //     height: 120,
    //     width: 120,
    //     backgroundColor: 'white',
    //     display: 'flex',
    //     alignItems: 'center',
    //     alignSelf: 'center',
    //     justifyContent: 'center',
    //     borderRadius: 60,
    //   }}
    // >
    //   <Box
    //     component="img"
    //     src="/assets/logo.png"
    //     sx={{
    //       cursor: 'pointer',
    //       ...sx,
    //     }}
    //   />
    // </div>
    <div style={{ marginTop: 60, marginBottom: 40 }}>
      <Box
        component="img"
        src="/assets/ep_logo.png"
        sx={{
          cursor: 'pointer',
          ...sx,
        }}
      />
    </div>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
