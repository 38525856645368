import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';

const DeliveryModal = ({
  open,
  count,
  onClose,
  onBack,
  onDeliver,
  onComplete,
  selectedItems,
}) => {
  const [step, setStep] = useState(1);
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [note, setNote] = useState('');
  const [code, setCode] = useState('');
  console.log(selectedItems);
  const handleNext = () => {
    if (step === 1) {
      onDeliver(name, lastname, phone, note);
      setStep(2);
    } else {
      //   onClose();
      onComplete(code);
    }
  };

  const handleBack = () => {
    if (step === 2) {
      setStep(1);
    } else {
      onBack();
    }
  };

  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;
    let numericValue = inputValue.replace(/[^0-9]/g, '');
    setPhone(numericValue);
    // console.log(handlePhoneNumberMask(numericValue));
  };

  //Phone Number Mask Function
  // const handlePhoneNumberMask = (phoneNumber) => {
  //   const inputValue = phoneNumber;

  //   const numericValue = inputValue.replace(/\D/g, '');

  //   const formattedValue = numericValue.slice(0, 10);

  //   const formattedDisplay =
  //     formattedValue.length >= 6
  //      // ? `${formattedValue.slice(0, 3)}xxx${formattedValue.slice(6)}`
  //       : formattedValue;

  //   // setPhoneNumber(formattedValue);

  //   console.log(formattedDisplay);
  // };

  function exportToExcell() {
    console.log(selectedItems);
    // let temp1 = orderList?.map((x, index) => {
    //   let a = {
    //     id: index + 1,
    //     barcode: x.barcode,
    //     title: x.title,
    //     from: x.from.title,
    //     to: x.to.title,
    //     deliverValue: x.deliverValue ? 'Başarlı' : 'Başarısız',
    //     createdDateTime: moment(new Date(Number(x.createdDateTime))).format(
    //       'DD/MM/YYYY'
    //     ),
    //   };
    //   return a;
    // });
    // console.log(temp1);
    // api
    //   .post('excel/export', {
    //     type: 'excel',
    //     content: temp1,
    //     header: {
    //       ID: 'number',
    //       'Kargo Takip No': 'string',
    //       'Firma/Kişi': 'string',
    //       'Firma ID': 'string',
    //       //   'Nokta Adı Çıkış': 'string',
    //       //   'Nokta Adı Varış': 'string',
    //       'Kargo Durumu': 'string',
    //       'Oluşturulma Tarihi': 'string',
    //       'Kargo Firması': 'string',
    //     },
    //     sheetTitle: 'İşler',
    //   })
    //   .then((response) => {
    //     console.log(response.data);
    //     if (response.data.status) {
    //       window.open('http://' + response.data.result, '_blank');
    //     } else {
    //       toast.error(response.data.message);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     toast.error(error.message);
    //   });
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{step === 1 ? 'Adres Bilgileri' : 'Onay Kodu'}</DialogTitle>
      <DialogContent>
        {step === 1 ? (
          <div>
            <TextField
              fullWidth
              label="Ad"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{ mb: 2, mt: 2 }}
            />
            <TextField
              fullWidth
              label="Soyad"
              variant="outlined"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Telefon"
              placeholder="5XXXXXXXXX"
              variant="outlined"
              inputProps={{ maxLength: 10 }}
              value={phone}
              onChange={handlePhoneNumberChange}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Notlar"
              variant="outlined"
              multiline
              rows={4}
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />
          </div>
        ) : (
          <>
            <DialogContentText>
              {
                <span style={{ fontWeight: '700', color: 'black' }}>
                  {count}
                </span>
              }{' '}
              adet paketin teslimi için {phone} numaralı telefona gönderilen
              onay kodunu giriniz
            </DialogContentText>

            <TextField
              fullWidth
              label="Kodu girin"
              variant="outlined"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              sx={{ mt: 2 }}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="success"
          fullWidth={false}
          onClick={() => exportToExcell()}
        >
          Excele Aktar
        </Button>
        <Button onClick={handleBack} color="primary" disabled={step === 1}>
          Geri
        </Button>
        <Button
          disabled={
            name?.length < 2 || lastname?.length < 2 || phone?.length !== 10
              ? true
              : false
          }
          onClick={handleNext}
          color="primary"
        >
          {step === 1 ? 'İlerle' : 'Teslim Et'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeliveryModal;
