import React, { useState } from 'react';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TextEditor.css'; // Stil dosyası
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Typography } from '@mui/material';
import Iconify from '../../iconify';
import api from 'src/services/BaseService';
import FileUpload from '../FileUpload/FileUpload';

const TextEditor = ({ onEditorStateChange, editorState, id, getTickets, getTicket }) => {

  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");

  const toolbarOptions = {
    options: ['inline', 'link'],
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
    link: { defaultTargetOption: "_blank" }
  };

  const handleCloseFileUpload = () => {
    setFileUploadOpen(false)
  }

  const handleSend = async () => {

    let isEmpty = true

    if (editorState) {
      isEmpty = convertToRaw(editorState.getCurrentContent()).blocks.every(b => b.text.trim() === "");
    }

    if (!isEmpty || selectedFiles.length > 0) {
      let cleanedString = ""
      if (!isEmpty) {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlContent = draftToHtml(rawContentState);

        cleanedString = htmlContent.replaceAll("&nbsp;", "").replaceAll("<p></p>","<br/>");
      }

      if (selectedFiles.length === 0) {
        api
          .post('flow/ticket-add-conversation', {
            id: id,
            response: cleanedString.trim()
          })
          .then((response) => {
            if (response.data.status) {
              getTickets()
              getTicket()
              setMessage("Mesajınız başarıyla gönderildi.")
              setShowMessage(true)
            } else {
              setMessage("Mesajınız gönderilirken bir hata oluştu.")
              setShowMessage(true)
            }
          })
          .catch((error) => {
            console.log(error);
            setMessage("Mesajınız gönderilirken bir hata oluştu.")
            setShowMessage(true)
          });
      }
      else {
        let fileArr = []
        for (let i = 0; i < selectedFiles.length; i++) {
          fileArr.push(selectedFiles[i].base64.base64)
        }
        api
          .post('flow/ticket-add-conversation', {
            id: id,
            response: cleanedString.trim(),
            files: fileArr
          })
          .then((response) => {
            if (response.data.status) {
              getTickets()
              getTicket()
              setMessage("Mesajınız başarıyla gönderildi.")
              setShowMessage(true)
            } else {
              console.log(response.data);
              setMessage("Mesajınız gönderilirken bir hata oluştu.")
              setShowMessage(true)
            }
          })
          .catch((error) => {
            console.log(error);
            setMessage("Mesajınız gönderilirken bir hata oluştu.")
            setShowMessage(true)
          });
      }

      onEditorStateChange("")
      setSelectedFiles([])
    }
  };

  return (
    <>
      <div className="text-editor-container">
        <Editor
          toolbarCustomButtons={[
            <button
              className='rdw-option-wrapper'
              style={{ marginBottom: '5px' }}
              onClick={() => setFileUploadOpen(true)}
            >
              {selectedFiles.length === 0 ?
                <CloudUploadOutlinedIcon fontSize="medium" />
                :
                <CheckCircleOutlineIcon fontSize="medium" />
              }
            </button>
            ,
            <Button
              startIcon={<Iconify icon="iconamoon:send" />}
              variant="contained"
              sx={{
                alignSelf: 'flex-end', marginLeft: 'auto', display: 'flex', marginBottom: '8px', marginRight: '15px',
                backgroundColor: '#2C70D7',
                fontSize: 12,
                fontFamily: 'Inter',
                fontWeight: 500,
                letterSpacing: 0.4,
                lineHeight: 24,
                height: 40,
                width: 110,
                borderRadius: '5px',
                padding: '8px 16px 8px 16px',
                gap: '6px',
              }}
              onClick={handleSend}
            >
              GÖNDER
            </Button>
          ]}
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
          toolbar={toolbarOptions}

        />
      </div >
      <Modal open={fileUploadOpen}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '5px',
          p: 4,

        }}>
          <FileUpload setFiles={setSelectedFiles} files={selectedFiles} handleClose={handleCloseFileUpload} />
        </Box>
      </Modal>

      <Dialog
        open={showMessage}
        onClose={() => setShowMessage(false)} // Fonksiyon olarak geçirildi
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle id="alert-dialog-title">{'Mesaj Gönderme'}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowMessage(false)}>Tamam</Button>{' '}
          {/* Fonksiyon olarak geçirildi */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TextEditor;
