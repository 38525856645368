import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { tokenService } from 'src/services';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const generateCombinedPermissions = (type, frontAuthArray) => {
    if (frontAuthArray === false) {
      return `${type}:${false}`;
    } else {
      return frontAuthArray.map((frontAuth) => `${type}:${frontAuth}`);
    }
  };

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => {
          const user = tokenService.getUser();
          const type = user?.type || '';
          const frontAuthArray = user?.frontAuth || false;

          const combinedPermissions = generateCombinedPermissions(
            type,
            frontAuthArray
          );
          // console.log('İZİNLER: ', combinedPermissions);

          if (
            combinedPermissions === 'superadmin:false' ||
            combinedPermissions === 'superadmin:true'
          ) {
            return <NavItem key={item.title} item={item} />;
          } else {
            return (
              item.permissions.some((permission) =>
                combinedPermissions.includes(permission)
              ) && <NavItem key={item.title} item={item} />
            );
          }

          // return <NavItem key={item.title} item={item} />;
        })}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        color: '#ffffff',
        '&.active': {
          color: '#DC423C',
          // bgcolor: '#01010233',
          bgcolor: '#ffffff',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
