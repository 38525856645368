import { Stack, Typography, MenuItem, Select, FormControl, TextField, Button, Backdrop, CircularProgress, Modal, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import HorizontalDivider from 'src/components/shared/HorizontalDivider';
import Services from 'src/services/Services';
import { toast } from 'react-toastify';
import successImage from '../../assets/images/success-check.png';
import errorImage from '../../assets/images/error.png';


export default function CargoPartnersRefund() {
    const { partner } = useParams();
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [customerReturnCode, setCustomerReturnCode] = useState('');
    const [barcode, setBarcode] = useState('');
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalImage, setModalImage] = useState('');
    let partnerName = "";

    if (partner === "aras-kargo") {
        partnerName = "Aras Kargo";
    }

    const handleAcceptReturn = () => {
        setLoading(true);

        const parsedUser = JSON.parse(localStorage.getItem('user'));
        const token = parsedUser.token;

        Services.AcceptArasKargoEcommerceReturn(token, selectedCompany.id, customerReturnCode, barcode)
            .then((res) => {
                setLoading(false);
                if (res.data.status) {
                    setModalMessage('E-ticaret İadeniz başarıyla tamamlanmıştır.');
                    setModalImage(successImage);
                } else {
                    setModalMessage('E-ticaret İadeniz yapılırken bir hata oluştu.');
                    setModalImage(errorImage);
                }
                setIsModalOpen(true);
            })
            .catch((err) => {
                setLoading(false);
                setModalMessage('E-ticaret İadeniz yapılırken bir hata oluştu.');
                setModalImage(errorImage);
                setIsModalOpen(true);
            });
    };

    const getArasCompanies = () => {
        setLoading(true);

        const parsedUser = JSON.parse(localStorage.getItem('user'));
        const token = parsedUser.token;

        Services.GetArasKargoEcommerceCompanyList(token)
            .then((res) => {
                setLoading(false);
                if (res.data.status) {
                    setCompanyList(res.data.result);
                } else {
                    toast.error("Şirket bulunamadı.");
                    setCompanyList([]);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (partner === "aras-kargo") {
            getArasCompanies();
        }
    }, [partner]);

    const handleChange = (event) => {
        setSelectedCompany(event.target.value);
    };

    const allFieldsAreValid = () => {
        return selectedCompany !== '' && customerReturnCode.trim() !== '' && barcode.trim() !== '';
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Modal'ı kapat
        setSelectedCompany('')
        setCustomerReturnCode('')
        setBarcode('')
    };

    return (
        <>
            <Helmet>
                <title> Kargo Partner İşlemleri | Easy Point Panel </title>
            </Helmet>

            <Stack style={{ paddingInline: '25px' }}>
                <Stack
                    direction="column"
                    alignItems="flex-start"
                    mb={5}
                >
                    <Typography variant="h4" gutterBottom>
                        Kargo Partner İşlemleri
                    </Typography>
                    <p style={{ color: '#D1D1D1', fontSize: '16px', fontWeight: '400' }}>
                        {partnerName} - E-Ticaret İadesi Al
                    </p>
                    <HorizontalDivider />
                </Stack>

                {loading ? (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: 2, height: '100%' }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                ) : (
                    <>
                        <p style={{ color: 'black', fontWeight: '700', fontSize: '14px' }}>E-ticaret firmasını seçiniz:</p>
                        <FormControl fullWidth variant="outlined">
                            <Select
                                id="company-select"
                                value={selectedCompany}
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em>Şirket Seç</em>
                                </MenuItem>
                                {companyList.map((company) => (
                                    <MenuItem key={company.id} value={company}>
                                        {company.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <p style={{ color: 'black', fontWeight: '700', fontSize: '14px' }}>*Müşteri iade kodu:</p>
                        <TextField
                            placeholder='Müşteri iade kodu giriniz'
                            variant="outlined"
                            fullWidth
                            value={customerReturnCode}
                            onChange={(e) => setCustomerReturnCode(e.target.value)}
                        />

                        <p style={{ color: 'black', fontWeight: '700', fontSize: '14px' }}>Barkod giriniz:</p>
                        <TextField
                            placeholder='Barkod no giriniz'
                            variant="outlined"
                            fullWidth
                            value={barcode}
                            onChange={(e) => setBarcode(e.target.value)}
                        />
                        {/* <span style={{ color: '#667085', fontSize: '12px', fontWeight: '400', marginTop: '5px' }}>Elle yazabilir veya pre-printed barkodu okutabilirsiniz.</span> */}

                        <Button
                            onClick={handleAcceptReturn}
                            style={{ marginTop: '50px' }}
                            sx={{
                                width: '100%',
                                backgroundColor: '#DC423C',
                                color: 'white',
                                marginTop: '21px',
                                height: '54px',
                                display: 'flex',
                                '&:hover': {
                                    backgroundColor: '#B53832'
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: '#DDDFE3',
                                },
                            }}
                            disabled={!allFieldsAreValid()}
                        >
                            İade İşlemini Tamamla
                        </Button>
                    </>
                )}

                <Modal
                    open={isModalOpen}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <img src={modalImage} alt="Sonuç" style={{ width: '60px', height: '60px', marginBottom: '20px' }} />
                        <Typography id="modal-modal-description" sx={{ mt: 1, fontSize: '15px', fontWeight: '700', width: '200px', textAlign: 'center' }}>
                            {modalMessage}
                        </Typography>
                        <Button onClick={handleCloseModal}
                            sx={{
                                width: '100%',
                                backgroundColor: '#DC423C',
                                color: 'white',
                                marginTop: '21px',
                                height: '54px',
                                display: 'flex',
                                '&:hover': {
                                    backgroundColor: '#B53832'
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: '#DDDFE3',
                                },
                            }} fullWidth>
                            Yeni İade İşlemi Yap
                        </Button>
                    </Box>
                </Modal>

            </Stack>
        </>
    );
}
