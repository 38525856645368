import React, { useState, useEffect } from 'react';
import AddEditUserModal from 'src/modals/AddEditUserModal';
import { useParams } from 'react-router-dom';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export default function EditUserForPoint() {
    const { id } = useParams();
    const [userDialogOpen, setUserDialogOpen] = useState(true);

    return (
        <>
            <AddEditUserModal MenuProps={MenuProps} actionType={'edit'} isUserDialogOpen={userDialogOpen} setUserDialogOpen={setUserDialogOpen} userID={id}/>
        </>
    );
}
