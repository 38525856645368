import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const CustomFilter = ({ children, modalType,filterModal,handleCloseModal  }) => {
 

    const paperProps = modalType === 'drawer' ? {
        style: {
            margin: 0,
            maxHeight: '100%',
            maxWidth: '100%',
            width: '490px',
            height: '100%',
            position: 'fixed',
            right: 0,
            top: 0,
            borderRadius:0,
        }
    } : {};
    return (
        <>
           
            {filterModal && (
                <Dialog
                    open={filterModal}
                    onClose={handleCloseModal}
                    PaperProps={paperProps}
                    paddingY={2}
                    paddingX={1}
                >
                    <DialogTitle>Filtreler
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseModal}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>{children}</DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default CustomFilter;
