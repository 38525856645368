import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CancelIcon from '@mui/icons-material/Cancel';

function ImageUpload({ setFiles, files }) {
    const onDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];

        if (!file || !file.type.startsWith("image/")) return;

        const base64File = await readFileAsBase64(file);
        setFiles([base64File]);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false });

    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve({ name: file.name, base64: reader.result.split(',')[1] });
            reader.onerror = error => reject(error);
        });
    };

    const handleCloseFileUpload = () => {
        setFiles([]);
    };

    return (
        <Grid container spacing={2}>
            {files?.length > 0 ? (
                <>
                    <Grid item xs={12}>
                        <Typography>Yüklenen Resim:</Typography>
                        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', gap: '15px' }}>
                            {/* Show the image preview if available */}
                            <img
                                src={files[0].base64 ? `data:image/*;base64,${files[0].base64}` : files[0].base64 || files[0].url}
                                alt={files[0].name || "Image"}
                                style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }}
                            />
                            {files[0].name} <CancelIcon style={{ cursor: 'pointer' }} onClick={handleCloseFileUpload} fontSize="medium" />
                        </div>
                    </Grid>
                </>
            ) : (
                <Grid item xs={12}>
                    <Paper variant="outlined" {...getRootProps()} sx={{ p: 3, textAlign: 'center', cursor: 'pointer', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                        <input {...getInputProps()} />
                        <div style={{
                            width: '40px',
                            height: '40px',
                            border: '1px solid #EAECF0',
                            borderRadius: '8px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            boxSizing: 'border-box',
                            boxShadow: '0 0 0 0.5px #EAECF0',
                            marginBottom: '12px'
                        }}>
                            <CloudUploadOutlinedIcon color="#475467" fontSize="medium" />
                        </div>
                        <Typography variant="body1" sx={{ fontSize: '14px', fontFamily: 'Inter' }}>
                            <span style={{ color: '#DD4640', fontWeight: '600' }}>Yüklemek için tıklayın</span> <span style={{ color: '#475467' }}>ya da sürükleyip bırakın</span>
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: '12px', fontFamily: 'Inter', color: '#475467', marginTop: '4px' }}>
                            Resim yükleyiniz
                        </Typography>
                    </Paper>
                </Grid>
            )}
        </Grid>
    );
}

export default ImageUpload;

