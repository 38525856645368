import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Card, Modal, TextField, Typography, Tab, Tabs, CircularProgress, Switch, Tooltip, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CancelButton from 'src/components/shared/CancelButton';
import CompleteButton from 'src/components/shared/CompleteButton';
import ImageUpload from 'src/components/shared/ImageUpload';
import MultipleImageUpload from 'src/components/shared/MultipleImageUpload';
import Services from 'src/services/Services';
import moment from 'moment';
import { toast } from 'react-toastify';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './AddEditBolParaCompany.css'
import Iconify from 'src/components/iconify';

export default function AddEditBolParaCompany({ open, onClose, company, companyName, setCompanyName, logo, setLogo, getCompanies }) {
    const parsedUser = JSON.parse(localStorage.getItem('user'));

    const [campaignTitle, setCampaignTitle] = useState("");
    const [campaignImages, setCampaignImages] = useState([]);
    const [campaignStart, setCampaignStart] = useState();
    const [campaignEnd, setCampaignEnd] = useState();
    const [campaignType, setCampaignType] = useState("");
    const [activeTab, setActiveTab] = useState(0);
    const [campaignList, setCampaignList] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [isActive, setIsActive] = useState(false);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [selectedCampaignIDForRemove, setSelectedCampaignIDForRemove] = useState("");


    const [selectedCampaign, setSelectedCampaign] = useState();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (company) {
            getCampaigns()
            setCompanyName(company.title)
            const logoFromUrl = {
                name: `${company.title} Logo`,
                url: company.logo // No need to convert to base64 since this is already a URL
            };
            setLogo([logoFromUrl]);
            setIsActive(company?.status === "1" ? true : false)
        }
    }, [company]);

    const allFieldsAreValid = () => {
        if (activeTab === 0 && company) {
            if (companyName !== "" && logo.length > 0) {
                return true
            }
            else {
                return false
            }
        }

        else if (activeTab === 1 && company) {
            let isEmpty = true

            if (editorState) {
                isEmpty = convertToRaw(editorState.getCurrentContent()).blocks.every(b => b.text.trim() === "");
            }

            if (campaignTitle && !isEmpty && campaignStart && campaignEnd && campaignType) {

                return true
            }
            else {
                return false
            }
        }

        else if (activeTab === 2 && company && selectedCampaign) {
            let isEmpty = true

            if (editorState) {
                isEmpty = convertToRaw(editorState.getCurrentContent()).blocks.every(b => b.text.trim() === "");
            }

            if (campaignTitle && !isEmpty && campaignStart && campaignEnd && campaignType) {

                return true
            }
            else {
                return false
            }
        }

        else if (!company) {
            if (companyName !== "" && logo.length > 0) {
                return true
            }
            else {
                return false
            }
        }
    }

    const createCompany = () => {
        Services.CreateBolParaCompany(parsedUser.token, companyName, logo[0]?.base64)
            .then((res) => {
                if (res.data.status) {
                    toast.success("Marka başarıyla eklendi.")
                    getCompanies(parsedUser.token)
                    setIsLoading(false)
                }
                else {
                    toast.error("Marka eklenirken bir sorunla karşılaşıldı.")
                    setIsLoading(false)
                }
                handleOnClose()
            })
            .catch((err) => console.log(err));
    }

    const updateCompany = () => {

        const logoData = logo[0]?.base64 || null;

        Services.UpdateBolParaCompany(parsedUser.token, company.companyID, companyName, logoData, isActive)
            .then((res) => {
                console.log("Update Company:", res.data);
                if (res.data.status) {
                    toast.success("Marka başarıyla düzenlendi.");
                    getCompanies(parsedUser.token);
                } else {
                    toast.error("Marka düzenlenirken bir sorunla karşılaşıldı.");
                }
                setIsLoading(false);
                handleOnClose()
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                handleOnClose()
            });
    };

    const formattedDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Aylar 0-11 arası olduğu için +1 ekleniyor
        const day = String(d.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const createCampaign = () => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlContent = draftToHtml(rawContentState);

        let cleanedString = htmlContent.replaceAll("&nbsp;", "");

        Services.CreateBolParaCampaign(parsedUser.token, campaignTitle, company.companyID, cleanedString, extractBase64Images(campaignImages), formattedDate(new Date(campaignStart)), formattedDate(new Date(campaignEnd)), campaignType)
            .then((res) => {
                if (res.data.status) {
                    toast.success("Kampanya başarıyla eklendi.")
                    getCompanies(parsedUser.token)
                    setIsLoading(false)
                }
                else {
                    toast.error("Kampanya eklenirken bir sorunla karşılaşıldı.")
                    setIsLoading(false)
                }
                handleOnClose()
            })
            .catch((err) => console.log(err));
    }

    const updateCampaign = () => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlContent = draftToHtml(rawContentState);

        let cleanedString = htmlContent.replaceAll("&nbsp;", "");

        const campaignImageData = (campaignImages[0]?.file ? extractBase64Images(campaignImages) : null);

        Services.UpdateBolParaCampaign(parsedUser.token, selectedCampaign.campaignID, campaignTitle, company.companyID, cleanedString, campaignImageData, formattedDate(new Date(campaignStart)), formattedDate(new Date(campaignEnd)), campaignType)
            .then((res) => {
                console.log(res.data, "--");
                if (res.data.status) {
                    toast.success("Kampanya başarıyla güncellendi.")
                    getCampaigns()
                }
                else {
                    toast.error("Kampanya güncellenirken bir sorunla karşılaşıldı.")
                    setIsLoading(false)
                }
                setSelectedCampaign()
            })
            .catch((err) => console.log(err));
    }

    const handleDeleteCampaign = (campaignID) => {
        setDeleteConfirmModal(true)
        setSelectedCampaignIDForRemove(campaignID)
    }

    const removeCampaign = () => {
        setIsLoading(true)
        setDeleteConfirmModal(false)
        Services.RemoveBolParaCampaign(parsedUser.token, selectedCampaignIDForRemove)
            .then((res) => {
                if (res.data.status) {
                    toast.success("Kampanya başarıyla kaldırıldı.")
                    getCampaigns()
                    setSelectedCampaignIDForRemove("")
                }
                else {
                    toast.error("Kampanya kaldırılırken bir sorunla karşılaşıldı.")
                    setIsLoading(false)
                    setSelectedCampaignIDForRemove("")
                }
            })
            .catch((err) => console.log(err));
    }

    const extractBase64Images = (data) => {
        return data.map(item => item.base64.base64);
    };

    const handleCampaignSelect = (campaign) => {
        const contentBlock = htmlToDraft(campaign.description);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
        setSelectedCampaign(campaign)
        setCampaignTitle(campaign.title)
        setCampaignImages(campaign.images)
        setCampaignStart(moment(campaign.start * 1000))
        setCampaignEnd(moment(campaign.end * 1000))
        setCampaignType(campaign.type)
    }

    const handleComplete = () => {
        // setIsLoading(true)

        if (company && activeTab === 0) {
            updateCompany()
        }
        else if (company && activeTab === 1) {
            createCampaign()
        }

        else if (company && activeTab === 2 && selectedCampaign) {
            updateCampaign()
        }

        else if (!company) {
            createCompany()
        }
    }

    const getCampaigns = () => {
        setIsLoading(true)
        Services.GetBolParaCompanyCampaigns(parsedUser.token, company.companyID)
            .then((res) => {
                if (res.data.status) {
                    setCampaignList(res.data.result)
                    setIsLoading(false)
                }
                else {
                    setCampaignList([])
                    setIsLoading(false)
                }
            })
            .catch((err) => console.log(err));
    }

    const handleOnClose = () => {
        onClose()
        setCampaignTitle("")
        setCampaignImages([])
        setCampaignStart()
        setCampaignEnd()
        setCampaignType("")
        setActiveTab(0)
        setCampaignList([])
        setEditorState(EditorState.createEmpty());
        setSelectedCampaign()
    }

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        setCampaignTitle("")
        setCampaignImages([])
        setCampaignStart()
        setCampaignEnd()
        setCampaignType("")
        setEditorState(EditorState.createEmpty());
        setSelectedCampaign()
    };

    const handleSwitchChange = () => {
        setIsActive(!isActive)
    };

    return (
        <Modal sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }} open={open}>
            <Card sx={{ padding: '25px', overflowY: 'auto', maxHeight: '95%' }}>

                {company ?
                    <div style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', display: 'flex' }}>

                        <Typography variant='h6'>{company.title}</Typography>
                        {!isLoading && activeTab === 0 &&
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                <Typography>
                                    Aktiflik Durumu:
                                </Typography>
                                <Switch
                                    checked={isActive}
                                    onChange={handleSwitchChange}
                                    name="isActive"
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </div>
                        }
                    </div>
                    :
                    <Typography variant='h6'>Marka Ekle</Typography>
                }

                {!company &&
                    <>
                        {isLoading ?
                            <div style={{ width: '250px', height: '250px', marginTop: '15px', marginBottom: '15px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                <CircularProgress size={22} color="error" />
                            </div>
                            :
                            <>
                                <TextField
                                    fullWidth
                                    label="Marka Adı *"
                                    name="title"
                                    value={companyName}
                                    onChange={e => setCompanyName(e.target.value)}
                                    margin="normal"
                                />
                                <Typography sx={{ marginBottom: '10px', fontWeight: '600' }}>Logo *</Typography>
                                <ImageUpload setFiles={setLogo} files={logo} />
                            </>
                        }
                    </>
                }

                {company &&
                    <>
                        {isLoading ?
                            <div style={{ width: '250px', height: '250px', marginTop: '15px', marginBottom: '15px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                <CircularProgress size={22} color="error" />
                            </div>
                            :
                            <>

                                <Tabs
                                    value={activeTab} // Set initial tab index
                                    onChange={handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab label="Marka Düzenle" />
                                    <Tab label="Kampanya Ekle" />
                                    <Tab label="Kampanya Listesi" />
                                </Tabs>

                                {activeTab === 0 && company && (
                                    <>
                                        <TextField
                                            sx={{ marginTop: '35px' }}
                                            fullWidth
                                            label="Marka Adı *"
                                            name="title"
                                            value={companyName}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                            margin="normal"
                                        />
                                        <Typography sx={{ marginBottom: '10px', fontWeight: '600' }}>Logo *</Typography>
                                        <ImageUpload setFiles={setLogo} files={logo} />
                                    </>
                                )}

                                {activeTab === 1 && company && (
                                    <>
                                        <TextField
                                            fullWidth
                                            label="Kampanya Adı *"
                                            name="campaignTitle"
                                            value={campaignTitle}
                                            onChange={(e) => setCampaignTitle(e.target.value)}
                                            margin="normal"
                                        />

                                        <div style={{ width: '800px', border: '1px solid rgba(145, 158, 171, 0.24)', padding: '15px', borderRadius: '8px' }}>
                                            <Editor
                                                editorClassName='editor'
                                                editorState={editorState}
                                                onEditorStateChange={setEditorState}
                                                toolbar={{
                                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                                                }}
                                            />
                                        </div>


                                        <Typography sx={{ marginTop: '10px', marginBottom: '15px', fontWeight: '600' }}>Kampanya Resimleri</Typography>
                                        <div style={{ width: '100%' }}>
                                            <MultipleImageUpload setFiles={setCampaignImages} files={campaignImages} />
                                        </div>

                                        <div style={{ width: '100%', marginTop: '15px', justifyContent: 'space-between', display: 'flex' }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    sx={{ marginRight: '8px', width: '50%' }}
                                                    format="DD/MM/YYYY"
                                                    label="Başlangıç Tarihi"
                                                    value={campaignStart}
                                                    onChange={(newValue) => {
                                                        setCampaignStart(newValue);
                                                        setCampaignEnd(null);
                                                    }}
                                                />
                                            </LocalizationProvider>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    sx={{ width: '50%' }}
                                                    format="DD/MM/YYYY"
                                                    label="Bitiş Tarihi"
                                                    value={campaignEnd}
                                                    minDate={campaignStart}
                                                    disabled={!campaignStart}
                                                    onChange={(newValue) => setCampaignEnd(newValue)}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        <TextField
                                            fullWidth
                                            label="Kampanya Türü *"
                                            name="campaignType"
                                            value={campaignType}
                                            onChange={(e) => setCampaignType(e.target.value)}
                                            margin="normal"
                                        />
                                    </>
                                )}

                                {activeTab === 2 && company && (
                                    <>  {
                                        selectedCampaign ?
                                            <>
                                                <TextField
                                                    fullWidth
                                                    label="Kampanya Adı *"
                                                    name="campaignTitle"
                                                    value={campaignTitle}
                                                    onChange={(e) => setCampaignTitle(e.target.value)}
                                                    margin="normal"
                                                />

                                                <div style={{ width: '800px', border: '1px solid rgba(145, 158, 171, 0.24)', padding: '15px', borderRadius: '8px' }}>
                                                    <Editor
                                                        editorClassName='editor'
                                                        editorState={editorState}
                                                        onEditorStateChange={setEditorState}
                                                        toolbar={{
                                                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                                                        }}
                                                    />
                                                </div>


                                                <Typography sx={{ marginTop: '10px', marginBottom: '15px', fontWeight: '600' }}>Kampanya Resimleri</Typography>
                                                <div style={{ width: '100%' }}>
                                                    <MultipleImageUpload setFiles={setCampaignImages} files={campaignImages} />
                                                </div>

                                                <div style={{ width: '100%', marginTop: '15px', justifyContent: 'space-between', display: 'flex' }}>
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <DatePicker
                                                            sx={{ marginRight: '8px', width: '50%' }}
                                                            format="DD/MM/YYYY"
                                                            label="Başlangıç Tarihi"
                                                            value={campaignStart}
                                                            onChange={(newValue) => {
                                                                setCampaignStart(newValue);
                                                                setCampaignEnd(null);
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <DatePicker
                                                            sx={{ width: '50%' }}
                                                            format="DD/MM/YYYY"
                                                            label="Bitiş Tarihi"
                                                            value={campaignEnd}
                                                            minDate={campaignStart}
                                                            disabled={!campaignStart}
                                                            onChange={(newValue) => setCampaignEnd(newValue)}
                                                        />
                                                    </LocalizationProvider>
                                                </div>

                                                <TextField
                                                    fullWidth
                                                    label="Kampanya Türü *"
                                                    name="campaignType"
                                                    value={campaignType}
                                                    onChange={(e) => setCampaignType(e.target.value)}
                                                    margin="normal"
                                                />
                                            </>
                                            :
                                            <>
                                                {
                                                    campaignList.length > 0 ?

                                                        <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: '15px', paddingBottom: '5px', borderBottom: '1px solid lightgray' }}>
                                                                <b style={{ width: '33%', textAlign: 'left' }}>Başlık</b>
                                                                <b style={{ width: '33%', textAlign: 'left' }}>Başlangıç</b>
                                                                <b style={{ width: '33%', textAlign: 'left' }}>Bitiş</b>
                                                                <div style={{ width: '74px' }} />
                                                            </div>
                                                            {campaignList.map((campaign, index) => (
                                                                <div key={index} style={{
                                                                    flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', borderBottom: '1px solid lightgray'
                                                                }}>
                                                                    <div

                                                                        onClick={() => handleCampaignSelect(campaign)}
                                                                        onMouseEnter={() => setHoveredItem(index)} // Sadece bu öğe hover olduğunda
                                                                        onMouseLeave={() => setHoveredItem(null)} // Hover kaldırıldığında
                                                                        style={{
                                                                            flexDirection: 'row',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'space-between',
                                                                            width: '100%',
                                                                            cursor: 'pointer',
                                                                            backgroundColor: hoveredItem === index ? '#F9FAFB' : 'transparent', // Sadece bu öğe hover edildiğinde renk değişimi
                                                                        }}
                                                                    >
                                                                        <p style={{ width: '33%', textAlign: 'left' }}>{campaign.title}</p>
                                                                        <p style={{ width: '33%', textAlign: 'left' }}>
                                                                            {moment.unix(campaign.start).format('DD/MM/YYYY')}
                                                                        </p>
                                                                        <p style={{ width: '33%', textAlign: 'left' }}>
                                                                            {moment.unix(campaign.end).format('DD/MM/YYYY')}
                                                                        </p>
                                                                    </div>
                                                                    <Tooltip title="Düzenle">
                                                                        <Iconify onClick={() => handleCampaignSelect(campaign)} style={{ color: '#98A2B3', width: 22, height: 22, cursor: 'pointer', marginRight: '15px' }} icon="tabler:edit" />
                                                                    </Tooltip>
                                                                    <Tooltip title="Kaldır">
                                                                        <Iconify onClick={() => handleDeleteCampaign(campaign.campaignID)} style={{ color: 'red', width: 22, height: 22, cursor: 'pointer', marginRight: '15px' }} icon="hugeicons:delete-04" />
                                                                    </Tooltip>
                                                                </div>

                                                            ))}
                                                        </div>
                                                        :
                                                        <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '15px' }}>
                                                            <p>Kampanya bulunamadı.</p>
                                                        </div>
                                                }

                                            </>

                                    }

                                    </>
                                )}
                            </>
                        }
                    </>
                }

                <Dialog
                    open={deleteConfirmModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                    <DialogTitle id="alert-dialog-title">{"Emin misiniz?"}</DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Kampanyayı kaldırmak istediğinize emin misiniz ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setDeleteConfirmModal(false)}>Hayır</Button>
                        <Button onClick={removeCampaign}>Evet</Button>
                    </DialogActions>
                </Dialog>


                {activeTab !== 2 && !isLoading &&

                    <div style={{ width: '100%', alignItems: 'center', justifyContent: 'flex-end', gap: '15px', display: 'flex', marginTop: '15px' }}>
                        <CancelButton onClick={handleOnClose} label={"Vazgeç"} />
                        <CompleteButton disabled={!allFieldsAreValid()} onClick={handleComplete} label={"Kaydet"} />
                    </div>
                }

                {activeTab === 2 && !isLoading &&

                    <div style={{ width: '100%', alignItems: 'center', justifyContent: 'flex-end', gap: '15px', display: 'flex', marginTop: '15px' }}>
                        {!selectedCampaign && <CancelButton onClick={handleOnClose} label={"Kapat"} />}
                        {selectedCampaign && <><CancelButton onClick={() => setSelectedCampaign()} label={"Geri"} /><CompleteButton disabled={!allFieldsAreValid()} onClick={handleComplete} label={"Kaydet"} /></>}
                    </div>
                }

            </Card>
        </Modal >
    )
}
