// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `



@media screen and (max-width:768px) {
    .mobile_horizantal{
        overflow-x: scroll;
    }
    
    .m-w-950{
        width: 950px !important;
    }
    
    .MuiDataGrid-columnHeaders{
        background-color: #eb33331a;
    }
    
    .mr-2{
        margin-right: 10px;
    }
    .ml-2{
        margin-left: 10px;
    }
    .gap-3{
        gap:5px;
       
       
    }
    .flex-direction-column{
        flex-direction: column;
    }
     .d-flex{
        display:flex;
     }   
     .align-end{
        align-items: flex-end;
     }
     .mobile_point_tag{
        display: flex ;
        flex-wrap: wrap; 
        width: 146px;
        gap: 10px;

     }
    .mobile-p-0{
        padding: 0 !important;
    }
    }`, "",{"version":3,"sources":["webpack://./src/pages/pointsPage.css"],"names":[],"mappings":";;;;AAIA;IACI;QACI,kBAAkB;IACtB;;IAEA;QACI,uBAAuB;IAC3B;;IAEA;QACI,2BAA2B;IAC/B;;IAEA;QACI,kBAAkB;IACtB;IACA;QACI,iBAAiB;IACrB;IACA;QACI,OAAO;;;IAGX;IACA;QACI,sBAAsB;IAC1B;KACC;QACG,YAAY;KACf;KACA;QACG,qBAAqB;KACxB;KACA;QACG,cAAc;QACd,eAAe;QACf,YAAY;QACZ,SAAS;;KAEZ;IACD;QACI,qBAAqB;IACzB;IACA","sourcesContent":["\n\n\n\n@media screen and (max-width:768px) {\n    .mobile_horizantal{\n        overflow-x: scroll;\n    }\n    \n    .m-w-950{\n        width: 950px !important;\n    }\n    \n    .MuiDataGrid-columnHeaders{\n        background-color: #eb33331a;\n    }\n    \n    .mr-2{\n        margin-right: 10px;\n    }\n    .ml-2{\n        margin-left: 10px;\n    }\n    .gap-3{\n        gap:5px;\n       \n       \n    }\n    .flex-direction-column{\n        flex-direction: column;\n    }\n     .d-flex{\n        display:flex;\n     }   \n     .align-end{\n        align-items: flex-end;\n     }\n     .mobile_point_tag{\n        display: flex ;\n        flex-wrap: wrap; \n        width: 146px;\n        gap: 10px;\n\n     }\n    .mobile-p-0{\n        padding: 0 !important;\n    }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
