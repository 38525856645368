import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import CustomRouter from './custom-routes/CustomRoutes';
import history from './custom-routes/history';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { configureStore } from './store/Store';
import 'handsontable/dist/handsontable.full.min.css';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import './App.css'

// ----------------------------------------------------------------------

export default function App() {
  return (
    <Provider store={configureStore()}>
      <HelmetProvider>
        <CustomRouter history={history}>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            {/* <ErrorBoundary> */}
              <Router />
            {/* </ErrorBoundary> */}
            <ToastContainer theme="colored" autoClose={2000} />
          </ThemeProvider>
        </CustomRouter>
      </HelmetProvider>
    </Provider>
  );
}
