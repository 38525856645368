import React from 'react';
import { Button } from '@mui/material';
import Iconify from '../iconify';

const AddEntityButton = ({ onClick, label, sx, icon }) => {

    return (
        <>
            <Button
                sx={{
                    border: '1px solid #D1D5DB',
                    boxShadow:'none',
                    backgroundColor: 'transparent',
                    fontSize: 14,
                    fontFamily: 'Inter',
                    color:'#6B7280',
                    fontWeight: 500,
                    letterSpacing: 0.4,
                    height: 40,
                    borderRadius: '5px',
                    padding: '8px 16px 8px 16px',
                    gap: '6px',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    ...sx
                }}
                variant="contained"
                startIcon={<Iconify icon={icon} />}
                onClick={onClick}
            >
                {label.toLocaleUpperCase('tr-TR')}
            </Button>
        </>
    );
};

export default AddEntityButton;