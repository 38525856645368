import { Helmet } from 'react-helmet-async';
import { concat, filter } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useSelector } from 'react-redux';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  Select,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
import api from 'src/services/BaseService';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'barcode', label: 'Kargo Takip No', alignRight: false },
  { id: 'title', label: 'Firma/Kişi', alignRight: false },
  { id: 'from', label: 'Çıkış Noktası', alignRight: false },
  { id: 'to', label: 'Varış Noktası', alignRight: false },
  { id: 'createdDateTime', label: 'Oluşturulma Tarihi', alignRight: false },
  { id: '', label: 'İşlemler' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function RobotActionsPage() {
  const customer = useSelector((state) => state.customerReducer);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [postID, setPostID] = useState('');
  const [orderList, setOrderList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedTitle, setSelectedTitle] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSuccess = () => {
    setSuccess(true);
    setLoading(false);
    setOpenModal(false);
    setSelectedCustomer();
    setSelectedTitle();
  };

  const handleFailed = () => {
    setFailed(true);
    setOpenModal(false);
    setLoading(false);
    setSelectedCustomer();
    setSelectedTitle();
  };

  const handleCustomerChange = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };

  // Service

  const createRobotOrder = () => {
    console.log('selected Customer: ', selectedCustomer.target.value);
    const [addressID, phone] = selectedCustomer.target.value.split('|');
    const senderName = selectedTitle.split(' - ')[0];
    console.log('id: ', postID);

    api
      .post('flow/send-to-robot', {
        postID: postID,
        userID: addressID,
        phone: phone,
        senderName: senderName,
        robotType: 'saharobotik',
      })
      .then((res) => {
        console.log('create robot order: ', res.data);
        handleSuccess();
        setPostID('');
      })
      .catch((err) => {
        console.log(err);
      });
    // Services.CreateRobotOrder(
    //   token,
    //   addressID,
    //   phone,
    //   senderName,
    //   location.state.id
    // )
    //   .then((res) => {
    //     console.log(res.data);
    //     if (res.data.status) {
    //       closeModal();
    //       // navigate("/homepage");
    //     } else {
    //       alert("Bir hata oluştu");
    //       closeModal();
    //     }
    //   })
    //   .catch((err) => {
    //     alert("Bir hata oluştu");
    //     closeModal();
    //     console.log(err);
    //   });
  };

  function getRobotCustomers() {
    api
      .post('flow/get-robot-customers', {
        robotType: 'saharobotik',
      })
      .then((res) => {
        if (res.data.status) {
          const list = [];
          for (let i = 0; i < res.data.result.length; i++) {
            const label =
              res.data.result[i].customer_details.name +
              ' ' +
              res.data.result[i].customer_details.lastname +
              ' - ' +
              res.data.result[i].address_site +
              ' Blok - Kat: ' +
              res.data.result[i].address_floor +
              ' - D: ' +
              res.data.result[i].address_name;
            const value = `${res.data.result[i].address_uid}|${res.data.result[i].customer_details.phone}`;
            list.push({ label, value });
          }

          console.log(list);
          setCustomerList(list);
        } else {
          setCustomerList([]);
        }
      })
      .catch(() => setCustomerList([]));
  }

  function getFilteredOrderList() {
    api
      .post('posts/get', {
        branchID: customer?.customer?.branchID || null,
        mallID: customer?.customer?.id || null,
        status: [14],
        startDate: startDate
          ? moment(startDate).format('DD-MM-YYYY')
          : startDate,
        endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : endDate,
        limit: 5000,
      })
      .then((response) => {
        if (response.data.status) {
          setOrderList(response.data.result);
          console.log(response.data.result);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => toast.error(error.message));
  }

  useEffect(() => {
    getFilteredOrderList();
    getRobotCustomers();
  }, [customer]);

  function clearFilters() {
    setStartDate(null);
    setEndDate(null);
    getFilteredOrderList();
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = orderList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderList.length) : 0;

  const filteredOrders = applySortFilter(
    orderList,
    getComparator(order, orderBy)
  );

  const isNotFound = !filteredOrders.length;

  return (
    <>
      <Helmet>
        <title> Robot İşlemleri | Mall Logistics Panel </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Robot İşlemleri
          </Typography>
        </Stack>

        <Card>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            paddingY={2}
            paddingX={1}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{ marginRight: '8px' }}
                format="DD/MM/YYYY"
                label="Başlangıç Tarihi"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{ marginRight: '8px' }}
                format="DD/MM/YYYY"
                label="Bitiş Tarihi"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              />
            </LocalizationProvider>
            <Button
              sx={{ marginRight: '8px' }}
              variant="contained"
              color="info"
              onClick={() => getFilteredOrderList()}
            >
              Filtrele
            </Button>

            <Button
              sx={{ marginRight: '8px' }}
              variant="contained"
              color="error"
              onClick={() => clearFilters()}
            >
              Temizle
            </Button>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={orderList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredOrders
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        id,
                        barcode,
                        title,
                        from,
                        to,
                        deliverValue,
                        createdDateTime,
                      } = row;
                      const selectedUser = selected.indexOf(barcode) !== -1;

                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                        >
                          <TableCell align="left">{index + 1}</TableCell>

                          <TableCell align="left">{barcode}</TableCell>

                          <TableCell align="left">{title}</TableCell>

                          <TableCell align="left">{from.title}</TableCell>

                          <TableCell align="left">{to.title}</TableCell>

                          <TableCell align="left">
                            {moment.unix(createdDateTime).format('DD/MM/YYYY')}
                          </TableCell>

                          <TableCell align="left">
                            <Button
                              sx={{}}
                              color="info"
                              onClick={() => {
                                setPostID(id);
                                handleOpenModal();
                              }}
                            >
                              Robota Teslim Et
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Bulunamadı
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={orderList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Robota Teslim Et</DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {/* Content of the modal */}
          <Typography sx={{ marginBlock: 3, fontWeight: '600' }}>
            Müşteri Seçiniz
          </Typography>

          <Select
            value={selectedCustomer}
            onChange={handleCustomerChange}
            sx={{ width: '450px' }}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Müşteri Seçiniz
            </MenuItem>
            {customerList?.map((customer) => (
              <MenuItem
                onClick={() => setSelectedTitle(customer.label)}
                key={customer.value}
                value={customer.value}
              >
                {customer.label}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>

        <Stack
          direction="column"
          spacing={2}
          sx={{ alignItems: 'center', display: 'flex', marginBottom: 3 }}
        >
          <Button
            variant="contained"
            style={{
              width: '450px',
              backgroundColor: '#4CAF50',
              color: 'white',
            }}
            onClick={createRobotOrder}
          >
            Robota Sipariş Oluştur
          </Button>

          <Button onClick={handleCloseModal} color="error">
            Kapat
          </Button>
        </Stack>
      </Dialog>

      <Dialog open={success} onClose={() => setSuccess(false)}>
        <DialogTitle color={'green'}>Başarılı</DialogTitle>
        <DialogContent>
          <Typography>İşleminiz gerçekleştirildi.</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSuccess(false);
              // window.location.reload(false);
            }}
            color="primary"
          >
            Tamam
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
