import React from 'react'

const DotsColor = ({color,name,border}) => {
    return (
        <span
            style={{
                backgroundColor: 'white',
                padding: border ? '4px 12px 4px 10px' : '0',
                borderRadius: '7px',
                textAlign: 'center',
                border: border ? '1px solid #D0D5DD' : 'none',
                color: color,
                fontSize: 14,
                fontWeight: 500,
                fontFamily: 'Inter',
                marginRight: '6px',
                boxShadow: border ? '2px 1px 4px 0px rgba(0, 0, 0, 0.1)' : 'none'
            }}
        >
            ● {name ? name : null}
        </span>
    )
}

export default DotsColor
