import React from 'react';
import Iconify from '../iconify';
import { Link } from 'react-router-dom';

const EditButton = ({ onClick, to, icon }) => {

    return (
        <>
            {icon ? <>
                {
                    to ?
                        <Link style={{ textDecoration: 'none', fontWeight: 800 }} to={to}>
                            <Iconify style={{ color: '#98A2B3', width: 22, height: 22, cursor: 'pointer' }} icon={icon} />
                        </Link >
                        :
                        <Iconify style={{ color: '#98A2B3', width: 22, height: 22, cursor: 'pointer' }} icon={icon} onClick={onClick} />
                }
            </>
                :
                <>
                    {
                        to ?
                            <Link style={{ textDecoration: 'none', fontWeight: 800 }} to={to}>
                                <Iconify style={{ color: '#98A2B3', width: 22, height: 22, cursor: 'pointer' }} icon="akar-icons:edit" />
                            </Link>
                            :
                            <Iconify style={{ color: '#98A2B3', width: 22, height: 22, cursor: 'pointer' }} icon="akar-icons:edit" onClick={onClick} />
                    }
                </>
            }

        </>
    );
};

export default EditButton;