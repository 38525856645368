import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useRef } from 'react';
import {
  Card,
  Stack,
  Button,
  Popover,
  MenuItem,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputAdornment,
  FormControl,
  Divider
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Iconify from '../components/iconify';
import * as React from 'react';
import Services from 'src/services/Services';
import AddEntityButton from 'src/components/shared/AddEntityButton';
import AddEditUserModal from 'src/modals/AddEditUserModal';
import './userPage.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import FilterButton from 'src/components/shared/FilterButton';
import ClearButton from 'src/components/shared/ClearButton';
import UserStatusFilters from 'src/components/shared/UserStatusFilters';
import DynamicTable from 'src/components/shared/DynamicTable';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import EditButton from 'src/components/shared/EditButton';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function UserPage() {
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');
  const [open, setOpen] = useState(null);
  const [mobileFilterModal, setMobileFilterModal] = useState(false);


  const [rows, setRows] = useState([]);
  const [allDatas, setAllDatas] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState('user');

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [userID, setUserID] = useState('');
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [locations, setLocations] = useState([]);
  const [firstResponsibleOffice, setFirstResponsibleOffice] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [isUserDialogOpen, setUserDialogOpen] = useState(false);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [actionType, setActionType] = useState('new');
  const [lastData, setLastData] = useState(false);
  const [status, setStatus] = useState(true);

  const usernameRef = useRef(null);
  const nameRef = useRef(null);
  const dotRef = useRef(null);


  const [filterModal, setFilterModal] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const handleModal = () => {
    setFilterModal(true);
  };

  const handleCloseModalFilter = () => {
    setFilterModal(false);
  };
  const TABLE_HEAD = [

    { id: 'name', label: 'Ad Soyad', alignRight: false },
    { id: 'lastname', label: 'Soyad', alignRight: false },
    { id: 'phone', label: 'Telefon', alignRight: false },
    { id: 'username', label: 'Kullanıcı Adı', alignRight: false },
    {
      id: 'allowedPoints', label: 'Nokta', alignRight: false, format: (value, row) =>

        row.allowedPoints?.map((point, index) => (
          <Typography style={{ fontSize: 13 }} key={index}>
            {point.title}
            {index < row.allowedPoints?.length - 1 ? ', ' : ''}
          </Typography>
        ))
    },
    {
      id: 'actions', label: '', alignRight: false, format: (value, row) =>
        <EditButton
          onClick={() => {
            setActionType('edit');
            setUserID(row.id);
            setUserDialogOpen(true);
            setLastData(true);
          }}
        />


    },

  ]

  // function descendingComparator(a, b, orderBy) {
  //   if (b[orderBy] < a[orderBy]) {
  //     return -1;
  //   }
  //   if (b[orderBy] > a[orderBy]) {
  //     return 1;
  //   }
  //   return 0;
  // }

  // function getComparator(order, orderBy) {
  //   return order === 'desc'
  //     ? (a, b) => descendingComparator(a, b, orderBy)
  //     : (a, b) => -descendingComparator(a, b, orderBy);
  // }

  // function applySortFilter(array, comparator, query) {
  //   const stabilizedThis = array?.map((el, index) => [el, index]);
  //   stabilizedThis.sort((a, b) => {
  //     const order = comparator(a[0], b[0]);
  //     if (order !== 0) return order;
  //     return a[1] - b[1];
  //   });

  //   return stabilizedThis?.map((el) => el[0]);
  // }

  const handleCloseMenu = () => {
    setOpen(null);
    setPassword('');
  };

  const handleCloseModal = () => {
    setName('');
    setLastname('');
    setUsername('');
    setEmail('');
    setPhone('');
    setPassword('');
    setSelectedRoles([]);
    setSelectedLocations([]);
    setFirstResponsibleOffice(null);
    setSelectedUserType('user');
    setUserDialogOpen(false);
    setMobileFilterModal(false);
  };

  const handleCopyPassword = () => {
    navigator.clipboard
      .writeText(password)
      .then(() => {
        // console.log('Şifre kopyalandı:', password);
      })
      .catch((err) => {
        console.error('Şifre kopyalanırken bir hata oluştu:', err);
      });
  };

  // const handleResetPassword = () => {
  //   setResetLoading(true);
  //   api
  //     .post('flow/user-forced-reset-password', {
  //       // email: '',
  //       userID: userID,
  //     })
  //     .then((response) => {
  //       if (response.data) {
  //         setPassword(response.data.result);
  //         setPasswordDialog(true);
  //       } else {
  //         toast.error(response.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       toast.error(error.message);
  //     })
  //     .finally(() => setResetLoading(false));
  // };

  let nameUsernames = [];
  const handleFilterByUsername = (text) => {
    const _username = text.toLowerCase();
    const filteredDatas = allDatas.filter((item) =>
      item.username.toLowerCase().includes(_username)
    );
    nameUsernames = filteredDatas;
    //setRows(filteredDatas);
  };

  let filterNames = [];
  const handleFilterByName = (text) => {
    const _name = text.toLowerCase();
    const filteredDatas = allDatas.filter((item) =>
      `${item.name.toLowerCase()} ${item.lastname.toLowerCase()}`.includes(
        _name
      )
    );
    filterNames = filteredDatas;
    //setRows(filteredDatas);
  };

  let filterDots = [];
  const handleFilterByDot = (text) => {
    const _dot = text.toLowerCase();
    const filteredDatas = allDatas.filter((item) =>
      item.allowedPoints.some((point) =>
        point.title.toLowerCase().includes(_dot)
      )
    );
    filterDots = filteredDatas;
    //setRows(filteredDatas);
  };
  const clearFilters = () => {
    const dotRefInputElement = dotRef.current.querySelector('input');
    const userNameRefInputElement = usernameRef.current.querySelector('input');
    const nameRefInputElement = nameRef.current.querySelector('input');

    if (usernameRef.current) {
      userNameRefInputElement.value = '';
    }
    if (nameRef.current) {
      nameRefInputElement.value = '';
    }
    if (dotRef.current) {
      dotRefInputElement.value = '';
    }

    setRows(allDatas);
  };

  const getFilteredUserList = () => {
    let combinedResults = [];

    if (Array.isArray(nameUsernames)) {
      combinedResults = [...combinedResults, ...nameUsernames];
    }

    if (Array.isArray(filterNames)) {
      combinedResults = [...combinedResults, ...filterNames];
    }

    if (Array.isArray(filterDots)) {
      combinedResults = [...combinedResults, ...filterDots];
    }

    const uniqueResults = Array.from(new Set(combinedResults));

    setRows(uniqueResults);
  };

  useEffect(() => {
    const parsedUser = JSON.parse(localStorage.getItem('user'));
    // console.log(parsedUser);
    setToken(parsedUser.token);

    setLoading(true);
    // Kullanıcılar
    Services.GetUsers(parsedUser.token, status)
      .then((res) => {
        if (res.data.status) {
          const rowsWithNo = res?.data?.result?.map((row, index) => ({
            ...row,
            no: index + 1,
          }));
          setUsers(res.data.result);
          setRows(rowsWithNo);
          setAllDatas(rowsWithNo);
        } else {
          setAllDatas([]);
          setRows([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setRows([]);
        setAllDatas([]);
      })
      .finally(() => setLoading(false));
  }, [password, status]);

  return (
    <>
      <Helmet>
        <title> Kullanıcılar | Easy Point Panel </title>
      </Helmet>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}

      >
        <Stack

        >
          <Typography className="ml-2" variant="h4" gutterBottom>
            Kullanıcılar
          </Typography>
        </Stack>

        <Stack >
          <AddEntityButton
            onClick={() => {
              setActionType('new');
              setUserDialogOpen(true);
            }}
            label={'Kullanıcı Ekle'}
            icon={'ic:outline-add'}
          />

        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent={isMobile ? 'space-between' : 'end'}
        mb={5}
        paddingX={2}
      >
        {isMobile ? (
          <FilterViewButton variant="contained" onClick={handleModal} />
        ) : null}
      </Stack>
      <Card className="mobile_horizantal">
        <CustomFilter
          modalType={'drawer'}
          filterModal={filterModal}
          handleCloseModal={handleCloseModalFilter}
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="flex-start"
            paddingY={2}
            paddingX={1}
            spacing={2}
            width="100%"
          >
            <FormControl sx={{ flex: 1, minWidth: '100%' }}>
              <TextField
                ref={nameRef}
                label="Ad Soyad"
                variant="outlined"
                autoComplete="off"
                onChange={(e) => handleFilterByName(e.target.value)}
              />
            </FormControl>

            <FormControl sx={{ flex: 1, minWidth: '100%' }}>
              <TextField
                ref={usernameRef}
                label="Kullanıcı Adı"
                variant="outlined"
                autoComplete="off"
                onChange={(e) => handleFilterByUsername(e.target.value)}
              />
            </FormControl>

            <FormControl sx={{ flex: 1, minWidth: '100%' }}>
              <TextField
                ref={dotRef}
                label="Nokta Adı"
                variant="outlined"
                autoComplete="off"
                onChange={(e) => handleFilterByDot(e.target.value)}
              />
            </FormControl>

            <div
              style={{
                height: '20px',
                width: '1px',
                backgroundColor: '#EAECF0',
                margin: '0 15px',
                marginLeft: '15px',
              }}
            ></div>
            <Stack>
              <UserStatusFilters
                activeOnClick={() => setStatus(true)}
                passiveOnClick={() => setStatus(false)}
              />
            </Stack>
            <Divider sx={{ width: '100%' }} />
            <Stack direction="row">
              <ClearButton onClick={() => clearFilters()} />
              <FilterButton onClick={() => getFilteredUserList()} />
            </Stack>

          </Stack>

        </CustomFilter>





        <DynamicTable
          data={rows}
          headers={TABLE_HEAD}
          initialOrder={'name'}
          initalOrderDirection={'desc'}
          isOrdered={true}
          isMobile={isMobile}
          handleModal={handleModal}
          handleCloseModal={handleCloseModalFilter}
          rowOnClick={(row) => {
            setActionType('edit');
            setUserID(row.id);
            setUserDialogOpen(true);
            setLastData(true);
          }}

        />
      </Card>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setActionType('edit');
            setUserDialogOpen(true);
          }}
        >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Düzenle
        </MenuItem>
      </Popover>

      <AddEditUserModal
        userModalWait={{ lastData, setLastData }}
        userID={userID}
        isUserDialogOpen={isUserDialogOpen}
        // handleCloseModal={handleCloseModal}
        actionType={actionType}
        setUserDialogOpen={setUserDialogOpen}
        MenuProps={MenuProps}
      />

      <Dialog
        open={passwordDialog}
        onClose={() => {
          setPasswordDialog(false);
          setPassword('');
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Yeni Şifre'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Yenilen şifreyi aşağıdan kopyalayabilirsiniz.
          </DialogContentText>
          <br />
          <DialogContentText
            disabled
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 1,
            }}
            id="alert-dialog-description"
          >
            <TextField
              disabled
              fullWidth
              type="text"
              label="Şifre"
              name="password"
              value={password}
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={handleCopyPassword}>
                      <ContentCopyIcon color="action" />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPasswordDialog(false)}>Kapat</Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog open={mobileFilterModal} onClose={handleCloseModal}>
        <DialogTitle>Filtreler</DialogTitle>
        <DialogContent>
          <div
            style={{
              height: '1px',
              width: '100%',
              backgroundColor: 'rgba(107, 114, 128, 0.7)',
            }}
          ></div>
          <Stack
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <TextField
              ref={nameRef}
              label="Ad Soyad"
              variant="outlined"
              autoComplete="off"
              onChange={(e) => handleFilterByName(e.target.value)}
              sx={{ my: 2, marginLeft: 1 }}
            />
            <TextField
              ref={usernameRef}
              label="Kullanıcı Adı"
              variant="outlined"
              autoComplete="off"
              onChange={(e) => handleFilterByUsername(e.target.value)}
              sx={{ my: 2, marginLeft: 1 }}
            />
            <TextField
              ref={dotRef}
              label="Nokta Adı"
              variant="outlined"
              autoComplete="off"
              onChange={(e) => handleFilterByDot(e.target.value)}
              sx={{ my: 2, marginLeft: 1 }}
            />

            <div
              style={{
                height: '20px',
                width: '1px',
                backgroundColor: '#EAECF0',
                margin: '0 15px',
                marginLeft: '-2px',
              }}
            ></div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ClearButton onClick={() => clearFilters()} />
              <FilterButton onClick={() => getFilteredUserList()} />
            </div>
          </Stack>
        </DialogContent>
      </Dialog> */}
    </>
  );
}
