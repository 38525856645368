import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import { tokenService } from '../../../services';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  // borderRadius: Number(theme.shape.borderRadius) * 1.5,
  // backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
          background: '#da3831',
        },
      }}
    >
      <Box
        sx={{
          // px: 2.5,
          // py: 3,
          display: 'inline-flex',
          justifyContent: 'center',
          width:'100%'
        }}
      >
        <Logo />
      </Box>

      <div
  
        style={{
          marginBottom: '5%',
          marginInline: '4%',
          backgroundColor: '#CD3636',
          border: 'solid 3px #C41C1C',
          borderRadius: 5,
          display: 'flex',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Link underline="none">
          <StyledAccount>
            {/* <div style={imagesStyle}>
              {tokenService.getUser()?.name[0].toUpperCase() +
                tokenService.getUser()?.lastname[0].toUpperCase()}
            </div> */}

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#EAECF0',
                  fontWeight: '600',
                }}
              >
                {tokenService.getUser()?.name}{' '}
                {tokenService.getUser()?.lastname}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </div>

      <div
        style={{
          backgroundColor: '#C61F1F',
          marginInline: '4%',
          height: 2,
          marginTop: 10,
          marginBottom: 20,
        }}
      />

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
              backgroundColor:'#da3831'
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH ,backgroundColor:'#da3831'},
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
