import { composeWithDevTools } from 'redux-devtools-extension';
import RootReducers from './RootReducers';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';

export function configureStore(InitialState) {
  const Store = createStore(
    RootReducers,
    InitialState,
    composeWithDevTools(applyMiddleware(thunk))
  );
  return Store;
}
