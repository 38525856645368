import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
// @mui
import {
  Grid,
  Container,
  Typography,
  Stack,
  TextField,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel,
  IconButton,
  useMediaQuery
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import api from 'src/services/BaseService';
import { toast } from 'react-toastify';
import { StatusList } from 'src/types/statusTypes';
import ClearButton from 'src/components/shared/ClearButton';
import Services from 'src/services/Services';

export default function DashboardAppPage() {
  const [user, setUser] = useState('');
  const [token, setToken] = useState('');
  const [barcode, setBarcode] = useState('');
  const [postList, setPostList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState('');
  const [forceUpdate, setForceUpdate] = useState(0);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedEasyPoints, setSelectedEasyPoints] = useState(null);

  const [pending, setPending] = useState(0);
  const [branch, setBranch] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [returns, setReturns] = useState(0);
  const [loadingPending, setLoadingPending] = useState(true);
  const [loadingBranch, setLoadingBranch] = useState(true);
  const [loadingReturns, setLoadingReturns] = useState(true);
  const [loadingCompleted, setLoadingCompleted] = useState(true);
  const isMobile = useMediaQuery('(max-width:768px)');
  const [easyPointOptions, setEasyPointOptions] = useState([]);

  const fetchStatistics = async (
    _token,
    statusArray,
    setCount,
    setLoading,
    poiID = null,
    clear = ''
  ) => {
    setLoading(true);
    let _startDate = startDate;
    let _endDate = endDate;
    const today = new Date();
    let dateTimeType = 'created';
    if (setCount === setCompleted) {
      _endDate = _startDate = moment(today).format('YYYY-MM-DD');
      dateTimeType = 'completed';
    }
    try {
      const res = await Services.GetStatistics(_token, {
        status: statusArray,
        poiID:
          poiID ||
          (userType === 'restricted' ? user?.firstResponsibleOffice?.id : null),
        startDate:
          clear === 'clear'
            ? null
            : _startDate
              ? moment(_startDate).format('YYYY-MM-DD')
              : null,
        endDate:
          clear === 'clear'
            ? null
            : _endDate
              ? moment(_endDate).format('YYYY-MM-DD')
              : null,
        dateTimeType,
      });
      if (res.data.status) {
        setCount(res.data.result);
      } else {
        setCount(0);
      }
    } catch (err) {
      setCount(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const parsedUser = JSON.parse(localStorage.getItem('user'));
    setUser(parsedUser);
    setToken(parsedUser.token);

    let poiID = null;

    if (
      ['highadmin', 'superadmin'].includes(parsedUser.type) ||
      (parsedUser.type === 'user' && parsedUser.frontAuth.includes('manager'))
    ) {
      setUserType('admins');
      Services.GetPoints(parsedUser.token).then((response) => {
        if (response.data.status) {
          setEasyPointOptions(response.data.result);
          setSelectedEasyPoints(null);
          // Admin kullanıcılar için poiID null olarak gönderilir
          fetchStatistics(
            parsedUser.token,
            [3],
            setPending,
            setLoadingPending,
            null
          );
          fetchStatistics(
            parsedUser.token,
            [5],
            setBranch,
            setLoadingBranch,
            null
          );
          fetchStatistics(
            parsedUser.token,
            [16, 21],
            setReturns,
            setLoadingReturns,
            null
          );
          fetchStatistics(
            parsedUser.token,
            [1],
            setCompleted,
            setLoadingCompleted,
            null
          );
        }
      });
    } else if (
      parsedUser.allowedPoints &&
      Object.keys(parsedUser.allowedPoints).length > 1
    ) {
      setUserType('limiteds');
      const options = [];
      Object.values(parsedUser.allowedPoints).forEach((office) => {
        office.centers.forEach((center) => {
          options.push({ id: center.id, title: center.title });
        });
      });
      setEasyPointOptions(options);
      const poiIDs = options.map((option) => option.id);
      setSelectedEasyPoints(poiIDs);
      // Kısıtlı kullanıcılar için poiID liste olarak gönderilir
      fetchStatistics(
        parsedUser.token,
        [3],
        setPending,
        setLoadingPending,
        poiIDs
      );
      fetchStatistics(
        parsedUser.token,
        [5],
        setBranch,
        setLoadingBranch,
        poiIDs
      );
      fetchStatistics(
        parsedUser.token,
        [16, 21],
        setReturns,
        setLoadingReturns,
        poiIDs
      );
      fetchStatistics(
        parsedUser.token,
        [1],
        setCompleted,
        setLoadingCompleted,
        poiIDs
      );
    } else {
      setUserType('restricted');
      const office = parsedUser.firstResponsibleOffice;
      setEasyPointOptions([{ id: office.id, title: office.title }]);
      setSelectedEasyPoints([office.id]);
      poiID = [office.id];
      // Yetkisiz kullanıcılar için tek bir poiID gönderilir
      fetchStatistics(
        parsedUser.token,
        [3],
        setPending,
        setLoadingPending,
        poiID
      );
      fetchStatistics(
        parsedUser.token,
        [5],
        setBranch,
        setLoadingBranch,
        poiID
      );
      fetchStatistics(
        parsedUser.token,
        [16, 21],
        setReturns,
        setLoadingReturns,
        poiID
      );
      fetchStatistics(
        parsedUser.token,
        [1],
        setCompleted,
        setLoadingCompleted,
        poiID
      );
    }
  }, []); // Boş bağımlılık dizisi ile sadece bir kez çalışır

  const handleClear = async () => {
    setStartDate(null);
    setEndDate(null);

    let poiID = null;

    if (userType === 'admins') {
      setSelectedEasyPoints(null);
      poiID = null;
    } else if (userType === 'limiteds') {
      const officeIds = user?.allowedPoints
        ? Object.values(user.allowedPoints).flatMap((office) =>
          office.centers.map((center) => center.id)
        )
        : [];

      setSelectedEasyPoints(officeIds);
      poiID =
        officeIds.length > 0 ? officeIds : [user?.firstResponsibleOffice?.id];
    } else if (userType === 'restricted') {
      const officeId = user?.firstResponsibleOffice?.id;
      setSelectedEasyPoints([officeId]);
      poiID = [officeId];
    }

    setForceUpdate((prev) => prev + 1);

    // İlk temizlemeden sonra hemen verileri çek
    fetchStatistics(token, [3], setPending, setLoadingPending, poiID, 'clear');
    fetchStatistics(token, [5], setBranch, setLoadingBranch, poiID, 'clear');
    fetchStatistics(
      token,
      [16, 21],
      setReturns,
      setLoadingReturns,
      poiID,
      'clear'
    );
    fetchStatistics(
      token,
      [1],
      setCompleted,
      setLoadingCompleted,
      poiID,
      'clear'
    );
  };

  const handleFilter = () => {
    fetchStatistics(
      token,
      [3],
      setPending,
      setLoadingPending,
      selectedEasyPoints
    );
    fetchStatistics(
      token,
      [5],
      setBranch,
      setLoadingBranch,
      selectedEasyPoints
    );
    fetchStatistics(
      token,
      [16, 21],
      setReturns,
      setLoadingReturns,
      selectedEasyPoints
    );
    fetchStatistics(
      token,
      [1],
      setCompleted,
      setLoadingCompleted,
      selectedEasyPoints
    );
  };

  const getPost = () => {
    if (
      barcode?.length < 5 ||
      postList.some((item) => item?.barcode === barcode)
    ) {
      toast.warning(
        'En az 5 karakter giriniz veya mevcut bir barkod girildi.',
        {
          position: 'top-center',
          autoClose: 5000,
        }
      );
      setBarcode('');
      return;
    }
    setLoading(true);
    api
      .post('flow/get-posts', { barcode })
      .then((res) => {
        console.log("res", res);
        if (res.data.status) {
          setPostList((prevList) => [...prevList, res.data.result[0]]);
          window.open(
            `/dashboard/post-details/${res.data.result[0]?.barcode}`,
            '_blank'
          );
        } else {
          toast.error('Gönderi bulunamadı.', {
            position: 'top-center',
            autoClose: 5000,
          });
        }
      })
      .catch(() => {
        toast.error('Gönderi bulunamadı.', {
          position: 'top-center',
          autoClose: 5000,
        });
      })
      .finally(() => {
        setLoading(false);
        setBarcode('');
      });
  };

  const removePost = (barcodeToRemove) => {
    setPostList((prevList) =>
      prevList.filter((item) => item.barcode !== barcodeToRemove)
    );
  };

  const BoxItem = ({ label, count, loading }) => (
    <Grid
      container
      alignItems="center"
      style={{
        padding: '16px',
        backgroundColor: 'rgba(38, 39, 41, 0.8)',
        borderRadius: '8px',
        marginBottom: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography
        style={{ textAlign: 'center', width: '100%', color: 'white' }}
      >
        {label} <br />
        {loading ? (
          <CircularProgress
            sx={{ marginTop: '5px', color: 'white' }}
            size={24}
          />
        ) : (
          <strong style={{ fontSize: 24 }}>{count} adet</strong>
        )}
      </Typography>
    </Grid>
  );

  return (
    <>
      <Helmet>
        <title> Ana Sayfa | Easy Point Panel </title>
      </Helmet>

      <Container maxWidth="xl">
        {isMobile}
        <Stack direction={isMobile ? 'column' : 'row'} spacing={2} mb={5} alignItems={'center'}>
          <FormControl sx={isMobile ? { width: '100%' } : null} >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format="DD/MM/YYYY"
                label="Başlangıç Tarihi"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={isMobile ? { width: '100%' } : null} >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format="DD/MM/YYYY"
                label="Bitiş Tarihi"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={isMobile ? { width: '100%' } : {minWidth:200}} >
            <InputLabel id="easy-point-label">Easy Point Noktaları</InputLabel>
            <Select
              labelId="easy-point-label"
              multiple={easyPointOptions.length > 1}
              value={selectedEasyPoints || []}
              onChange={(event) => setSelectedEasyPoints(event.target.value)}
              input={<OutlinedInput label="Easy Point Noktaları" />}
              renderValue={(selected) => {
                if (easyPointOptions.length === 1) {
                  return easyPointOptions[0].title;
                }

                const selectedTitles = selected
                  .map(
                    (value) =>
                      easyPointOptions.find((option) => option.id === value)
                        ?.title
                  )
                  .join(', ');

                const maxLength = 30;

                return selectedTitles.length > maxLength
                  ? `${selectedTitles.substring(0, maxLength)}...`
                  : selectedTitles;
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5,
                  },
                },
              }}
              disabled={easyPointOptions.length === 1}
            >
              {easyPointOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Stack spacing={1} direction="row">

            <ClearButton onClick={handleClear} />
            <Button variant="contained" color="primary" onClick={handleFilter}>
              Filtrele
            </Button>
          </Stack>
        </Stack>

        <Grid container spacing={2} mb={5}>
          <Grid item xs={12} sm={6} md={3}>
            <BoxItem
              label="Bekleyen Gönderiler"
              count={pending}
              loading={loadingPending}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <BoxItem
              label="Şubedeki Gönderiler"
              count={branch}
              loading={loadingBranch}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <BoxItem
              label="İade Edilenler"
              count={returns}
              loading={loadingReturns}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <BoxItem
              label="Bugün Teslim Edilenler"
              count={completed}
              loading={loadingCompleted}
            />
          </Grid>
        </Grid>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          mb={5}
        >
          <TextField
            id="barcode"
            name="barcode"
            type="text"
            label="Barkod giriniz"
            autoComplete="off"
            fullWidth
            value={barcode}
            style={{ backgroundColor: 'white' }}
            onChange={(e) => setBarcode(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') getPost();
            }}
          />
          <Button
            style={{ marginLeft: 10 }}
            variant="contained"
            color="primary"
            size="large"
            onClick={getPost}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Ara'}
          </Button>
        </Stack>

        {postList?.map((item, index) => (
          <Grid
            container
            spacing={2}
            alignItems="center"
            key={index}
            style={{
              padding: '16px',
              backgroundColor: 'white',
              borderRadius: '8px',
              marginBottom: '16px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography>
              {`${index + 1} - ${item?.id}: `}
              <br />
              <strong
                onClick={() => {
                  window.open(
                    `/dashboard/post-details/${item?.barcode}`,
                    '_blank'
                  );
                }}
                style={{ cursor: 'pointer' }}
              >
                {item?.apiUserType} - {item?.willTakenBy} - {item?.barcode} - (
                {
                  StatusList.find((status) => status?.id === +item?.status)
                    ?.label
                }
                )
              </strong>
            </Typography>
            <IconButton
              onClick={() => removePost(item.barcode)}
              style={{ color: 'red' }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        ))}
      </Container>
    </>
  );
}
