import { Stack, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import HorizontalDivider from 'src/components/shared/HorizontalDivider';
import CargoPartnerButton from './components/CargoPartnerButton';

export default function CargoPartnerOperations() {

    const [selectedAction, setSelectedAction] = useState('receive');

    return (
        <>
            <Helmet>
                <title> Kargo Partner İşlemleri | Easy Point Panel </title>
            </Helmet>

            <Stack style={{ paddingInline: '25px' }}>
                <Stack
                    direction="column"
                    alignItems="flex-start"
                    mb={5}
                >
                    <Typography variant="h4" gutterBottom>
                        Kargo Partner İşlemleri
                    </Typography>
                    <p style={{ color: '#D1D1D1', fontSize: '16px', fontWeight:'400' }}>
                        Kargo Partnerlerimiz
                    </p>
                    <HorizontalDivider />
                </Stack>

                <ToggleButtonGroup
                    value={selectedAction}
                    exclusive
                    aria-label="action buttons"
                    sx={{
                        border: '1px solid #EAECF0',
                        borderRadius: '10px',
                        backgroundColor: '#F9FAFB',
                        padding: '5px',
                    }}
                >
                    <ToggleButton
                        value="receive"
                        aria-label="Zimmete Al"
                        onClick={() => setSelectedAction("receive")}
                        sx={{
                            width: '50%',
                            border: 'none',
                            padding: '10px 20px',
                            '&.Mui-selected': {
                                backgroundColor: '#FFFFFF', // Seçili buton rengi
                                color: '#344054', // Seçili metin rengi
                                boxShadow: '0px 4px 8px 0px rgba(16, 24, 40, 0.2)'
                            },
                            '&.Mui-selected:hover': {
                                backgroundColor: '#FFFFFF', // Seçili ve hover durumunda gölge
                            },
                            '&:hover': {
                                backgroundColor: 'transparent', // Hover durumu için arka plan rengi
                            },

                        }}
                    >
                        Zimmete Al
                    </ToggleButton>
                    <ToggleButton
                        value="refund"
                        aria-label="İade Et"
                        onClick={() => setSelectedAction("refund")}
                        sx={{
                            width: '50%',
                            border: 'none',
                            padding: '10px 20px',
                            '&.Mui-selected': {
                                backgroundColor: '#FFFFFF', // Seçili buton rengi
                                color: '#344054', // Seçili metin rengi
                                boxShadow: '0px 4px 8px 0px rgba(16, 24, 40, 0.2)'
                            },
                            '&.Mui-selected:hover': {
                                backgroundColor: '#FFFFFF', // Seçili ve hover durumunda gölge
                            },
                            '&:hover': {
                                backgroundColor: 'transparent', // Hover durumu için arka plan rengi
                            },
                        }}
                    >
                        İade Et
                    </ToggleButton>
                </ToggleButtonGroup>

                <CargoPartnerButton partnerName="aras-kargo" title={'Aras Kargo'} type={selectedAction}/>
            </Stack>
        </>
    )
}
