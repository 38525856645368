import { Backdrop, Box, Button, CircularProgress, Modal, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'
import HorizontalDivider from 'src/components/shared/HorizontalDivider';
import Services from 'src/services/Services';
import DynamicTable from 'src/components/shared/DynamicTable';
import moment from 'moment';
import successImage from '../../assets/images/success-check.png';
import errorImage from '../../assets/images/error.png';

export default function CargoPartnersGivingBackPage() {


  const { partner } = useParams();

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalImage, setModalImage] = useState('');

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState();

  let partnerName = ""

  if (partner === "aras-kargo") {
    partnerName = "Aras Kargo"
  }


  let TABLE_HEAD = [
    {
      id: 'postDetail', label: 'Gönderi Detayları', format: (value, row) => {
        return (
          <div style={{ flexDirection: 'column', display: 'flex' }}>
            <span style={{ fontSize: '16px', fontWeight: '600' }}>{row?.takenName[0] + "*** " + row?.takenName.split(" ")[1][0] + "***"}</span>
            <span style={{ fontSize: '14px', fontWeight: '400', color: '#787878' }}>{row?.barcode}</span>
          </div>
        )
      }
    },
    {
      id: 'operationInfo', label: 'İşlem Bilgisi', format: (value, row) => {
        return (
          <div style={{ flexDirection: 'column', display: 'flex' }}>
            <span style={{ fontSize: '16px', fontWeight: '600' }}>{partnerName}</span>
            <span style={{ fontSize: '14px', fontWeight: '400', color: '#787878' }}> {row?.inBranchDateTime
              ? moment.unix(parseInt(row?.inBranchDateTime)).format("DD/MM/YYYY")
              : ""}</span>
          </div>
        )
      }
    },
    {
      id: 'operations', label: 'İşlem', format: (value, row) => {
        return (
          <span onClick={() => handleOpenConfirmModal(row)} style={{ backgroundColor: '#FEF3F2', border: '1px solid #FECDCA', color: '#B42318', borderRadius: '16px', alignItems: 'center', justifyContent: 'center', display: 'flex', width: '88px', cursor: 'pointer' }}>
            İade Et
          </span>
        )
      }
    }
  ];

  const handleOpenConfirmModal = (post) => {
    setSelectedPost(post);
    setIsConfirmModalOpen(true)
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleReturn = () => {
    handleCloseConfirmModal()
    setLoading(true);

    const parsedUser = JSON.parse(localStorage.getItem('user'));
    const token = parsedUser.token;
    if (post.apiUserType === "aras-kargo") {
      Services.ReturnArasKargoPost(token, selectedPost.id)
        .then((res) => {
          if (res.data.status) {
            fetchDatas()
            setModalMessage(`"${selectedPost.barcode}" numaralı işlem başarıyla iade edilmiştir.`);
            setModalImage(successImage);
          } else {
            setLoading(false);
            setModalMessage(`"${selectedPost.barcode}" numaralı işlem iade edilirken bir sorunla karşılaşıldı.`);
            setModalImage(errorImage);
          }
          setIsModalOpen(true);
        })
        .catch((err) => {
          setLoading(false);
          setModalMessage(`"${selectedPost.barcode}" numaralı işlem iade edilirken bir sorunla karşılaşıldı.`);
          setModalImage(errorImage);
          setIsModalOpen(true);
        });
    }
  };

  const fetchDatas = () => {
    setLoading(true);

    const parsedUser = JSON.parse(localStorage.getItem('user'));
    const token = parsedUser.token;

    Services.GetArasKargoPosts(token)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          const filteredOrders = res.data.result.filter(
            (order) => order.apiUserType === "aras-kargo"
          );
          setOrders(filteredOrders);
        } else {
          setOrders([]);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <>
      <Helmet>
        <title> Kargo Partner İşlemleri | Easy Point Panel </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="column"
          alignItems="flex-start"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Kargo Partner İşlemleri
          </Typography>
          <p style={{ color: '#D1D1D1', fontSize: '16px', fontWeight: '400' }}>
            {partnerName} - İade Et
          </p>
          <HorizontalDivider />
        </Stack>

        {loading ?
          <Backdrop
            sx={{ color: '#fff', zIndex: 2, height: '100%' }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop> :
          <>
            {orders.length > 0 ?
              <DynamicTable
                data={orders}
                headers={TABLE_HEAD}
                isOrdered={false}
                initialOrder={"createdDateTime"}
                initalOrderDirection={"desc"}
              /> :
              <div style={{alignItems:'center',justifyContent:'center',display:'flex'}}>
                <Typography>Aras Kargo gönderisi bulunamadı.</Typography>
              </div>
            }

          </>
        }

        <Modal
          open={isModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <img src={modalImage} alt="Sonuç" style={{ width: '60px', height: '60px', marginBottom: '20px' }} />
            <Typography
              id="modal-modal-description"
              sx={{ mt: 1, fontSize: '15px', textAlign: 'left' }}
            >
              <strong>{modalMessage.split(' ')[0]}</strong> {modalMessage.split(' ').slice(1).join(' ')}
            </Typography>
            <Button onClick={handleCloseModal}
              sx={{
                width: '100%',
                backgroundColor: '#DC423C',
                color: 'white',
                marginTop: '21px',
                height: '54px',
                display: 'flex',
                '&:hover': {
                  backgroundColor: '#B53832'
                },
                '&.Mui-disabled': {
                  backgroundColor: '#DDDFE3',
                },
              }} fullWidth>
              Ana Menüye Git
            </Button>
          </Box>
        </Modal>

        <Modal
          open={isConfirmModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Typography id="modal-modal-description" sx={{ mt: 1, fontSize: '15px', textAlign: 'left' }}>
              <b>"{selectedPost?.id}"</b> numaralı Aras kargo gönderisini iptal etmek istiyor musunuz?
            </Typography>
            <Button onClick={handleReturn}
              sx={{
                width: '100%',
                backgroundColor: '#DC423C',
                color: 'white',
                marginTop: '21px',
                height: '54px',
                display: 'flex',
                '&:hover': {
                  backgroundColor: '#B53832'
                },
                '&.Mui-disabled': {
                  backgroundColor: '#DDDFE3',
                },
              }} fullWidth>
              İptal İşlemini Onayla
            </Button>
            <Button onClick={handleCloseConfirmModal}
              sx={{
                width: '100%',
                backgroundColor: '#fff',
                border: '1px solid #DC423C',
                color: '#DC423C',
                marginTop: '21px',
                height: '54px',
                display: 'flex',

                '&.Mui-disabled': {
                  backgroundColor: '#DDDFE3',
                },
              }} fullWidth>
              Vazgeç
            </Button>
          </Box>
        </Modal>

      </Stack>
    </>
  )
}
