export const StatusList = [
  { label: 'Şubede', id: 5 },
  { label: 'Tamamlandı', id: 1 },
  { label: 'İptal', id: 2 },
  { label: 'Bekleniyor', id: 3 },
  { label: 'Porter Zimmetinde', id: 4 },
  { label: 'Dolapta', id: 6 },
  { label: 'Transfer Ediliyor', id: 7 },
  { label: 'Porter Atandı', id: 8 },
  { label: 'İade Edilecek', id: 9 },
  { label: 'Concierge Kuryeye Teslim Edildi', id: 10 },
  { label: 'Depoda Teslim Alınmayı Bekliyor', id: 11 },
  { label: 'Mağazada Teslim Alınmayı Bekliyor', id: 12 },
  { label: 'Ofiste Teslim Alınmayı Bekliyor', id: 13 },
  { label: 'Mal Kabulde Teslim Alınmayı Bekliyor', id: 14 },
  { label: 'Çoklu Gönderim Başladı', id: 15 },
  { label: 'Trendyola İade Edildi', id: 16 },
  { label: 'Getir Kuryesine Teslim Edildi', id: 17 },
  { label: 'Mağazadan Teslim Alındı Onayı Bekleniyor', id: 18 },
  { label: 'İptal Edildi, Mağazaya Geri Götürülüyor', id: 19 },
  { label: 'İptal Edildi, Mağazaya Geri Götürülecek', id: 20 },
  { label: 'Hepsiburadaya İade Edildi', id: 21 },
  { label: 'Hepsiburadaya Teslim Edildi', id: 22 },
  { label: 'Emirden İptal', id: 23 },
  { label: 'Zimmetten İptal', id: 24 },
  { label: 'Mal Kabulden İptal', id: 25 },
  { label: 'Robottan Onay Bekleniyor', id: 26 },
  { label: 'Robota Teslim Edilecek', id: 27 },
  { label: 'Robot Üzerinde', id: 28 },
  { label: 'Robot Üzerinden İptal', id: 29 },
];
