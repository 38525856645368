import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Backdrop, Box, Button, Card, CircularProgress, FormControlLabel, Grid, IconButton, Modal, Radio, RadioGroup, Stack, Tooltip, Typography } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async';
import CompleteButton from 'src/components/shared/CompleteButton';
import moment from 'moment';
import 'moment/locale/tr'

import Services from 'src/services/Services';
import { toast } from 'react-toastify';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default function CreateReport() {
    const parsedUser = JSON.parse(localStorage.getItem('user'));

    const [option, setOption] = useState("created");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [isBackdropOpen, setIsBackdropOpen] = useState(false);
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChangeOption = (event) => {
        setOption(event.target.value)
    }

    function formatDate(date) {
        date = new Date(date)
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleCreateReport = () => {
        setIsBackdropOpen(true)
        Services.GetReport(parsedUser.token, option, formatDate(startDate), formatDate(endDate))
            .then((response) => {
                if (response.data.status) {
                    setIsBackdropOpen(false)
                    window.open(response.data.result, '_blank');
                }
                else {
                    setIsBackdropOpen(false)
                    toast.error("Rapor oluşturuluracak veri mevcut değil.");
                }
            })
            .catch((error) => {
                setIsBackdropOpen(false)
                toast.error("Rapor oluşturulurken bir sorunla karşılaşıldı!");
                console.log(error);
            });
    }

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: 1, flexDirection: 'column', display: 'flex', gap: '50px' }} open={isBackdropOpen}>
                <Typography>Dosya boyutuna göre indirme işlemi biraz zaman alabilir. Lütfen sayfayadan ayrılmayınız veya sayfayı yenilemeyiniz.</Typography>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Helmet>
                <title> Rapor Oluştur | Easy Net Panel </title>
            </Helmet>

            <Stack style={{ paddingInline: '25px' }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Typography variant="h4" gutterBottom>
                        Rapor Oluştur
                    </Typography>
                </Stack>
                <Card>
                    <Stack
                        direction="column"
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        paddingY={2}
                        paddingX={2}
                    >
                        <Grid item md={6} xs={12}>
                            <Typography variant="h6">Rapor Tarihinin Türünü Seçin</Typography>
                            <RadioGroup
                                sx={{ marginTop: '32px' }}
                                aria-label="Rapor Tarihinin Türünü Seçin"
                                name="option"
                                value={option}
                                onChange={handleChangeOption}
                                row
                            >
                                <FormControlLabel value="created" control={<Radio />} label="Oluşturma Tarihine Göre" />
                                <FormControlLabel value="inBranch" control={<Radio />} label="Şubeye Alma Tarihine Göre" />
                                <FormControlLabel value="delivered" control={<Radio />} label="Teslim Tarihine Göre" />
                                <FormControlLabel value="completed" control={<Radio />} label="Tamamlanma Tarihine Göre" />
                            </RadioGroup>
                        </Grid>

                        <Grid container alignItems="center" xs={8} spacing={2} sx={{ marginTop: '32px' }}>
                            <Grid item>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"tr"}>
                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        label="Rapor Başlangıç Tarihi"
                                        value={startDate}
                                        onChange={(newValue) => setStartDate(newValue)}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"tr"}>
                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        label="Rapor Bitiş Tarihi"
                                        value={endDate}
                                        onChange={(newValue) => setEndDate(newValue)}
                                        disabled={!startDate}
                                        minDate={moment(startDate)}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item>
                                <CompleteButton sx={{ height: '54px' }} disabled={!(startDate && endDate) || (startDate > endDate)} label={"Rapor Oluştur"} onClick={handleCreateReport} />
                                {!(startDate && endDate) || (startDate > endDate) ?

                                    <Tooltip title="Dosya indirme hakkında bilgi için tıklayın">
                                        <IconButton
                                            size="small"
                                            sx={{ color: 'gray', marginLeft: '8px' }}
                                            onClick={handleOpen}
                                        >
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    <Tooltip title="Dosya indirme hakkında bilgi için tıklayın">
                                        <IconButton
                                            size="small"
                                            sx={{
                                                color: 'gray',
                                                marginLeft: '8px',
                                                animation: 'pulseInfo 2s infinite',
                                                '@keyframes pulseInfo': {
                                                    '0%': {
                                                        transform: 'scale(1)',
                                                        color: 'gray',
                                                    },
                                                    '50%': {
                                                        transform: 'scale(1.1)',
                                                        color: '#088AB2', // Açık mavi
                                                    },
                                                    '100%': {
                                                        transform: 'scale(1)',
                                                        color: 'gray',
                                                    },
                                                },
                                            }}
                                            onClick={handleOpen}
                                        >
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </Tooltip>
                                }

                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-title"
                                    aria-describedby="modal-description"
                                >
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: 800,
                                            bgcolor: 'background.paper',
                                            borderRadius: '10px',
                                            boxShadow: 24,
                                            p: 4,
                                            justifyContent: 'flex-end',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <h3 id="modal-title">Dosya İndirme Uyarısı</h3>
                                        <img
                                            src={require('../../src/assets/images/popUpError.png')}
                                            alt="Pop-up Error"
                                            style={{
                                                display: 'block',
                                                margin: '20px auto',
                                                maxWidth: '100%',
                                                height: 'auto',
                                            }}
                                        />
                                        <p id="modal-description">
                                            Eğer tarayıcınızda görseldeki işareti görüyorsanız, gösterilen ikona tıklayarak tarayıcınızdan, bu sayfa için <b>"Pop-up'lar ve yönlendirmeler"</b> seçeneğine izin vermeniz gerekmektedir. Aksi durumda dosya indirme işlemlerinize engel olacaktır.
                                        </p>
                                        <Button onClick={handleClose} variant="contained" color="primary">
                                            Tamam
                                        </Button>
                                    </Box>
                                </Modal>
                            </Grid>
                        </Grid>
                    </Stack>
                </Card>
            </Stack>

        </>
    );
}
