import React, { useState, useEffect } from 'react';
import { Typography, Button, Stack } from '@mui/material';

const StatusFilters = ({ activeOnClick, passiveOnClick, temporaryPassiveOnClick, label, selectedButton, setSelectedButton }) => {

    const handleButtonClick = (buttonType) => {
        setSelectedButton(buttonType);
        // Call the appropriate click handler
        if (buttonType === '1') {
            activeOnClick && activeOnClick();
        } else if (buttonType === '0') {
            passiveOnClick && passiveOnClick();
        } else if (buttonType === '3') {
            temporaryPassiveOnClick && temporaryPassiveOnClick();
        }
    };


    return (
        <Stack sx={{ my: 1, marginLeft: 1 }} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', display: 'flex' }}>
            <Typography
                sx={{
                    fontWeight: 500,
                    fontFamily: 'Inter',
                    fontSize: 14,
                    color: '#6B7280',
                    letterSpacing: 0.15,
                    marginRight: '25px'
                }}
            >
                {label}
            </Typography>

            {activeOnClick &&
                <>
                    <Button
                        onClick={() => handleButtonClick('1')}
                        variant='outlined'
                        sx={{
                            background: selectedButton === '1' ? '#DC423C' : '#ECFDF3',
                            border: selectedButton === '1' ? null : '1px solid #FFD6AE',
                            padding: "4px 12px 4px 10px",
                            borderRadius: '5px',
                            borderColor: selectedButton === '1' ? null : '#ABEFC6',
                            borderWidth: 1,
                            color: selectedButton === '1' ? "white" : '#067647',
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 500,
                            letterSpacing: 0.15,
                            marginLeft: '10px',
                            '&:hover': {
                                backgroundColor: selectedButton === '1' ? '#B53228' : '#ceded5',
                                borderColor: selectedButton === '1' ? null : '#93cfaa'
                            }
                        }}>
                        • Aktif
                    </Button>
                </>
            }

            {passiveOnClick &&
                <>
                    <div
                        style={{
                            width: '3%',
                            height: '1px',
                            borderTop: '1px solid #ccc',
                            transform: 'rotate(90deg)',
                        }}
                    ></div>
                    <Button
                        onClick={() => handleButtonClick('0')}
                        variant='outlined'
                        sx={{
                            background: selectedButton === '0' ? '#DC423C' : '#F9FAFB',
                            padding: "4px 12px 4px 10px",
                            borderRadius: '5px',
                            borderColor: selectedButton === '0' ? null : '#EAECF0',
                            borderWidth: 1,
                            color: selectedButton === '0' ? "white" : '#344054',
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 500,
                            letterSpacing: 0.15,
                            '&:hover': {
                                backgroundColor: selectedButton === '0' ? '#B53228' : '#cacbcc',
                                borderColor: selectedButton === '0' ? null : '#b1b3b5'
                            }
                        }}>
                        • Pasif
                    </Button>
                </>
            }

            {temporaryPassiveOnClick &&
                <>
                    <div
                        style={{
                            width: '3%',
                            height: '1px',
                            borderTop: '1px solid #ccc',
                            transform: 'rotate(90deg)',
                        }}
                    ></div>
                    <Button
                        onClick={() => handleButtonClick('3')}
                        variant='outlined'
                        sx={{
                            background: selectedButton === '3' ? '#DC423C' : '#FFFAEB',
                            padding: "4px 12px 4px 10px",
                            borderRadius: '5px',
                            borderColor: selectedButton === '3' ? null : '#FEDF89',
                            borderWidth: 1,
                            color: selectedButton === '3' ? 'white' : '#B54708',
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 500,
                            letterSpacing: 0.15,
                            '&:hover': {
                                backgroundColor: selectedButton === '3' ? '#B53228' : '#e3ded1',
                                borderColor: selectedButton === '3' ? null : '#d1b871'
                            }
                        }}>
                        • Geçici Pasif
                    </Button>
                </>
            }

        </Stack>
    );
};

export default StatusFilters;
