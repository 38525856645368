import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import moment from 'moment';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  Box,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TextField,
  Modal,
  TablePagination,
  ToggleButtonGroup,
  ToggleButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import Scrollbar from '../components/scrollbar';
import { UserListHead } from '../sections/@dashboard/user';
import Services from 'src/services/Services';
import CircularProgress from '@mui/material/CircularProgress';
// ----------------------------------------------------------------------

const REQUESTS_TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'name', label: 'Ad Soyad', alignRight: false },
  { id: 'phone', label: 'Telefon', alignRight: false },
  { id: 'date', label: 'Tarih', alignRight: false },
  { id: 'state', label: 'Durum', alignRight: false },
  { id: '', label: 'İşlemler' },
];

const OPERATIONS_TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'name', label: 'Ad Soyad', alignRight: false },
  { id: 'phone', label: 'Telefon', alignRight: false },
  { id: 'date', label: 'Tarih', alignRight: false },
  { id: 'state', label: 'Durum', alignRight: false },
];

const COMPLETED_TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'name', label: 'Ad Soyad', alignRight: false },
  { id: 'date', label: 'Tarih', alignRight: false },
];

export default function HandsfreePage() {
  const [token, setToken] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selectedTab, setSelectedTab] = useState('talepleri');
  const [countModal, setCountModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [count, setCount] = useState('');
  const [selectedOrderID, setSelectedOrderID] = useState('');
  const [requests, setRequests] = useState([]);
  const [operations, setOperations] = useState([]);
  const [completeds, setCompleteds] = useState([]);
  const [success, setSuccess] = useState(false);
  const [rejectedSuccess, setRejectedSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showType, setShowType] = useState('all');
  const [onlyWaitingDatas, setOnlyWaitingDatas] = useState([]);
  const [allRequests, setAllRequests] = useState([]);
  const [orderNumbers, setOrderNumbers] = useState();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [note, setNote] = useState('');

  // Service

  const handleSuccess = (type) => {
    if (type === 'confirm') {
      setSuccess(true);
    } else {
      setRejectedSuccess(true);
    }
    setLoading(false);
  };

  const handleFailed = () => {
    setFailed(true);
    setLoading(false);
  };

  const confirmRequest = () => {
    console.log(selectedOrderID, count);
    setLoading(true);
    Services.GetHandsFreeConfirm(token, selectedOrderID, +count)
      .then((res) => {
        console.log('confirm response: ', res.data);
        setOrderNumbers(res.data.result);
        handleSuccess('confirm');
        setCountModal(false);
        setSelectedOrderID('');
        setCount('');
      })
      .catch((err) => {
        console.log(err);
        console.log('Bir hata oluştu');
        handleFailed();
      });
  };

  const rejectRequest = () => {
    console.log(selectedOrderID, token);
    Services.GetHandsFreeReject(token, selectedOrderID)
      .then((res) => {
        console.log(res.data);
        handleSuccess('reject');
        setRejectModal(false);
        setSelectedOrderID('');
      })
      .catch((err) => {
        console.log(err);
        console.log('Bir hata oluştu');
        handleFailed();
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - req?.length) : 0;

  const findLocation = (handsfreeToken, branch, token) => {
    console.log(token, branch);
    Services.GetLocation(token, branch)
      .then((res) => {
        console.log('location response: ', res.data);
        Services.GetHandsFreeOrders(
          handsfreeToken,
          res.data.result?.map((x) => x.locationID)
        )
          .then((response) => {
            console.log('handsfreeOrders: ', response.data);
            const allRequests = response.data.result;
            // Tamamı
            const sortedRequests = allRequests?.sort(
              (a, b) => b.createdDateTime - a.createdDateTime
            );
            setAllRequests(sortedRequests);
            setRequests(sortedRequests);
            // Sadece Bekleyenler
            const onlyWaitingDatas = allRequests?.filter(
              (item) => item.status === '3'
            );
            const sortedWaitingDatas = onlyWaitingDatas?.sort(
              (a, b) => b.createdDateTime - a.createdDateTime
            );
            setOnlyWaitingDatas(sortedWaitingDatas);
          })
          .catch((error) => {
            console.log('Orders error: ', error);
            setRequests([]);
          });
      })
      .catch((err) => {
        console.log('locations error: ', err);
      });
  };

  const fetchDatas = () => {
    const datas = JSON.parse(localStorage.getItem('user'));
    Services.GetToken()
      .then((response) => {
        console.log('datas: ', datas);
        setToken(response.data.result.token);

        findLocation(response.data.result.token, datas.branchID, datas.token);
        Services.GetHandsFreePosts(
          response.data.result.token,
          datas.branchID,
          [1, 2, 3, 4, 5, 6]
        )
          .then((res) => {
            console.log(res.data);
            const sortedOperations = res.data.result.sort(
              (a, b) => b.createdDateTime - a.createdDateTime
            );
            setOperations(sortedOperations);
          })
          .catch((err) => {
            console.log(err);
            setOperations([]);
          });

        Services.GetHandsFreePosts(response.data.result.token, datas.branchID, [
          1,
        ])
          .then((res) => {
            console.log(res.data);
            const sortedPosts = res.data.result.sort(
              (a, b) => b.createdDateTime - a.createdDateTime
            );
            setCompleteds(sortedPosts);
          })
          .catch((err) => {
            console.log(err);
            setCompleteds([]);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchDatas();
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <>
      <Helmet>
        <title> Hands Free | Minimal UI </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {selectedTab === 'talepleri' && 'Hands Free Talepleri'}
            {selectedTab === 'islemleri' && 'Hands Free İşlemleri'}
            {selectedTab === 'tamamlanmis' && 'Hands Free Tamamlanmış İşler'}
          </Typography>

          <ToggleButtonGroup
            value={selectedTab}
            exclusive
            onChange={(event, newTab) => setSelectedTab(newTab)}
          >
            <ToggleButton value="talepleri">Hands Free Talepleri</ToggleButton>
            <ToggleButton value="islemleri">Hands Free İşlemleri</ToggleButton>
            <ToggleButton value="tamamlanmis">
              Hands Free Tamamlanmış İşler
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        {onlyWaitingDatas?.length > 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'right',
              marginBottom: 20,
            }}
          >
            <Button
              onClick={() => {
                if (showType === 'all') {
                  setRequests(onlyWaitingDatas);
                  setShowType('onlyWaitings');
                } else {
                  setRequests(allRequests);
                  setShowType('all');
                }
              }}
              variant="contained"
              size="large"
            >
              {showType === 'all'
                ? `Onay Bekleyenleri Göster (${onlyWaitingDatas.length})`
                : `Tümünü Göster`}
            </Button>
          </div>
        )}

        <Card
          sx={{
            backgroundColor: 'white',
            paddingBottom: '30px',
          }}
        >
          <Scrollbar>
            <Box sx={{ width: '100%', paddingInline: '30px' }}>
              {/* Render content based on the selected tab */}
              {selectedTab === 'talepleri' && (
                <>
                  {requests?.length > 0 ? (
                    <div style={{ marginTop: 50 }}>
                      <Table>
                        <UserListHead
                          headLabel={REQUESTS_TABLE_HEAD}
                          rowCount={requests?.length}
                          onRequestSort={() => null}
                        />
                        <TableBody>
                          {requests
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                              const {
                                orderID,
                                name,
                                lastname,
                                phone,
                                status,
                                inventoryCount,
                                createdDateTime,
                                additionalNote,
                              } = row;

                              return (
                                <TableRow hover key={index} tabIndex={-1}>
                                  <TableCell align="left">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="left">
                                    {name + ' ' + lastname}
                                  </TableCell>
                                  <TableCell align="left">{phone}</TableCell>

                                  <TableCell align="left">
                                    {moment
                                      .unix(createdDateTime)
                                      .format('DD/MM/YYYY HH:mm')}
                                  </TableCell>
                                  <TableCell align="left">
                                    {status === '1'
                                      ? 'Onaylandı'
                                      : status === '2'
                                      ? 'Reddedildi'
                                      : status === '3'
                                      ? 'Mağazadan Onay Bekliyor'
                                      : status === '6'
                                      ? 'Müşteriden Onay Bekleniyor'
                                      : status === '2' && inventoryCount === '0'
                                      ? 'Reddettiniz'
                                      : 'Bilinmiyor'}{' '}
                                    {+inventoryCount > 0 &&
                                      '(' + inventoryCount + ')'}
                                  </TableCell>
                                  <TableCell align="left">
                                    {status === '3' && (
                                      <div>
                                        <Button
                                          variant="contained"
                                          onClick={() => {
                                            setSelectedOrderID(orderID);
                                            setCountModal(true);
                                            setNote(additionalNote);
                                          }}
                                          sx={{ marginRight: '8px' }}
                                        >
                                          Onayla
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            setSelectedOrderID(orderID);
                                            setRejectModal(true);
                                          }}
                                          variant="contained"
                                          color="error"
                                        >
                                          Reddet
                                        </Button>
                                      </div>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={requests?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  ) : (
                    <Typography mt={5} color={'gray'}>
                      Hands Free Talebi Bulunmamaktadır
                    </Typography>
                  )}{' '}
                </>
              )}

              {selectedTab === 'islemleri' && (
                <>
                  {operations?.length > 0 ? (
                    <div style={{ marginTop: 50 }}>
                      <Table>
                        <UserListHead
                          headLabel={OPERATIONS_TABLE_HEAD}
                          rowCount={operations?.length}
                          onRequestSort={() => null}
                        />
                        <TableBody>
                          {operations
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                              const {
                                orderNumber,
                                name,
                                lastname,
                                phone,
                                createdDateTime,
                                status,
                              } = row;

                              return (
                                <TableRow hover key={index} tabIndex={-1}>
                                  <TableCell align="left">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="left">
                                    {orderNumber}
                                  </TableCell>
                                  <TableCell align="left">
                                    {' '}
                                    {name + ' ' + lastname}
                                  </TableCell>
                                  <TableCell align="left">{phone}</TableCell>
                                  <TableCell align="left">
                                    {' '}
                                    {moment
                                      .unix(createdDateTime)
                                      .format('DD/MM/YYYY HH:mm')}
                                  </TableCell>
                                  <TableCell align="left">
                                    {status === '1'
                                      ? 'Tamamlandı'
                                      : status === '2'
                                      ? 'İptal Edildi'
                                      : status === '3'
                                      ? 'Mağazada Bekliyor'
                                      : status === '4'
                                      ? 'Porter Taşımasında'
                                      : status === '5'
                                      ? 'Easy Point Noktasında'
                                      : status === '6'
                                      ? 'Müşteri Onayı Bekliyor'
                                      : 'Bilinmiyor'}{' '}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={requests?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  ) : (
                    <Typography mt={5} color={'gray'}>
                      Hands Free İşlemleri Bulunmamaktadır
                    </Typography>
                  )}
                </>
              )}
              {selectedTab === 'tamamlanmis' && (
                <>
                  {completeds?.length > 0 ? (
                    <div style={{ marginTop: 50 }}>
                      <Table>
                        <UserListHead
                          headLabel={COMPLETED_TABLE_HEAD}
                          rowCount={completeds?.length}
                          onRequestSort={() => null}
                        />
                        <TableBody>
                          {completeds
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                              const {
                                orderNumber,
                                name,
                                lastname,
                                createdDateTime,
                              } = row;

                              return (
                                <TableRow hover key={index} tabIndex={-1}>
                                  <TableCell align="left">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="left">
                                    {orderNumber}
                                  </TableCell>
                                  <TableCell align="left">
                                    {name + ' ' + lastname}
                                  </TableCell>
                                  <TableCell align="left">
                                    {moment
                                      .unix(createdDateTime)
                                      .format('DD/MM/YYYY HH:mm')}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={requests?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  ) : (
                    <Typography mt={5} color={'gray'}>
                      Hands Free Tamamlanmış İşleri Bulunmamaktadır
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Scrollbar>
        </Card>
        <Modal
          open={countModal}
          onClose={() => {
            setCountModal(false);
            setSelectedOrderID('');
            setCount('');
            setNote('');
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            <Typography variant="h6">Paket Sayısı Giriniz</Typography>
            <TextField
              sx={{ marginBlock: '30px' }}
              fullWidth
              label="Paket Sayısı"
              variant="outlined"
              type="number"
              value={count}
              onChange={(e) => setCount(e.target.value)}
            />
            <Button
              onClick={() => {
                confirmRequest();
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Tamamla'
              )}
            </Button>
          </Box>
        </Modal>

        <Modal
          open={rejectModal}
          onClose={() => {
            setRejectModal(false);
            setSelectedOrderID('');
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            <Typography variant="h6">
              Müşterinin talebini reddetmek istediğinize emin misiniz?
            </Typography>

            <Button
              onClick={() => {
                rejectRequest();
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Evet'
              )}
            </Button>
          </Box>
        </Modal>

        <Dialog open={success}>
          <DialogTitle color={'green'}>Başarılı</DialogTitle>
          <DialogContent>
            <Typography>İşleminiz gerçekleştirildi.</Typography>
            <Stack direction="column" spacing={2} mt={2}>
              {orderNumbers?.map((orderNumber, index) => (
                <Box key={index} display="flex" alignItems="center">
                  <Typography>
                    {index + 1}) {orderNumber}
                  </Typography>
                </Box>
              ))}
              <Typography>Alıcı: {note}</Typography>
              <Box display="flex" alignItems="center" sx={{ width: 350 }}>
                <Checkbox
                  checked={isCheckboxChecked}
                  onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                />
                <Typography>
                  Gönderi kodlarını ilgili paketlerin üzerine yazdım.
                </Typography>
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={!isCheckboxChecked}
              onClick={() => {
                setSuccess(false);
                window.location.reload(false);
              }}
              color="primary"
            >
              Tamam
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={failed} onClose={() => setFailed(false)}>
          <DialogTitle color={'red'}>Hata</DialogTitle>
          <DialogContent>
            <Typography>İşleminiz gerçekleştirilemedi.</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setFailed(false)} color="primary">
              Kapat
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={rejectedSuccess}
          onClose={() => {
            window.location.reload(false);
            setRejectedSuccess(false);
          }}
        >
          <DialogTitle color={'green'}>Başarılı</DialogTitle>
          <DialogContent>
            <Typography>Talep reddedilmiştir.</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                window.location.reload(false);
                setRejectedSuccess(false);
              }}
              color="primary"
            >
              Kapat
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
