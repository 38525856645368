import { Backdrop, Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import DynamicTable from 'src/components/shared/DynamicTable';
import Services from 'src/services/Services';

export default function BolParaApplications() {
    const parsedUser = JSON.parse(localStorage.getItem('user'));

    const [responseList, setResponseList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [selectedResponseID, setSelectedResponseID] = useState(null);
    const [actionType, setActionType] = useState(null);

    const [approvedCount, setApprovedCount] = useState(0);
    const [rejectedCount, setRejectedCount] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);

    let TABLE_HEAD = [
        { id: 'applicant', label: 'Başvuran Kişi', format: (value, row) => { return (row.data[0].name + " " + row.data[0].lastname) }, sortBy: 'data[0].name' },
        { id: 'companyTitle', label: 'Marka Adı' },
        { id: 'poiTitle', label: 'Nokta Adı' },
        { id: 'title', label: 'Kampanya Adı' },
        { id: 'createdDateTime', label: 'Oluşturulma Tarihi', format: (value) => formatDate(value) },
        {
            id: 'status',
            label: 'Durum',
            format: (value) => {
                return (
                    <Box
                        component="span"
                        sx={{
                            display: 'inline-block',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            color: '#fff',
                            backgroundColor:
                                value === "1" ? 'green' :
                                    value === "2" ? 'red' :
                                        'orange'
                        }}
                    >
                        {value === "1" ? "Onaylandı" : value === "2" ? "Reddedildi" : "Onay Bekliyor"}
                    </Box>
                );
            }
        },
        {
            id: 'operations', label: '', format: (value, row) => {
                if (row.status === "3") {
                    return (
                        <div>
                            <Button onClick={() => openConfirmDialog(row.responseID, 1)}>Onayla</Button>
                            <Button onClick={() => openConfirmDialog(row.responseID, 2)}>Reddet</Button>
                        </div>
                    );
                } else {
                    return null;
                }
            }
        },
    ];

    const formatDate = (date) => {
        const formattedDate = moment.unix(date).format('DD.MM.YYYY HH:mm');
        return formattedDate;
    };

    const getResponses = () => {
        setLoading(true);
        Services.GetBolParaResponses(parsedUser.token)
            .then((res) => {
                if (res.data.status) {
                    setResponseList(res.data.result);

                    const approvedResponses = res.data.result.filter(response => response.status === "1");
                    const rejectedResponses = res.data.result.filter(response => response.status === "2");
                    const pendingResponses = res.data.result.filter(response => response.status === "3");


                    setApprovedCount(approvedResponses.length);
                    setRejectedCount(rejectedResponses.length);
                    setPendingCount(pendingResponses.length);

                    setLoading(false);
                } else {
                    toast.error("Başvuru bulunamadı.");
                    setLoading(false);
                }
            })
            .catch((err) => console.log(err));
    };

    const updateResponseStatus = (responseID, status) => {
        setLoading(true);
        Services.UpdateBolParaResponse(parsedUser.token, responseID, status)
            .then((res) => {
                if (res.data.status) {
                    getResponses();
                    setConfirmModalOpen(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((err) => console.log(err));
    };

    const openConfirmDialog = (responseID, actionType) => {
        setSelectedResponseID(responseID);
        setActionType(actionType);
        setConfirmModalOpen(true);
    };

    const handleConfirm = () => {
        if (selectedResponseID && actionType !== null) {
            updateResponseStatus(selectedResponseID, actionType);
        }
    };

    const handleClose = () => {
        setConfirmModalOpen(false);
    };

    useEffect(() => {
        getResponses();
    }, []);

    return (
        <>
            <Helmet>
                <title>Bol Para Başvurular | Easy Point Panel</title>
            </Helmet>

            <Stack style={{ paddingInline: '25px' }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Typography variant="h4" gutterBottom>
                        Bol Para Başvurular
                    </Typography>
                </Stack>
                <Card>
                    {loading ? (
                        <Backdrop
                            sx={{ color: '#fff', zIndex: 2, height: '100%' }}
                            open={loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    ) : (
                        <>
                            <Stack direction="row" spacing={3} mb={3} sx={{ marginLeft: '15px', marginTop: '15px' }}>

                                <Typography variant="body1" sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>  <div style={{ width: '15px', height: '15px', backgroundColor: 'green', marginRight: '10px' }} />Onaylanan: {approvedCount}</Typography>
                                <Typography variant="body1" sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>  <div style={{ width: '15px', height: '15px', backgroundColor: 'red', marginRight: '10px' }} />Reddedilen: {rejectedCount}</Typography>
                                <Typography variant="body1" sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>  <div style={{ width: '15px', height: '15px', backgroundColor: 'orange', marginRight: '10px' }} />Onay Bekleyen: {pendingCount}</Typography>
                            </Stack>
                            <DynamicTable
                                data={responseList}
                                headers={TABLE_HEAD}
                                isOrdered={false}
                                initialOrder={"createdDateTime"}
                                initalOrderDirection={"desc"}
                            />
                        </>

                    )}
                </Card>
            </Stack>

            {/* Confirmation Dialog */}
            <Dialog
                open={confirmModalOpen}
                onClose={handleClose}
            >
                <DialogTitle>{actionType === 1 ? "Onay" : "Red"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {actionType === 1 ? "Başvuruyu onaylamak istediğinize emin misiniz?" : "Başvuruyu reddetmek istediğinize emin misiniz?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Hayır</Button>
                    <Button onClick={handleConfirm} color="primary">
                        Evet
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
