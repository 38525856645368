import React, { useState, useEffect } from 'react';
import FileUpload from 'src/components/modals/FileUpload/FileUpload'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { FormControl, Stack, Typography } from '@mui/material';
import Services from 'src/services/Services';
import Iconify from 'src/components/iconify/Iconify';
import CompleteButton from 'src/components/shared/CompleteButton';

export default function SupportTicketsCreateTicket({ currentUser, token, setOpenCreateTicket, handleCloseDetails }) {

    const [selectedType, setSelectedType] = useState("");
    const [topic, setTopic] = useState("");
    const [username, setUsername] = useState("");
    const [details, setDetails] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isBackdropOpen, setIsBackdropOpen] = useState(false);
    const [showResultMessage, setShowResultMessage] = useState(false);
    const [resultMessage, setResultMessage] = useState("");

    const supportTypes =
        [
            { value: "Esnaf App", title: "Esnaf App" },
            { value: "Easypoint App", title: "Easypoint App" },
            { value: "General", title: "Genel Talep" },
            { value: "EasyNet", title: "EasyNet" },
            { value: "Technical Support", title: "Teknik Destek" },
            { value: "Development", title: "Geliştirme Talebi" },
            { value: "Report Bug", title: "Hata Bildirimi" },
        ]

    const allFieldsAreValid = () => {
        if (selectedType !== '' && topic !== '' && username !== '' && details !== '') {
            return true
        }
        else
            return false
    };

    useEffect(() => {
        setIsBackdropOpen(true)
        Services.GetUserByID(token, currentUser.userID)
            .then((response) => {
                setUsername(response.data.result.username)
                setIsBackdropOpen(false)
            })
            .catch((error) => {
                setIsBackdropOpen(false)
                setShowResultMessage(true)
                setResultMessage("Kullanıcı bilgilerinize ulaşılamadı. Lütfen daha sonra tekrar deneyin.")
            })
    }, []);

    const handleCloseMessage = () => {
        setShowResultMessage(false)
        setSelectedType("")
        setTopic("")
        setUsername("")
        setDetails("")
        setSelectedFiles([])
        setOpenCreateTicket(false)
        handleCloseDetails()
    }

    const handleUpload = () => {
        setIsBackdropOpen(true)
        let fileArr = []
        for (let i = 0; i < selectedFiles.length; i++) {
            fileArr.push(selectedFiles[i].base64.base64)
        }
        Services.CreateTicket(token, topic, details, selectedType, fileArr, username)
            .then((response) => {
                console.log(topic, details, selectedType, fileArr, username);
                if (response.data.status) {
                    setIsBackdropOpen(false)
                    setShowResultMessage(true)
                    setResultMessage("Talebiniz başarıyla iletildi.")
                }
                else {
                    setIsBackdropOpen(false)
                    setShowResultMessage(true)
                    setResultMessage("Talebiniz iletilirken bir sorunla karşılaşıldı. Lütfen daha sonra tekrar deneyin.")
                }
            })
            .catch((error) => {
                setIsBackdropOpen(false)
                setShowResultMessage(true)
                setResultMessage("Talebiniz iletilirken bir sorunla karşılaşıldı. Lütfen daha sonra tekrar deneyin.")
            })
    }
    return (
        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>

            <Backdrop
                sx={{ color: '#fff', zIndex: 2 }}
                open={isBackdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog
                open={showResultMessage}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Destek Talebi"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {resultMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseMessage}>
                        Tamam
                    </Button>
                </DialogActions>
            </Dialog>

            <Stack style={{ paddingInline: '25px', width: '100%', justifyContent: 'flex-end', display: 'flex', flexDirection: 'row' }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Card sx={{ height: '40px', width: '40px', borderRadius: '5px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        <Button onClick={() => handleCloseMessage()} sx={{ height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            <Iconify sx={{ color: '#858585', height: '16px' }} icon="material-symbols:arrow-back-ios-new" />
                        </Button>
                    </Card>
                </Stack>
            </Stack>

            <Typography sx={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: '600', color: '#DD4640', letterSpacing: '-0.2%', '@media screen and (max-width: 600px)': { fontSize: '12px' } }}>
                BİZE ULAŞIN
            </Typography>
            <Typography sx={{ fontFamily: 'Inter', fontSize: '26px', fontWeight: '600', color: '#475467', letterSpacing: '-0.2%', marginBottom: '20px', '@media screen and (max-width: 600px)': { fontSize: '22px' } }}>
                DESTEK TALEBİ OLUŞTUR
            </Typography>




            <Card sx={{
                width: '41.77%', justifyContent: 'center', display: 'flex', marginBottom: '15px', paddingTop: '13px', '@media screen and (max-width: 600px)': { width: '90%' }
            }}>
                <CardContent sx={{ width: '83.33%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
                    <Box
                        sx={{
                            width: '100%',
                            '@media screen and (max-height: 670px)': {
                                marginBottom: '100px'
                            }
                        }}
                    >
                        <Typography sx={{ fontFamily: 'Inter', fontSize: '20px', fontWeight: '600', lineHeight: '24px', marginBottom: '10px', display: { xs: 'none', sm: 'block' } }}>
                            Talep Formu
                        </Typography>
                        <Typography sx={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: '400', lineHeight: '24px', color: '#667085', marginBottom: '15px', display: { xs: 'none', sm: 'block' } }}>
                            Lütfen destek almak istediğiniz konu ve destek birimine yönelik olarak gerekli alanları doldurarak talebinizi bize iletiniz.
                        </Typography>
                    </Box>


                    <FormControl fullWidth margin="normal">
                        <InputLabel>Destek birimi seçiniz</InputLabel>
                        <Select
                            sx={{ width: '100%' }}
                            label="Destek birimi seçiniz"
                            disabled={supportTypes.length === 0}
                            value={selectedType}
                            onChange={(e) => setSelectedType(e.target.value)}
                            name="supportType"
                        >
                            {supportTypes?.map((type, index) => (
                                <MenuItem key={index} value={type.value}>{type.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        id="topic"
                        label="Konu*"
                        variant="outlined"
                        value={topic}
                        onChange={(e) => setTopic(e.target.value)}
                        sx={{ mb: 1, mt: 1 }}
                        fullWidth />
                    <TextField
                        id="details"
                        label="İletmek istediğiniz mesajı yazın..."
                        variant="outlined"
                        value={details}
                        onChange={(e) => setDetails(e.target.value)}
                        sx={{ mb: 2, mt: 1 }}
                        fullWidth
                        multiline
                        rows={5}
                    />

                    <FileUpload setFiles={setSelectedFiles} files={selectedFiles} isCreate={true} />
                    <CompleteButton onClick={() => handleUpload()} label={"Talebi Gönder"} disabled={!allFieldsAreValid()} sx={{ marginTop: '15px', width: '162px' }} />
                </CardContent>
            </Card>
        </div>
    )
}
