// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapperClassName {
    display: flex;
    flex-direction: column-reverse;
    background-color: #FEFEFE;
    width: 99%;
    height: 100%;
    border-radius: 5px;
    padding-left: 10px;

}

.toolbarClassName {
    margin-bottom: 0%;
    border: none !important;
    height: 75px;
    align-items: center;
}

.rdw-option-wrapper {
    /* Burada kalın (bold) düğmenin çerçeve stilini değiştirin */
    border: 2px solid #EBEBEB;
    border-radius: 5px;
    width: 36px;
    height: 36px;
    /* Diğer stiller... */
}

.editorClassName {
    overflow-y: auto;
    height: 79%;
}

.text-editor-container {
    width: 95%;
    height: 30%;
    border: 1px solid #0000001A;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-right: 15px;
}

.rdw-image-modal {
    transform: translateY(-100%) !important;
}

.rdw-link-modal {
    transform: translateY(-100%) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/modals/TextEditor/TextEditor.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,yBAAyB;IACzB,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;;AAEtB;;AAEA;IACI,iBAAiB;IACjB,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,4DAA4D;IAC5D,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,UAAU;IACV,WAAW;IACX,2BAA2B;IAC3B,0CAA0C;IAC1C,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,uCAAuC;AAC3C;;AAEA;IACI,uCAAuC;AAC3C","sourcesContent":[".wrapperClassName {\n    display: flex;\n    flex-direction: column-reverse;\n    background-color: #FEFEFE;\n    width: 99%;\n    height: 100%;\n    border-radius: 5px;\n    padding-left: 10px;\n\n}\n\n.toolbarClassName {\n    margin-bottom: 0%;\n    border: none !important;\n    height: 75px;\n    align-items: center;\n}\n\n.rdw-option-wrapper {\n    /* Burada kalın (bold) düğmenin çerçeve stilini değiştirin */\n    border: 2px solid #EBEBEB;\n    border-radius: 5px;\n    width: 36px;\n    height: 36px;\n    /* Diğer stiller... */\n}\n\n.editorClassName {\n    overflow-y: auto;\n    height: 79%;\n}\n\n.text-editor-container {\n    width: 95%;\n    height: 30%;\n    border: 1px solid #0000001A;\n    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);\n    border-radius: 5px;\n    margin-right: 15px;\n}\n\n.rdw-image-modal {\n    transform: translateY(-100%) !important;\n}\n\n.rdw-link-modal {\n    transform: translateY(-100%) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
