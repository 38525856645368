import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import api from 'src/services/BaseService';
import Services from 'src/services/Services';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const [html, setHtml] = useState('');
  const [isChecked, setChecked] = useState(false);

  // Services
  function login(user) {
    setLoading(true);
    console.log(user);
    Services.Login(user.username, user.password)
      .then((response) => {
        console.log(response.data);
        if (response.data.status) {
          localStorage.setItem(
            'expired',
            JSON.stringify(response.data.result.expired)
          );
          localStorage.setItem('user', JSON.stringify(response.data.result));
          localStorage.setItem('mallID', response?.data?.result?.mallID);
          localStorage.setItem(
            'tokenForService',
            response?.data?.result?.token
          );
          navigate('/dashboard');
        } else {
          if (response.data.message === 'Password not match') {
            toast.error('Şifrenizi kontrol ediniz');
          } else {
            toast.error('Giriş bilgilerinizi kontrol ediniz');
          }
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => setLoading(false));
  }

  const handleClick = (event) => {
    event.preventDefault();

    login({
      username: event.target.elements.email.value,
      password: event.target.elements.password.value,
    });
  };

  const checkboxChange = () => {
    if (isChecked) {
      setChecked(false);
    } else {
      setModal(true);
    }
  };

  useEffect(() => {
    console.log('checked: ', isChecked);
  }, [isChecked]);

  useEffect(() => {
    Services.GetToken()
      .then((res) => {
        console.log(res.data);
        Services.GetAgreement(res.data.result.token, 'aydinlatma-metni')
          .then((response) => {
            console.log(response.data);
            setHtml(response.data.result.description);
          })
          .catch((error) => console.log(error));
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <form id="login-form" onSubmit={handleClick}>
        <Stack spacing={3}>
          <TextField
            required
            error={error}
            id="email"
            name="email"
            label="Kullanıcı adını girin"
            autoComplete="off"
          />

          <TextField
            required
            id="password"
            name="password"
            label="Şifrenizi girin"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <div>
            <Checkbox
              checked={isChecked}
              value={isChecked}
              onChange={checkboxChange}
            />
            <label
              style={{ textDecorationLine: 'underline', color: '#DC3545' }}
              onClick={() => setModal(true)}
            >
              Aydınlatma Metni
            </label>
          </div>
          <Typography
            onClick={() => navigate('/forgot-password')}
            sx={{ color: '#DC3545', cursor: 'pointer' }}
          >
            Şifremi Unuttum
          </Typography>
        </Stack>

        <LoadingButton
          disabled={loading || !isChecked}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
          style={{ backgroundColor: '#dc3545' }}
        >
          Giriş Yap
        </LoadingButton>
      </form>

      <Dialog open={modal}>
        <DialogTitle>Aydınlatma Metni</DialogTitle>
        <DialogContent>
          <DialogContentText dangerouslySetInnerHTML={{ __html: html }} />
        </DialogContent>

        <DialogActions>
          <Button
            sx={{ backgroundColor: 'green', color: 'white' }}
            onClick={() => {
              setChecked(true);
              setModal(false);
            }}
          >
            Okudum, Anladım, Onaylıyorum
          </Button>
          {/* <Button onClick={() => setModal(false)}>Kapat</Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
