import { Navigate } from 'react-router-dom';
import { tokenService } from '../../services';

export const ProtectedRoute = ({ children }) => {
  if (!tokenService.getUser()) {
    return <Navigate to="/login" />;
  }

  return children;
};
