import { Backdrop, Card, CircularProgress, Stack, Typography } from '@mui/material'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-toastify';
import AddEntityButton from 'src/components/shared/AddEntityButton'
import DynamicTable from 'src/components/shared/DynamicTable';
import AddEditBolParaCompany from 'src/modals/AddEditBolParaCompany';
import Services from 'src/services/Services';

export default function BolPara() {

    const [companyList, setCompanyList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState();
    const [companyName, setCompanyName] = useState("");
    const [logo, setLogo] = useState("");

    let TABLE_HEAD = [
        { id: 'title', label: 'Marka Adı', },
        { id: 'createdDateTime', label: 'Oluşturulma Tarihi', format: (value) => formatDate(value) },
        { id: 'status', label: 'Durum', format: (value) => value === "1" ? "Aktif" : "Pasif" },

    ];

    useEffect(() => {
        const parsedUser = JSON.parse(localStorage.getItem('user'));
        getCompanies(parsedUser.token)
    }, []);

    const handleCompanySelect = (company) => {
        setModalOpen(true)
        setSelectedCompany(company);
    }

    const handleCompanyAdd = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
        setSelectedCompany()
        setLogo("")
        setCompanyName("")
    }

    const getCompanies = (token) => {
        setLoading(true)
        Services.GetBolParaCompanies(token)
            .then((res) => {
                if (res.data.status) {
                    setCompanyList(res.data.result)
                    setLoading(false)
                }
                else {
                    toast.error("Marka bulunamadı.")
                    setLoading(false)
                }
            })
            .catch((err) => console.log(err));
    }

    const formatDate = (date) => {
        const formattedDate = moment.unix(date).format('DD.MM.YYYY');
        return formattedDate;
    };

    return (
        <>
            <Helmet>
                <title> Bol Para | Easy Point Panel </title>
            </Helmet>

            <Stack style={{ paddingInline: '25px' }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Typography variant="h4" gutterBottom>
                        Bol Para
                    </Typography>
                    <AddEntityButton
                        label={'Marka Ekle'}
                        icon={'ic:outline-add'}
                        onClick={handleCompanyAdd}
                    />
                </Stack>
                <Card>
                    {loading ?
                        <Backdrop
                            sx={{ color: '#fff', zIndex: 2, height: '100%' }}
                            open={loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        :
                        <DynamicTable
                            data={companyList}
                            headers={TABLE_HEAD}
                            isOrdered={false}
                            initialOrder={"createdDateTime"}
                            initalOrderDirection={"desc"}
                            rowOnClick={handleCompanySelect}
                        />
                    }
                </Card>
            </Stack>

            <AddEditBolParaCompany
                open={modalOpen}
                onClose={handleCloseModal}
                company={selectedCompany}
                companyName={companyName}
                setCompanyName={setCompanyName}
                logo={logo}
                setLogo={setLogo}
                getCompanies={getCompanies}
            />
        </>
    )
}
