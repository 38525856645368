import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import {
  Link,
  Container,
  Typography,
  Divider,
  Stack,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Collapse,
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormGroup,
  Checkbox,
} from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { HotTable } from '@handsontable/react';
import Services from 'src/services/Services';
import { toast } from 'react-toastify';
import Dots from 'react-activity/dist/Dots';
import 'react-activity/dist/Dots.css';

// ----------------------------------------------------------------------
const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));
const StyledFormWrapper = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));
export default function LoginPage() {
  const [avm, setAvm] = useState();
  const [taxNumber, setTaxNumber] = useState();
  const [formOpen, setFormOpen] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [bearerToken, setBearerToken] = useState();
  const [malls, setMalls] = useState();
  const [loading, setLoading] = useState();
  const [shops, setShops] = useState();
  const [selectedOption, setSelectedOption] = useState(null);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    username: '',
    password: '',
    passwordApprove: '',
    kvkk: false,
    terms: false,
  });

  const handleSearch = () => {
    if (formOpen) {
      setFormOpen(!formOpen);
    } else {
      setLoading(true);
      const requestData = {
        taxNo: taxNumber,
        mallID: avm,
      };

      Services.SearchBranchByTaxNo(
        bearerToken,
        requestData.taxNo,
        requestData.mallID
      )
        .then((response) => {
          console.log(response.data);

          setShops(response?.data?.result);
        })
        .catch((error) => {
          console.log('error : ' + error);
        })
        .finally(() => setLoading(false));

      setFormOpen(!formOpen);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(formData);
    Services.CreateEmployeeWithoutLogin(bearerToken, formData)
      .then((response) => {
        if (response.data.status === true) {
          toast.success('Başarıyla oluşturuldu.');
          setSelectedOption(null);
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            username: '',
            password: '',
            mallID: null,
            branchId: null,
            corporationID: null,
            branchTitle: null,
          });
        } else {
          toast.error(response.data.message);
        }
        console.log(response.data);
      })
      .catch((error) => {
        toast.error(response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const allFieldsAreValid = () => {
    if (
      isValidEmail === true &&
      formData.firstName.length > 3 &&
      formData.lastName.length > 3 &&
      formData.phoneNumber.length > 7 &&
      formData.username.length > 1 &&
      formData.password.length > 1 &&
      formData.branchId &&
      formData.kvkk === true &&
      formData.terms === true &&
      formData.password === formData.passwordApprove
    ) {
      return false;
    } else {
      return true;
    }
  };
  const handleTaxNumberChange = (event) => {
    const value = event.target.value;

    const onlyNumbers = value.replace(/[^0-9]/g, ''); // Sadece rakamları koru
    const truncatedValue = onlyNumbers.slice(0, 9); // Maksimum 9 karaktere kırp

    setTaxNumber(truncatedValue);
  };

  const handleNameAndSurnameChange = (e) => {
    const value = e.target.value;

    const onlyLettersAndSpace = value.replace(/[^a-zA-ZğüşöçĞÜŞÖÇ\s]/g, '');

    if (e.target.name === 'firstName') {
      setFormData({
        ...formData,
        firstName: onlyLettersAndSpace,
      });
    } else {
      setFormData({
        ...formData,
        lastName: onlyLettersAndSpace,
      });
    }
  };

  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const isValidEmail = emailRegex.test(inputValue);
    setFormData({
      ...formData,
      email: inputValue,
    });

    if (isValidEmail) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  const handlePhoneNumberChange = (event) => {
    let value = event.target.value;

    // Sadece rakamları kabul et
    value = value.replace(/\D/g, '');

    // Telefon numarasının ilk karakteri '+'
    if (value.length > 0 && value[0] !== '+') {
      value = '+' + value;
    }

    setFormData({
      ...formData,
      phoneNumber: value,
    });
  };

  useEffect(() => {
    setLoading(true);
    Services.GetTokenForCreateEmployeeWithoutLogin()
      .then((response) => {
        setBearerToken(response?.data?.result?.token);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    Services.GetMallsForCreateEmployeeWithoutLogin(bearerToken)
      .then((response) => {
        setMalls(response?.data?.result);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  }, [bearerToken]);
  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Dots />
        </div>
      ) : (
        <>
          <Helmet>
            <title> Create Employee | Mall Logistics Panel </title>
          </Helmet>

          <Container maxWidth="sm">
            <StyledContent>
              <Typography textAlign="center" variant="h4" gutterBottom>
                Mall Logistics Kullanıcı Oluşturma Servisi
              </Typography>

              <Divider sx={{ my: 3 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  AVM Ara
                </Typography>
              </Divider>

              <>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Lütfen AVM Seçin
                  </InputLabel>
                  <Select
                    disabled={loading}
                    required
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={avm}
                    label="AVM"
                    onChange={(event) => {
                      setAvm(event.target.value);
                    }}
                  >
                    <MenuItem value="" disabled>
                      Lütfen AVM Seçin
                    </MenuItem>
                    {!loading &&
                      malls?.map((item, key) => (
                        <MenuItem key={key} value={item.id}>
                          {item?.title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <TextField
                    required
                    id="taxNumber"
                    name="taxNumber"
                    label="Lütfen Vergi Numarası Girin"
                    type="number"
                    value={taxNumber}
                    onChange={handleTaxNumberChange}
                  />
                </FormControl>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ my: 2 }}
                ></Stack>
                <LoadingButton
                  onClick={handleSearch}
                  disabled={
                    avm?.length !== 0 && taxNumber?.length !== 9 ? true : false
                  }
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  // loading={formOpen}
                >
                  Ara
                </LoadingButton>
              </>
              <Collapse
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  marginTop: '20px',
                }}
                in={formOpen}
              >
                <StyledFormWrapper>
                  <FormControl onSubmit={handleSubmit}>
                    <InputLabel id="demo-simple-select-helper-label">
                      Lütfen Mağaza Seçin
                    </InputLabel>
                    <Select
                      disabled={loading}
                      required
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={selectedOption}
                      label="Shops"
                      onChange={(e) => {
                        setSelectedOption(e.target.value);
                        setFormData({
                          ...formData,
                          mallID: e.target.value.mallID,
                          branchId: e.target.value.id,
                          corporationID: e.target.value.corporationID,
                          branchTitle: e.target.value.title,
                        });
                      }}
                    >
                      <MenuItem value={null} disabled>
                        Lütfen Mağaza Seçin
                      </MenuItem>
                      {!loading &&
                        shops?.map((item, key) => (
                          <MenuItem key={key} value={item}>
                            {item?.title}
                          </MenuItem>
                        ))}
                    </Select>
                    <TextField
                      label="Ad"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleNameAndSurnameChange}
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Soyad"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleNameAndSurnameChange}
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="E-posta"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleEmailChange}
                      onBlur={() => {
                        if (formData.username === '') {
                          setFormData({
                            ...formData,
                            username: formData.email,
                          });
                        }
                      }}
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Telefon Numarası"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handlePhoneNumberChange}
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Kullanıcı Adı"
                      name="username"
                      value={formData.username}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          username: e.target.value,
                        });
                      }}
                      margin="normal"
                      fullWidth
                      autoComplete="false"
                    />
                    <TextField
                      label="Parola"
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          password: e.target.value,
                        })
                      }
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Parola Tekrar"
                      type="password"
                      name="password approve"
                      value={formData.passwordApprove}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          passwordApprove: e.target.value,
                        })
                      }
                      margin="normal"
                      fullWidth
                    />
                    {/* <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Çalışan"
                  name="radio-buttons-group"
                  row
                  value={formData.isAdmin}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      isAdmin: e.target.value,
                    })
                  }
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Admin"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Çalışan"
                  />
                </RadioGroup> */}
                    <FormGroup className="kvkk-form-wrapper">
                      <FormControlLabel
                        required
                        style={{
                          color: '#5E596D',
                          fontSize: '12px',
                          fontWeight: 400,
                        }}
                        control={
                          <Checkbox
                            checked={formData.terms === true ? true : false}
                            value={formData.terms}
                            onChange={() =>
                              setFormData({
                                ...formData,
                                terms: !formData.terms,
                              })
                            }
                          />
                        }
                        label="Kullanım koşullarını okudum ve kabul ediyorum."
                      />
                      <FormControlLabel
                        required
                        style={{
                          color: '#5E596D',
                          fontSize: '7px',
                          fontWeight: 400,
                        }}
                        checked={formData.kvkk === true ? true : false}
                        control={
                          <Checkbox
                            value={formData.kvkk}
                            onChange={() =>
                              setFormData({
                                ...formData,
                                kvkk: !formData.kvkk,
                              })
                            }
                          />
                        }
                        label="KVKK Aydınlatma Metnini okudum ve anladım."
                      />
                    </FormGroup>
                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={allFieldsAreValid()}
                      onClick={handleSubmit}
                    >
                      Çalışan oluştur
                    </Button>
                  </FormControl>
                </StyledFormWrapper>
              </Collapse>
            </StyledContent>
          </Container>
        </>
      )}
    </>
  );
}
