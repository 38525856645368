// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-1c44y0a-MuiTableCell-root {
  text-align: center;
}


.fs-sm{
  font-size: 12px;
}

.bt-lr-1{
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}


.country-spec .apexcharts-bar-series .apexcharts-series path:nth-child(1) {
  fill: #0666F6 /* İlk çubuğun rengi */
}

.country-spec .apexcharts-bar-series .apexcharts-series path:nth-child(2) {
  fill: #E0C6FD /* İkinci çubuğun rengi */
}

.country-spec .apexcharts-bar-series .apexcharts-series path:nth-child(3) {
  fill: #962DFF
}

.country-spec .apexcharts-bar-series .apexcharts-series path:nth-child(4) {
  fill:#C6D2FD /* Dördüncü çubuğun rengi */
}

.country-spec .apexcharts-grid-borders{
  display: none;
}
.country-spec .apexcharts-gridline{
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/pagesStyles/priceTable.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;;AAGA;EACE,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,4BAA4B;AAC9B;;;AAGA;EACE,YAAY,EAAE,sBAAsB;AACtC;;AAEA;EACE,YAAY,EAAE,yBAAyB;AACzC;;AAEA;EACE;AACF;;AAEA;EACE,WAAW,EAAE,2BAA2B;AAC1C;;AAEA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf","sourcesContent":[".css-1c44y0a-MuiTableCell-root {\r\n  text-align: center;\r\n}\r\n\r\n\r\n.fs-sm{\r\n  font-size: 12px;\r\n}\r\n\r\n.bt-lr-1{\r\n  border-top-left-radius: 1px;\r\n  border-top-right-radius: 1px;\r\n}\r\n\r\n\r\n.country-spec .apexcharts-bar-series .apexcharts-series path:nth-child(1) {\r\n  fill: #0666F6 /* İlk çubuğun rengi */\r\n}\r\n\r\n.country-spec .apexcharts-bar-series .apexcharts-series path:nth-child(2) {\r\n  fill: #E0C6FD /* İkinci çubuğun rengi */\r\n}\r\n\r\n.country-spec .apexcharts-bar-series .apexcharts-series path:nth-child(3) {\r\n  fill: #962DFF\r\n}\r\n\r\n.country-spec .apexcharts-bar-series .apexcharts-series path:nth-child(4) {\r\n  fill:#C6D2FD /* Dördüncü çubuğun rengi */\r\n}\r\n\r\n.country-spec .apexcharts-grid-borders{\r\n  display: none;\r\n}\r\n.country-spec .apexcharts-gridline{\r\n  display: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
