// CONSTANT
const CUSTOMER = 'CUSTOMER';

// ACTION
export const updateCustomerValue = (customer) => ({
  type: CUSTOMER,
  customer,
});

// REDUCER

const initialState = {
  customer: null,
};

export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER:
      return {
        ...state,
        customer: action.customer,
      };

    default:
      return state;
  }
};
