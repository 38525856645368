import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Stack,
  Typography,
  TextField,
  FormControl,
  useMediaQuery
} from '@mui/material';

// components
// sections
import api from 'src/services/BaseService';
import './muhaberatOutput.css';
import AddEntityButton from 'src/components/shared/AddEntityButton';
import EditButton from 'src/components/shared/EditButton';
import AddEditAvmModal from 'src/modals/AddEditAvmModal';
import ClearButton from 'src/components/shared/ClearButton';
import FilterButton from 'src/components/shared/FilterButton';
import DynamicTable from 'src/components/shared/DynamicTable';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
// ----------------------------------------------------------------------


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function StoreList() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('createdDateTime');
  const [filterName, setFilterName] = useState('');
  const [storeList, setStoreList] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [title, setTitle] = useState('');
  const [city, setCity] = useState('');
  const [county, setCounty] = useState('');
  const [addEditAvmOpen, setAddEditAvmOpen] = useState(false);
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [isPrimary, setIsPrimary] = useState('');
  const [mallList, setMallList] = useState([]);

  const [isBackdropOpen, setIsBackdropOpen] = useState(true);
  const [filterModal, setFilterModal] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const handleModal = () => {
    setFilterModal(true);
  };

  const handleCloseModal = () => {
    setFilterModal(false);
  };

  const TABLE_HEAD = [
    { id: 'title', label: 'AVM/Plaza', alignRight: false },
    { id: 'city', label: 'İl', alignRight: false },
    { id: 'county', label: 'İlce', alignRight: false },
    { id: 'edit', label: '', format: (value, row) => <EditButton onClick={() => handleEdit(row)}></EditButton> },
  ];



  const handleOpenAddEditStoreModal = () => {
    setAddEditAvmOpen(true);
    setIsModalEdit(false);
  };

  const handleCloseAddEditStoreModal = () => {
    setAddEditAvmOpen(false);
    getMalls();
  };

  const handleEdit = (rowData) => {
    setEditData(rowData);
    setAddEditAvmOpen(true);
    setIsModalEdit(true);
  };

  function getAvmList(malls) {
    setStoreList(malls);
    setOriginalData(malls);
  }
  function getMalls() {
    api
      .post('/malls/get-malls')
      .then((response) => {
        if (response.data) {
          getAvmList(response.data.result);
          setMallList(response.data.result);
          setIsBackdropOpen(false);
        } else {
          // toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  useEffect(() => {
    getMalls();
  }, []);

  const getFilteredStoreList = () => {
    let filteredStoreList;
    if (title || city || county || isPrimary) {
      filteredStoreList = originalData.filter((item) => {
        const titleMatch = title
          ? item.title
            .toLowerCase()
            .normalize('NFKD')
            .replace(/[\u0300-\u036F]/g, '')
            .includes(
              title
                .toLowerCase()
                .normalize('NFKD')
                .replace(/[\u0300-\u036F]/g, '')
            )
          : true;
        const cityMatch = city
          ? item.city
            .toLowerCase()
            .normalize('NFKD')
            .replace(/[\u0300-\u036F]/g, '')
            .includes(
              city
                .toLowerCase()
                .normalize('NFKD')
                .replace(/[\u0300-\u036F]/g, '')
            )
          : true;
        const countyMatch = county
          ? item.county
            .toLowerCase()
            .normalize('NFKD')
            .replace(/[\u0300-\u036F]/g, '')
            .includes(
              county
                .toLowerCase()
                .normalize('NFKD')
                .replace(/[\u0300-\u036F]/g, '')
            )
          : true;

        const isPrimaryMatch = isPrimary ? item.isPrimary === isPrimary : true;
        return titleMatch && cityMatch && countyMatch && isPrimaryMatch;
      });
    } else {
      filteredStoreList = originalData;
    }

    setStoreList(filteredStoreList);
  };

  const clearFilters = () => {
    setStoreList(originalData);
    setTitle('');
    setCity('');
    setIsPrimary('');
    setCounty('');
  };

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setPage(0);
  //   setRowsPerPage(parseInt(event.target.value, 10));
  // };

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - storeList.length) : 0;

  const filteredStores = applySortFilter(
    storeList,
    getComparator(order, orderBy),
    filterName
  );

  // const isNotFound = !filteredStores?.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> AVM Listesi | Mall Logistics Panel </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            AVM Listesi
          </Typography>
          <AddEntityButton
            sx={{ marginInline: '8px', marginTop: 1 }}
            label={' AVM Ekle'}
            onClick={() => handleOpenAddEditStoreModal()}
          >
          </AddEntityButton>
        </Stack>
        <Stack
          direction="row"
          justifyContent={isMobile ? 'space-between' : 'end'}
          mb={5}
          paddingX={2}
        >
          {isMobile ? (
            <FilterViewButton variant="contained" onClick={handleModal} />
          ) : null}
        </Stack>
        <Card>
          <CustomFilter
            modalType={'drawer'}
            filterModal={filterModal}
            handleCloseModal={handleCloseModal}
          >
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="flex-start"
              paddingY={2}
              paddingX={1}
              spacing={2}
            >
              <Stack
                mr={1}
                direction="column"
                alignItems="flex-start"
                justifyContent="flex-start"
                width="100%"
                spacing={2}
              >
                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label="Avm Adı"
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label="İl"
                    variant="outlined"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label="İlce"
                    variant="outlined"
                    value={county}
                    onChange={(e) => setCounty(e.target.value)}
                  />
                </FormControl>

              </Stack>

              <Stack direction="row">
                <ClearButton onClick={() => clearFilters()} />
                <FilterButton onClick={() => getFilteredStoreList()} />
              </Stack>

            </Stack>
          </CustomFilter>


          <DynamicTable
            data={filteredStores}
            headers={TABLE_HEAD}
            initialOrder={'name'}
            initalOrderDirection={'desc'}
            isOrdered={true}
            isMobile={isMobile}
            handleModal={handleModal}
            handleCloseModal={handleCloseModal}
            rowOnClick={(row) => handleEdit(row)}
          />

        </Card>
      </Stack>

      {addEditAvmOpen && (
        <AddEditAvmModal
          open={addEditAvmOpen}
          onClose={handleCloseAddEditStoreModal}
          isEdit={isModalEdit}
          editData={editData}
          mallList={mallList}
          isBackdropOpen={isBackdropOpen}
        />
      )}
    </>
  );
}
