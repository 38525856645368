import * as React from 'react';
import Box from '@mui/material/Box';
import Scrollbar from '../components/scrollbar';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { WarningOutlined } from '@mui/icons-material';
import {
  Card,
  Stack,
  Container,
  Typography,
  Button,
  Divider,
  Modal,
  TextField,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import api from 'src/services/BaseService';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.min.css';
import { registerAllModules } from 'handsontable/registry';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

registerAllModules();
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function FullFeaturedCrudGrid() {
  const hotRef = React.useRef(null);
  const initialRows = Array.from({ length: 50 }, (_, index) => ({
    id: index + 1,
    barcode: '',
    name: '',
    corporation: '',
    shipmentFirm: '',
    corporationID: '',
    // fromWhere: '',
  }));
  const columnHeaders = [
    'No',
    'Barkod',
    'Kişi',
    'Firma',
    'Kargo Firma',
    'Firma ID',
    // We need an extra field like below
    // 'Nereden Geldi ?',
  ];
  const cargoList = [
    'Aras Kargo',
    'MNG Kargo',
    'PTT Kargo',
    'Sürat Kargo',
    'UPS',
    'Yurtiçi Kargo',
    'Diğer',
  ];
  const [customerList, setCustomerList] = React.useState([]);
  const columnSettings = [
    {
      readOnly: true,
      width: 50,
      className: 'htCenter htMiddle',
    },
    {
      strict: false,
      className: 'htCenter htMiddle',
      width: 250,
    },
    {
      strict: false,
      className: 'htCenter htMiddle',
      width: 250,
    },
    {
      type: 'autocomplete',
      strict: true,
      className: 'htCenter htMiddle',
      width: 200,
      source: customerList?.map((option) => option?.value),
    },
    {
      className: 'htCenter htMiddle',
      width: 100,
      type: 'autocomplete',
      strict: true,
      source: cargoList,
    },
    {
      readOnly: true,
      strict: false,
      visibleRows: 50,
      className: 'htCenter htMiddle',
      width: 250,
    },
    // {
    //   readOnly: true,
    //   strict: false,
    //   visibleRows: 50,
    //   className: 'htCenter htMiddle',
    //   width: 100,
    // },
  ];
  const [isSuccessModalOpen, setSuccessModalOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [tableData, setTableData] = React.useState();
  const [failedRowsCount, setFailedRowsCount] = React.useState();
  const [controlModalOpen, setControlModalOpen] = React.useState(false);
  const [smsModal, setSmsModal] = React.useState(false);
  const [code, setCode] = React.useState('');

  const createPDF = () => {
    // const doc = new jsPDF();
    // doc.setLanguage('tr-TR');
    // const table = hotRef.current.hotInstance.getData();
    // const nonEmptyRows = table
    //   .filter((row) => row.slice(1, -1).every((cell) => cell !== ''))
    //   .map((row) => {
    //     const corporational = row[3];
    //     const cargoFirmName = row[4];
    //     const matchingCustomer = customerList.find(
    //       (item) => item.value === corporational
    //     );
    //     const matchingCargo = cargoList.find((item) => item === cargoFirmName);
    //     if (matchingCustomer && matchingCargo) {
    //       return [row[0].toString(), row[1], row[2], row[3], row[4], row[5]];
    //     }
    //   })
    //   .filter(Boolean);
    // if (nonEmptyRows?.length > 0) {
    //   doc.autoTable({
    //     head: [columnHeaders],
    //     body: nonEmptyRows,
    //   });
    //   const lastRow = doc.lastAutoTable.finalY + 10;
    //   doc.text('Teslim Alan:', 14, lastRow);
    //   doc.line(70, lastRow, 180, lastRow);
    //   doc.text('imza:', 14, lastRow + 10);
    //   doc.line(70, lastRow + 10, 180, lastRow + 10);
    //   doc.setFont('helvetica');
    //   const timestamp = moment().format('DD-MM-YYYY_HH:mm');
    //   doc.save(`Muhaberat_${timestamp}.pdf`);
    //   doc.output('dataurlnewwindow');
    // } else {
    //   openSuccessModal('Lütfen boş alan bırakmayınız', true);
    // }
    // setControlModalOpen(false);
    const table = hotRef.current.hotInstance.getData();
    const nonEmptyRows = table
      .filter((row) => row.slice(1, -1).every((cell) => cell !== ''))
      .map((row) => {
        const corporational = row[3];
        const cargoFirmName = row[4];
        const matchingCustomer = customerList.find(
          (item) => item.value === corporational
        );
        const matchingCargo = cargoList.find((item) => item === cargoFirmName);
        // const fromWhere = row[6]
        if (matchingCustomer && matchingCargo) {
          return [
            row[0].toString(),
            row[1],
            row[2],
            row[3],
            row[4],
            row[5],
            // row[6],
          ];
        }
      })
      .filter(Boolean);

    if (nonEmptyRows?.length > 0) {
      const headerText = `Zorlu Center - ${moment().format(
        'DD.MM.YYYY HH:mm'
      )} - Batuhan Göktaş - ${nonEmptyRows.length} Adet`;
      const docDefinition = {
        content: [
          { text: 'Belge No: 1565743286', style: 'header' },
          { text: headerText, style: 'header' },
          {
            table: {
              headerRows: 1,
              widths: ['auto', '*', '*', '*', '*', 'auto'],
              body: [columnHeaders, ...nonEmptyRows],
              alignment: 'center',
              margin: ['auto'],
            },
          },
          { text: '\nTeslim Alan:', margin: [0, 10, 0, 0] },
          { text: 'imza:', margin: [0, 10, 0, 0] },
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10],
            alignment: 'center',
          },
        },
      };

      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.open();
      // pdfDocGenerator.download(
      //   `Muhaberat_${moment().format('DD-MM-YYYY_HH:mm')}.pdf`
      // );
      pdfDocGenerator.getBlob((blob) => {
        // Create a Blob URL and initiate the download
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Muhaberat_${moment().format('DD-MM-YYYY_HH:mm')}.pdf`;
        a.click();
        // Release the Blob URL
        URL.revokeObjectURL(url);
      });
    } else {
      openSuccessModal('Lütfen boş alan bırakmayınız', true);
    }
    setControlModalOpen(false);
  };

  const handleCreate = () => {
    //sms or notification service will be added
    const table = hotRef.current.hotInstance.getData();
    const nonEmptyRows = table
      .filter((row) => row.slice(1, -1).every((cell) => cell !== ''))
      .map((row) => {
        const customerName = row[2];
        const corporational = row[3];
        const cargoFirmName = row[4];
        const matchingCustomer = customerList.find(
          (item) => item.value === corporational
        );
        const matchingCargo = cargoList.find((item) => item === cargoFirmName);

        if (matchingCustomer && matchingCargo) {
          return {
            barcode: row[1],
            shipmentFirm: cargoFirmName,
            isMultipleTransportation: false,
            orderInventory: 1,
            isOnlyCreateJob: true,
            type: 'post-office',
            isPhysicalBarcode: true,
            status: 5,
            direction: 'MallLogisticsToCustomer',
            transportationTool: 'Ring',
            customer: matchingCustomer.id,
            from: '86561942017699686248',
            to: matchingCustomer.locationID,
            sentToEasyPoint: '86561942017699686248',
            customerName: customerName,
            index: row[0],
            companyName: row[3],
          };
        }
      })
      .filter(Boolean);

    // console.log('Non-empty rows:', nonEmptyRows);
    if (nonEmptyRows?.length > 0) {
      api
        .post('mall-logistics/create', nonEmptyRows)
        .then((response) => {
          const numJobsCreated = nonEmptyRows.length;
          if (response.data.status === true) {
            const data = hotRef.current.hotInstance.getData();
            const newData = data.map((row) => {
              const matchingRow = nonEmptyRows.find(
                (nonEmptyRow) => nonEmptyRow?.index === row[0]
              );
              if (matchingRow) {
                return [row[0], '', '', '', '', ''];
              }
              return row;
            });
            const filledRowsCount = newData.filter((row) =>
              row.slice(1).some((cell) => cell !== '')
            ).length;
            openSuccessModal(
              `Toplam ${numJobsCreated} adet iş oluştu.`,
              filledRowsCount
            );
            setTableData(newData);
          } else {
            openSuccessModal('Lütfen boş alan bırakmayınız', true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      openSuccessModal('Lütfen boş alan bırakmayınız', true);
    }
  };

  const openSuccessModal = (message, count) => {
    setSuccessMessage(message);
    setFailedRowsCount(count);
    setSuccessModalOpen(true);
  };

  React.useEffect(() => {
    const mall = localStorage.getItem('mallID');
    api
      .post('corporations/get-malls-companies', {
        mallID: mall,
      })
      .then((response) => {
        const result = response.data.result;
        const processedDataList = result?.map((item) => ({
          value: item.title,
          id: item.branchID,
          corporationID: item.corporationID,
          locationID: item.locations[0].locationID,
        }));
        setCustomerList(processedDataList);
      });
  }, []);

  React.useEffect(() => {
    if (hotRef.current) {
      const hotInstance = hotRef.current.hotInstance;
      hotInstance.addHook('afterChange', onAfterChange);
      hotInstance.updateSettings({
        data: initialRows.map((row) => [
          row.id,
          row.barcode,
          row.name,
          row.corporation,
          row.shipmentFirm,
          row.corporationID,
        ]),
        columns: columnSettings,
      });
    }
  }, [hotRef, customerList]);

  const onAfterChange = (changes, source) => {
    const hotInstance = hotRef.current.hotInstance;

    changes?.forEach(([row, col, prevValue, newValue]) => {
      if (col === 3 && newValue !== '') {
        const matchingCustomer = customerList?.find(
          (item) => item.value === newValue
        );
        if (matchingCustomer) {
          hotInstance.setDataAtCell(row, 5, matchingCustomer?.corporationID);
        }
      } else if (col === 3) {
        hotInstance.setDataAtCell(row, 5, '');
      }
    });
  };

  return (
    <>
      <Helmet>
        <title> Muhaberat | Minimal UI </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Muhaberat Giriş
          </Typography>

          <div>
            <Button
              onClick={() => {
                handleCreate();
              }}
              variant="contained"
              endIcon={<SendIcon />}
              sx={{ marginRight: '12px' }}
            >
              İş Oluştur
            </Button>
            <Button
              onClick={() => {
                setControlModalOpen(true);
              }}
              variant="contained"
              endIcon={<SendIcon />}
            >
              KTF İle Teslim Al
            </Button>
          </div>
        </Stack>

        <Card>
          <Scrollbar>
            <Box sx={{ width: '100%' }}>
              <HotTable
                ref={hotRef}
                rowHeights={35}
                licenseKey="non-commercial-and-evaluation"
                data={tableData}
                persistentState={true}
                colHeaders={columnHeaders}
                columns={columnSettings}
              />
            </Box>
          </Scrollbar>
        </Card>
        <Modal
          open={isSuccessModalOpen}
          onClose={() => {
            setSuccessModalOpen(false);
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            {successMessage.includes('Lütfen boş alan bırakmayınız') ? (
              <WarningOutlined sx={{ color: 'orange', fontSize: 64 }} />
            ) : (
              <CheckCircleIcon sx={{ color: 'green', fontSize: 64 }} />
            )}
            <Typography variant="h6">{successMessage}</Typography>
            {failedRowsCount > 0 && (
              <>
                <Divider sx={{ my: 2, backgroundColor: 'gray' }} />{' '}
                <Typography variant="h6">
                  Uyarı: {failedRowsCount} satırınızın verileri doldurulmadığı
                  için bu satırlarda iş oluşturulamadı.
                </Typography>
              </>
            )}
            <Button
              onClick={() => {
                setSuccessModalOpen(false);
                if (!successMessage.includes('Lütfen boş alan bırakmayınız')) {
                }
              }}
            >
              Tamam
            </Button>
          </Box>
        </Modal>

        <Modal
          open={controlModalOpen}
          onClose={() => setControlModalOpen(false)}
        >
          <Box
            sx={{
              position: 'absolute',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            <Typography variant="h6">Teslimat Yöntemi Seçin</Typography>
            <Button onClick={() => setSmsModal(true)}>SMS ile Teslim Al</Button>
            <Button
              onClick={() => {
                createPDF();
                handleCreate();
              }}
            >
              Belge ile Teslim Al
            </Button>
          </Box>
        </Modal>

        <Modal open={smsModal} onClose={() => setSmsModal(false)}>
          <Box
            sx={{
              position: 'absolute',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            <Typography variant="h6">Kodu Giriniz</Typography>
            <TextField
              label="Kod"
              variant="outlined"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              sx={{ my: 2 }}
            />
            <div>
              <Button>Teslim Al</Button>
            </div>
          </Box>
        </Modal>
      </Container>
    </>
  );
}
