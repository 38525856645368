import { Helmet } from 'react-helmet-async';
import { concat, filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useSelector } from 'react-redux';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  FormControl,
  Select,
  OutlinedInput,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControlLabel,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Link from '@mui/material/Link';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import api from 'src/services/BaseService';
import { tokenService } from 'src/services';
import CreateWorkOrderModal from 'src/components/modals/CreateWorkOrderModal';
import SearchByBarcode from 'src/components/searchByBarcode/SearchByBarcode';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'barcode', label: 'Kargo Takip No', alignRight: false },
  { id: 'title', label: 'Firma/Kişi', alignRight: false },
  { id: 'from', label: 'Çıkış Noktası', alignRight: false },
  { id: 'to', label: 'Varış Noktası', alignRight: false },
  { id: 'deliverValue', label: 'Kargo Durumu', alignRight: false },
  { id: 'createdDateTime', label: 'Oluşturulma Tarihi', alignRight: false },
  { id: '', label: 'İşlemler' },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// if 0 return 5, 1
const StatusList = [
  { label: 'Şubede', id: 5 },
  { label: 'Tamamlandı', id: 1 },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function FinishedJobsPage() {
  const customer = useSelector((state) => state.customerReducer);

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [orderList, setOrderList] = useState([]);

  const [startDate, setStartDate] = useState();

  const [endDate, setEndDate] = useState();

  const [selectedCargoStatus, setSelectedCargoStatus] = useState([]);

  const [company, setCompany] = useState([]);

  const [locations, setLocations] = useState([]);

  const [openCreateModal, setOpenCreateModal] = useState(false);

  // Service
  function getLocations(directions) {
    api
      .post('locations/get-locations', {
        branchID: customer?.customer?.branchID,
      })
      .then((response) => {
        if (response.data.status) {
          const permutations = [];

          for (let i = 0; i < response.data.result?.length; i++) {
            for (let j = 0; j < response.data.result?.length; j++) {
              if (i !== j) {
                permutations.push({
                  title: `${response.data.result[i].title} → ${response.data.result[j].title}`,
                  handle: 'StoreToStore',
                  type: 'new',
                  from: response.data.result[i].locationID,
                  to: response.data.result[j].locationID,
                });
              }
            }
          }

          directions = directions.concat(permutations);
          setLocations(directions);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  function getDirections() {
    api
      .get('directions/get')
      .then((response) => {
        if (response.data.status) {
          getLocations(
            response.data.result.filter((x) => x.handle != 'StoreToStore')
          );
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  function getFilteredOrderList() {
    api
      .post('posts/get', {
        branchID: customer?.customer?.branchID || null,
        mallID: customer?.customer?.id || null,
        status:
          selectedCargoStatus && selectedCargoStatus.length > 0
            ? selectedCargoStatus.map((x) => x.id)
            : StatusList.filter((x) => x.id != 0).map((x) => x.id),
        startDate: startDate
          ? moment(startDate).format('DD-MM-YYYY')
          : startDate,
        endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : endDate,
        limit: 5000,
      })
      .then((response) => {
        if (response.data.status) {
          setOrderList(response.data.result);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => toast.error(error.message));
  }

  function exportToExcel() {
    let temp1 = orderList.map((x, index) => {
      let a = {
        id: index + 1,
        barcode: x.barcode,
        title: x.title,
        from: x.from.title,
        to: x.to.title,
        deliverValue: x.deliverValue ? 'Başarlı' : 'Başarısız',
        createdDateTime: moment(new Date(Number(x.createdDateTime))).format(
          'DD/MM/YYYY'
        ),
      };
      return a;
    });

    api
      .post('excel/export', {
        type: 'excel',
        content: temp1,
        header: {
          ID: 'number',
          'Kargo Takip No': 'string',
          'Firma/Kişi': 'string',
          'Nokta Adı Çıkış': 'string',
          'Nokta Adı Varış': 'string',
          'Kargo Durumu': 'string',
          'Oluşturulma Tarihi': 'string',
        },
        sheetTitle: 'İşler',
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.status) {
          window.open('http://' + response.data.result, '_blank');
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message);
      });
  }

  useEffect(() => {
    getFilteredOrderList();
    getDirections();
  }, [customer]);

  function clearFilters() {
    setStartDate(null);
    setEndDate(null);
    setSelectedCargoStatus([]);
    getFilteredOrderList();
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCargoStatus(
      // On autofill we get a stringified value.
      value
    );
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = orderList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderList.length) : 0;

  const filteredOrders = applySortFilter(
    orderList,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredOrders.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> İş Emirleri | Mall Logistics Panel </title>
      </Helmet>

      <Container>
        <SearchByBarcode />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            İş Emirleri
          </Typography>
          {['store:manageroperations', 'store:operations'].includes(
            tokenService.getUser()?.accountCategory +
              ':' +
              tokenService
                .getUser()
                ?.frontAuth.map((x) => x)
                .join('')
          ) && (
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => setOpenCreateModal(true)}
            >
              İş Emri Oluştur
            </Button>
          )}
        </Stack>

        <Card>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            paddingY={2}
            paddingX={1}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{ marginRight: '8px' }}
                format="DD/MM/YYYY"
                label="Başlangıç Tarihi"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{ marginRight: '8px' }}
                format="DD/MM/YYYY"
                label="Bitiş Tarihi"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              />
            </LocalizationProvider>

            <Stack
              mr={1}
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <FormControl sx={{ minWidth: 250 }}>
                <Select
                  sx={{ marginRight: '8px' }}
                  multiple
                  displayEmpty
                  value={selectedCargoStatus}
                  input={<OutlinedInput />}
                  onChange={handleChange}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Kargo Durumu</em>;
                    }

                    return selected.map((x) => x.label).join(', ');
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {StatusList.map((x) => (
                    <MenuItem key={x.id} value={x}>
                      {x.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Button
              sx={{ marginRight: '8px' }}
              variant="contained"
              color="info"
              onClick={() => getFilteredOrderList()}
            >
              Filtrele
            </Button>

            <Button
              sx={{ marginRight: '8px' }}
              variant="contained"
              color="error"
              onClick={() => clearFilters()}
            >
              Temizle
            </Button>

            <Button
              variant="contained"
              color="success"
              onClick={() => exportToExcel()}
            >
              Excele aktar
            </Button>

            {/* <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            /> */}
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={orderList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredOrders
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        barcode,
                        title,
                        from,
                        to,
                        deliverValue,
                        createdDateTime,
                      } = row;
                      const selectedUser = selected.indexOf(barcode) !== -1;

                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                        >
                          <TableCell align="left">{index + 1}</TableCell>

                          <TableCell align="left">{barcode}</TableCell>

                          <TableCell align="left">{title}</TableCell>

                          <TableCell align="left">{from.title}</TableCell>

                          <TableCell align="left">{to.title}</TableCell>

                          <TableCell align="left">
                            <Label color={deliverValue ? 'success' : 'error'}>
                              {deliverValue ? 'V' : 'X'}
                            </Label>
                          </TableCell>

                          <TableCell align="left">
                            {moment.unix(createdDateTime).format('DD/MM/YYYY')}
                          </TableCell>

                          <TableCell align="left">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={handleOpenMenu}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={orderList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Düzenle
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Sil
        </MenuItem>
      </Popover>

      {openCreateModal && (
        <CreateWorkOrderModal
          openCreateModal={openCreateModal}
          onCreateModal={() => setOpenCreateModal(false)}
          customer={customer}
          locations={locations}
        />
      )}
    </>
  );
}
