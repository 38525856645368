// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `



@media screen and (max-width:768px) {
.mobile_horizantal{
    overflow-x: scroll;
}

.m-w-950{
    width: 950px !important;
}

.MuiDataGrid-columnHeaders{
    background-color: #eb33331a;
}

.mr-2{
    margin-right: 10px;
}
.ml-2{
    margin-left: 10px;
}
.gap-3{
    gap:5px;
   
   
}
.flex-direction-column{
    flex-direction: column;
}
 .d-flex{
    display:flex;
 }   
}`, "",{"version":3,"sources":["webpack://./src/pages/userPage.css"],"names":[],"mappings":";;;;AAIA;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,OAAO;;;AAGX;AACA;IACI,sBAAsB;AAC1B;CACC;IACG,YAAY;CACf;AACD","sourcesContent":["\n\n\n\n@media screen and (max-width:768px) {\n.mobile_horizantal{\n    overflow-x: scroll;\n}\n\n.m-w-950{\n    width: 950px !important;\n}\n\n.MuiDataGrid-columnHeaders{\n    background-color: #eb33331a;\n}\n\n.mr-2{\n    margin-right: 10px;\n}\n.ml-2{\n    margin-left: 10px;\n}\n.gap-3{\n    gap:5px;\n   \n   \n}\n.flex-direction-column{\n    flex-direction: column;\n}\n .d-flex{\n    display:flex;\n }   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
