import React from 'react';
import { Grid, Typography, Paper, Button } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CancelButton from 'src/components/shared/CancelButton';
import CompleteButton from 'src/components/shared/CompleteButton';

function MultipleImageUpload({ setFiles, files }) {

    const onDrop = async (acceptedFiles) => {
        // Sadece belirli dosya türlerini kabul etmek için filtreleme yapalım
        const filteredFiles = acceptedFiles.filter(file => {
            return file.type.startsWith("image/"); // Resimler
        });

        // Dosyaları Base64 formatına dönüştürme
        const filesWithBase64 = await Promise.all(filteredFiles.map(async (file) => {
            const base64 = await readFileAsBase64(file);
            return { file, base64 };
        }));

        setFiles(filesWithBase64);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve({ name: file.name, base64: reader.result.split(',')[1] });
            reader.onerror = error => reject(error);
        });
    };

    const handleCloseFileUpload = () => {
        setFiles([])
    }

    return (
        <Grid container >
            <div style={{ flexDirection: 'column', display: 'flex', width: '100%' }}>
                <Paper variant="outlined" {...getRootProps()} sx={{ p: 3, textAlign: 'center', cursor: 'pointer', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <input {...getInputProps()} />
                    <div style={{
                        width: '40px',
                        height: '40px',
                        border: '1px solid #EAECF0', // Border stilini belirledik
                        borderRadius: '8px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        boxSizing: 'border-box',
                        boxShadow: '0 0 0 0.5px #EAECF0',
                        marginBottom: '12px' // Opsiyonel olarak boxShadow ile border efekti verebiliriz
                    }}>
                        <CloudUploadOutlinedIcon color="#475467" fontSize="medium" />
                    </div>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontFamily: 'Inter' }}>
                        <span style={{ color: '#DD4640', fontWeight: '600' }}>Yüklemek için tıklayın</span> <span style={{ color: '#475467' }}>ya da sürükleyip bırakın</span>
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '12px', fontFamily: 'Inter', color: '#475467', marginTop: '4px' }}>
                        Resim yükleyiniz
                    </Typography>
                </Paper>

                {files?.length > 0 &&
                    <div style={{ flexDirection: 'column', marginTop: '15px' }}>
                        <Typography>Yüklenen Dosyalar:</Typography>
                        {files.map((file, index) => (
                            <div div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', gap: '15px' }}>
                                <img
                                    src={file.base64?.base64 ? `data:image/*;base64,${file.base64.base64}` : file}
                                    alt={"Campaign"}
                                    style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }}
                                />
                                {file.file?.name} Kampanya Resmi {index + 1}
                            </div>
                        ))}
                        <Grid item xs={12} sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', gap: '15px', marginTop: '15px' }}>
                            <CancelButton label={'Temizle'} onClick={() => handleCloseFileUpload()} />
                        </Grid>
                    </div>}
            </div>


        </Grid >
    );
}

export default MultipleImageUpload;